import { Button, Col, Form, Input, Modal, Row, message, Alert } from "antd";
import React, { useEffect } from "react";
import { QuizAttemptProperties } from "../../../types/quiz.type";
import { generateFormRules, getErrorMessage } from "@qlibs/react-components";
import { httpRequest } from "../../../helpers/api";

interface IProps {
  quizAttempt: QuizAttemptProperties | undefined;
  onClose?: () => void;
  onSuccess?: () => void;
  open: boolean;
}
export default function ModalAddNotes({
  quizAttempt,
  onSuccess,
  onClose,
  open,
}: IProps) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const addNotes = async () => {
    try {
      await form.validateFields();
    } catch (error: any) {
      console.error(error);
      return;
    }

    try {
      setIsLoading(true);

      const values = form.getFieldsValue();

      await httpRequest.patch(`/quiz-attempt/${quizAttempt?.id}`, {
        ...values,
      });

      onClose?.();
      form.resetFields();

      onSuccess?.();
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (quizAttempt?.notesFromMentor) {
      form.setFieldValue("notesFromMentor", quizAttempt?.notesFromMentor);
    }
  }, [quizAttempt]);

  return (
    <Modal
      width={520}
      open={open}
      footer={false}
      onCancel={() => {
        onClose?.();
        form.resetFields();
      }}
      title={`${quizAttempt?.notesFromMentor ? 'Edit' : 'Add'} Notes`}
    >
      <Alert type="warning" showIcon message="Make sure you've seen the submission from talent before adding notes" style={{marginBottom: 10}} />
      <Form layout="vertical" autoComplete="off" name="formQuiz" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="notesFromMentor"
              label="Notes"
              rules={generateFormRules('Notes', ['required'])}
            >
              <Input.TextArea
                rows={5}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button block type="primary" onClick={addNotes} loading={isLoading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
