import { AppTable } from '@qlibs/react-components';
import Title from 'antd/lib/typography/Title';
import { SCORING_REFERENCES } from '../data/scoreReferences'

export default function ScoreReferences() {
  return (
    <div style={{marginTop: 20}}>
      <Title level={5}>SCORE REFERENCES</Title>
      <AppTable
        keyId="parameter"
        columns={SCORING_REFERENCES.columns}
        data={SCORING_REFERENCES.data}
        pagination={false}
      />
    </div>
  );
}