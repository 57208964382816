import { getStandardError, NotSet } from '@qlibs/react-components';
import { Empty, Modal, Tabs } from 'antd'
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react'
import styled from 'styled-components';
import { httpRequest } from '../../../helpers/api';
import DetailResultByTalent from '../../quiz/Result/DetailResultByTalent';

export default function OJTQuizResultByEvent({eventId, talentId, title, onClose, onCancel}: {eventId: string; talentId: string; title: string; onClose: () => void; onCancel: () => void}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quizIdPlan, setQuizIdPlan] = useState<string>();
  const [quizIdReport, setQuizIdReport] = useState<string>();
  const [activeTab, setActiveTab] = useState('ojt_plan');

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    if (!eventId) {
      return;
    }
    setIsOpen(true);
    setIsLoading(true);
    try {
      const res = await httpRequest.get('event/' + eventId);

      if (res?.data?.payload) {
        setQuizIdPlan(res?.data?.payload.planningFormId);
        setQuizIdReport(res?.data?.payload.reportFormId);
      }
      setIsLoading(false);
    } catch (err) {
      getStandardError(err, { showToast: true });
      setIsLoading(false);
    }
  }

  const items = [
    {
      label: 'OJT Plan',
      key: 'ojt_plan',
      children: (
        <div style={{ padding: 10, paddingTop: 30 }}>
          {/* <Title level={4} style={{ paddingLeft: 5 }}>
            OJT Planning
          </Title> */}

          {!quizIdPlan && <NotSet value="Planning Form not exist" />}
          {quizIdPlan && talentId ? (
            <DetailResultByTalent
              quizId={quizIdPlan}
              userId={talentId}
              detailButtonMode="modal"
              hideHeader
            />
          ) : (
            false
          )}
        </div>
      ),
      closable: false,
    },
    {
      label: 'OJT Report',
      key: 'ojt_report',
      children: (
        <div style={{ padding: 10, paddingTop: 30 }}>
          {/* <Title level={4} style={{ paddingLeft: 5 }}>
            OJT Report
          </Title> */}

          {!quizIdReport && <NotSet value="Report Form not exist" />}
          {quizIdReport && talentId ? (
            <DetailResultByTalent
              quizId={quizIdReport}
              userId={talentId}
              detailButtonMode="modal"
              hideHeader
            />
          ) : (
            false
          )}
        </div>
      ),
      closable: false,
    },
  ];

  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Modal
      open={isOpen}
      loading={isLoading}
      closable
      width={1000}
      title={title}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onCancel={() => {
        setIsOpen(false);
        onCancel();
      }}
      footer={false}
    >
      {/* {!isLoading && (!eventId || !quizIdPlan || !talentId) ? <Empty /> : false} */}
      {!isLoading && (
        <CustomTabs
          type="editable-card"
          items={items}
          onChange={onChangeTab}
          defaultActiveKey={'ojt_plan'}
          activeKey={activeTab}
          hideAdd
        />
      )}
    </Modal>
  );
}
const CustomTabs = styled(Tabs)`
  .ant-tabs-tab {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
  }import Evaluation from './index';

`;

