export type CourseProps = {
  courseId: string;
  title: string;
  trainer: string;
  courseType: string;
  objective?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum ECourseType {
  BASIC_TRAINING = 'BASIC_TRAINING',
  WSCC_TRAINING = 'WSCC_TRAINING'
}

export const initialCourse: CourseProps = {
  courseId: '',
  title: '',
  trainer: '',
  courseType: ECourseType.BASIC_TRAINING,
  objective: '',
  createdAt: new Date(),
  updatedAt: new Date(),
}