export function calculateAverage(numbers: number[], includeZero?: boolean) {
  console.info('calculateAverage - numbers', numbers);
  let avg = 0;
  
  numbers = numbers.map(item => Number(item)).filter(item => !isNaN(item));
  console.info('calculateAverage - numbers - filter', numbers);

  if (!includeZero) {
    numbers = numbers.filter(item => item);
  }

  if (numbers.length > 0) {
    avg = numbers.reduce((a, b) => a + b) / numbers.length;
  }
  console.info('calculateAverage - avg', avg);

  return avg;
}