import { DetailItem } from '@qlibs/react-components';
import { Badge, Card, Modal, Tabs } from 'antd';
import React from 'react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ICompetenceItemLevel2 } from '../types/competence.type';
import EvalBadgeAverage from './EvalBadgeAverage';
import FormInputFinalNotes from './FormInputFinalNotes';
import FormInputList from './FormInputList';
import FormInputScore from './FormInputScore';
import ScoreReferences from './ScoreReferences';
import TooltipFormula from './TooltipFormula';

type FormInputGroupedListProps = {
  mode?: 'final-notes' | 'readonly';

  title: string;
  userId: string;
  code: string;
  eventId: string;

  items: ICompetenceItemLevel2[];

  onClickInputScore?: () => void;
};
export default function FormInputGroupedList(props: FormInputGroupedListProps) {
  const [hasChanges, setHasChanges] = useState<string>();
  const [activeTab, setActiveTab] = useState(
    props.items[0]?.subCode || ''
  );
  
  const items = props.items.map((item) => {
    return {
      label: (
        <div
          className="flex items-center gap-auto"
          style={{ fontSize: 11, padding: 0 }}
        >
          {hasChanges === item.subCode ? (
            <Badge dot>{item.title}</Badge>
          ) : (
            item.title
          )}

          <EvalBadgeAverage
            average={item.finalScore || 0}
            hasChanges={hasChanges === item.subCode ? true : false}
          />

          <TooltipFormula
            originalScore={item.originalScore}
            finalScore={item.finalScore}
            items={item.items_level3 || []}
            operator={item.items_level3_operator || ''}
            weight={item.weight}
          />
        </div>
      ),
      key: item.subCode,
      children: (
        <div style={{ padding: 10, paddingTop: 30 }}>
          {/* <Title level={4} style={{ paddingLeft: 5 }}>
            {item.title}
          </Title> */}

          {item.items_level3 && item.items_level3.length > 0 ? (
            <FormInputList
              readonly={props.mode === 'final-notes' || props.mode === 'readonly'}
              level={3}
              userId={props.userId}
              code={props.code}
              subCode={item.subCode}
              eventId={props.eventId || ''}
              items={item.items_level3 || []}
              onChangesAnything={() => setHasChanges(item.subCode)}
            />
          ) : (
            <FormInputScore
              userId={props.userId}
              code={props.code}
              eventId={props.eventId}
              subCode={item.subCode}
              title={item.title}
              initialValues={{ score: item.originalScore || 0 }}
              label={item.title}
            />
          )}
        </div>
      ),
      closable: false,
    };
  });

  const onChangeTab = (key: string) => {
    if (hasChanges) {
      Modal.confirm({
        title: 'Are you sure want to change tab?',
        content:
          'Please makesure your changes in current tab has been saved, or your changes will be losed.',
        onOk: () => {
          setHasChanges(undefined);
          setActiveTab(key);
          // navigate('/evaluation/' + props.userId + '?tab=' + key);
          // window.location.reload();
        },
        onCancel: () => {
          //
        },
        okText: 'Yes, sure',
        okType: 'danger',
      });
    } else {
      // navigate('/evaluation/' + props.userId + '?tab=' + key);
      setActiveTab(key);
    }
  };

  const hasLevel3 = useMemo(() => {
    const findLevel3 = props.items.find(item => item.items_level3 && item.items_level3.length > 0);
    return findLevel3 ? true : false;
  }, [props.items])

  return (
    <div>
      {props.items.length === 1 ? (
        <Card style={{ marginTop: 10 }}>
          <FormInputScore
            userId={props.userId}
            code={props.code}
            eventId={props.eventId}
            subCode={props.items[0].subCode}
            title={props.items[0].title}
            initialValues={{ score: props.items[0].finalScore }}
            label={props.items[0].title}
          />
        </Card>
      ) : hasLevel3 ? (
        <React.Fragment>
          {props.mode === 'final-notes' && (
            <FormInputFinalNotes
              userId={props.userId}
              code={props.code}
              eventId={props.eventId}
              items={props.items}
              label="Notes from Main Mentor"
              title={props.title}
              initialValues={{
                finalNotes: props.items[0]?.items_level3?.[0]?.finalNotes || '',
              }}
              onClickInputScore={props.onClickInputScore}
            />
          )}
          {props.mode === 'readonly' && (
            <DetailItem
              label="Notes from Main Mentor"
              value={props.items[0]?.items_level3?.[0]?.finalNotes || ''}
            />
          )}
          <CustomTabs
            type="editable-card"
            items={items}
            onChange={onChangeTab}
            defaultActiveKey={props.items[0]?.subCode}
            activeKey={activeTab}
            hideAdd
            destroyInactiveTabPane={true}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {props.mode === 'final-notes' && (
            <FormInputFinalNotes
              userId={props.userId}
              code={props.code}
              eventId={props.eventId}
              items={props.items}
              label="Notes from Main Mentor"
              title={props.title}
              initialValues={{ finalNotes: props.items[0].finalNotes }}
              onClickInputScore={props.onClickInputScore}
            />
          )}
          {props.mode === 'readonly' && (
            <DetailItem
              label="Notes from Main Mentor"
              value={props.items[0]?.items_level3?.[0]?.finalNotes || ''}
            />
          )}
          <FormInputList
            readonly={props.mode === 'final-notes' || props.mode === 'readonly'}
            level={2}
            userId={props.userId}
            code={props.code}
            eventId={props.eventId || ''}
            items={props.items || []}
            onChangesAnything={() => setHasChanges(props.items[0].subCode)}
          />
        </React.Fragment>
      )}
      {/* {props.items.map((item, key) => (
        <div key={key}>
          <Title level={5}>{item.title}</Title>

          <FormInputList
            userId={props.userId}
            code={props.code}
            subCode={item.subCode}
            eventId={props.eventId || ''}
            evaluations={item.evaluations || []}
          />

          <Divider />
        </div>
      ))} */}

      <ScoreReferences />
    </div>
  );
}

const CustomTabs = styled(Tabs)`
  .ant-tabs-tab {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
  }import Evaluation from './index';

`;

