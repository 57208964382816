import { AppTable, IAppTableColumn, PAGE_SIZE_OPTIONS, useFetchList } from '@qlibs/react-components';
import { Button, Modal, Spin, Typography } from 'antd';
import { httpRequest } from '../../../helpers/api';
import ReactJson from 'react-json-view';
import { useMemo } from 'react';
import useAdditionalDataForList from '../../../hooks/useAdditionalDataForList';
import { EventsProps } from '../../../types/event.type';
import { UserProperties } from '../../user/types/user.type';
import ReactJsonViewCompare from 'react-json-view-compare';
import CompareDataView from '../../../components/CompareDataView';
import { MAPPING_EVALUATION_CHANGELOGS_ACTION } from '../data/mappingEvaluation';

const {Title} = Typography;

type Props = {
  talentId?: string;
  searchByExternalId?: string;

  hideEvalCode?: boolean;
  hideEvent?: boolean;
  hideChanges?: boolean;
}
export default function EvaluationChangeLogs(props: Props) {
  const {
    isLoading: isLoadingLogs,
    data,
    pagination: paginationLogs,
    changePage: changePageLogs,
    changeLimit: changeLimitLogs,
  } = useFetchList<any>({
    endpoint: `/evaluation-change-logs`,
    httpRequest: httpRequest as any,
    limit: +PAGE_SIZE_OPTIONS[0],
    initialQuery: {
      ...(props.searchByExternalId
        ? { searchByExternalId: props.searchByExternalId }
        : props.talentId
        ? { searchByExternalId: props.talentId }
        : {}),
    },
  });

  const changeLogs = useMemo(() => {
    return data.map(item => {
      const exp = item.externalId.split('__');
      return {
        ...item,
        talentId: exp[0],
        code: exp[1],
        eventId: exp[2],
      };
    })
  }, [data]);

  const { isLoadingAdditionalData, additionalData } = useAdditionalDataForList<{
    [key: string]: { talent?: UserProperties; event?: EventsProps };
  }>({
    id: 'id',
    data: changeLogs,
    injects: [
      {
        injectedId: 'talentId',
        endpoint: '/users?roles=talent&userIds=',
        endpointId: 'userId',
        returnKey: 'talent',
      },
      {
        injectedId: 'eventId',
        endpoint: '/event?eventIds=',
        endpointId: 'eventId',
        returnKey: 'event',
      },
    ],
  });
  console.info('additionalData', additionalData);

  const columns: IAppTableColumn<any>[] = [
    {
      title: 'Updated At',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      type: 'datetime',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      type: 'status',
      mappingStatus: MAPPING_EVALUATION_CHANGELOGS_ACTION,
    },
    {
      title: 'Action By',
      key: 'updatedByUserId',
      dataIndex: 'updatedByUserId',
      render: (value, record) => {
        return record.actionByMeta?.name;
      },
    },
    !props.talentId
      ? {
          title: 'Talent',
          key: 'talentId',
          dataIndex: 'talentId',
          render: (value, record) =>
            isLoadingAdditionalData ? (
              <Spin spinning />
            ) : (
              additionalData[record.id]?.talent?.name || record.talentId || '-'
            ),
        }
      : {},
    props.hideEvalCode
      ? {}
      : {
          title: 'Eval Code',
          key: 'code',
          dataIndex: 'code',
          type: 'status',
          mappingStatus: {
            ojt: {
              label: 'OJT',
              tagProps: {
                color: 'orange',
              },
            },
            dbi: {
              label: 'DBI Project',
              tagProps: {
                color: 'green',
              },
            },
            training: {
              label: 'TRAINING',
              tagProps: {
                color: 'blue',
              },
            },
          },
        },
    props.hideEvent
      ? {}
      : {
          title: 'Event',
          key: 'eventId',
          dataIndex: 'eventId',
          render: (value, record) =>
            isLoadingAdditionalData ? (
              <Spin spinning />
            ) : (
              additionalData[record.id]?.event?.title ||
              (record.eventId === 'general_banking'
                ? 'General Banking'
                : record.eventId === 'aws'
                ? 'AWS'
                : record.eventId) ||
              '-'
            ),
        },
    // {
    //   title: 'Description',
    //   key: 'description',
    //   dataIndex: 'description',
    //   type: 'description',
    // },
    props.hideChanges
      ? {}
      : {
          title: 'Changes',
          key: 'newData',
          dataIndex: 'newData',
          render: (value, record) => {
            const { previousData, newData } = record;
            if (previousData) {
              delete previousData.createdByUserId;
              delete previousData.metaCreatedByUser;
              delete previousData.createdAt;
              delete previousData.updatedAt;
            }
            if (newData) {
              delete newData.createdByUserId;
              delete newData.metaCreatedByUser;
              delete newData.createdAt;
              delete newData.updatedAt;
            }
            if (record.action === 'REQUESTED') {
              return (
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    Modal.info({
                      title: 'Request Data',
                      content: (
                        <ReactJson
                          src={newData}
                          theme="google"
                          displayDataTypes={false}
                        />
                      ),
                      closable: true,
                      width: 1000,
                      footer: false,
                    });
                  }}
                >
                  See Requested Data
                </Button>
              );
            } else {
              return (
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    Modal.info({
                      title: `Data Changes (${MAPPING_EVALUATION_CHANGELOGS_ACTION[record.action].label})`,
                      content: (
                        <CompareDataView
                          oldData={previousData}
                          newData={newData}
                        />
                      ),
                      closable: true,
                      width: 1000,
                      footer: false,
                    });
                  }}
                >
                  See Changes
                </Button>
              );
            }
          },
        },
  ];

  return (
    <Spin spinning={isLoadingLogs}>
      <Title level={4}>Evaluation Activities / Change Logs</Title>
      <AppTable
        keyId="historyId"
        columns={columns}
        data={changeLogs}
        pagination={paginationLogs}
        isLoading={isLoadingLogs}
        onChangePage={changePageLogs}
        onChangeLimit={changeLimitLogs}
      />
    </Spin>
  );
}