import React, { useState } from 'react';
import {
  AppTable,
  IAppTableColumn,
  useFetchList,
  getStandardError,
  getDateTimeString,
} from '@qlibs/react-components';
import { httpRequest } from '../../helpers/api';
import RowFilter from '@qlibs/react-components/dist/Table/RowFilter';
import { UserProperties } from '../user/types/user.type';
import useAdditionalDataForList from '../../hooks/useAdditionalDataForList';
import { Button, Spin } from 'antd';

type Props = {
  programId: string;
  talentIds?: string[];
}
const ListTalentForReportData = (props: Props) => {
  const filterRole = { roles: 'talent' };
  const [isLoadingGenerate, setIsLoadingGenerate] = useState<string>();

  const { isLoading, data, handleSearch, pageQueries } =
    useFetchList<UserProperties>({
      httpRequest: httpRequest as any,
      endpoint: 'users',
      limit: 999999,
      initialQuery: {
        ...filterRole,
        ...(props.talentIds ? { userIds: props.talentIds.join(',') } : {}),
        programId: props.programId,
        status: 'active',
        sort: 'name:ASC',
      },
      pageQuery: {
        defaultValue: {
          page: 1,
        },
      },
    });

  const { isLoadingAdditionalData, additionalData, fetchAdditionalData } = useAdditionalDataForList<{
    [key: string]: { reports?: any[] };
  }>({
    id: 'userId',
    data,
    injects: [
      {
        injectedId: 'userId',
        endpoint: '/reports?userIds=',
        endpointId: 'userId',
        returnKey: 'reports',
        multiple: true,
      },
    ],
  });

  const columns: IAppTableColumn<UserProperties>[] = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      type: 'detail',
      showOriginalValue: true,
      keyId: 'userId',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
    },
    // {
    //   title: "PHONE NUMBER",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    {
      title: 'FINAL REPORT',
      dataIndex: 'finalReport',
      key: 'finalReport',
      render: (_, record) => {
        if (isLoadingAdditionalData || isLoadingGenerate === record.userId) {
          return <Spin />;
        }

        const finalReport = (additionalData[record.userId]?.reports || []).find(r => r.reportType === 'FINAL_REPORT');

        return finalReport ? (
          <div>
            <span style={{color: 'darkgrey'}}>Last update: {getDateTimeString(finalReport.updatedAt, 'short', 'short')}</span>
            <Button
              size="small" type="link" href={`/evaluation/${record.userId}`} target="_blank">Show</Button>

            <Button
              size="small"
              type="link"
              onClick={() => handleGenerateFinalReport(record.userId)}
            >
              Generate
            </Button>
          </div>
        ) : (
          <Button
            size="small"
            type="link"
            onClick={() => handleGenerateFinalReport(record.userId)}
          >
            Generate
          </Button>
        );
      }
    },
  ];

  const handleGenerateFinalReport = (talentId: string) => {
    setIsLoadingGenerate(talentId);
    httpRequest
      .post(`/final-reports/${props.programId}/${talentId}/generate`)
      .then((res) => {
        setIsLoadingGenerate(undefined);
        fetchAdditionalData();
      })
      .catch((err) => {
        getStandardError(err, { showToast: true });
        setIsLoadingGenerate(undefined);
      });
  };

  return (
    <React.Fragment>
      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || 'all',
        }}
        filters={[
          [
            {
              type: 'search',
              key: 'search',
              label: 'Search Talent',
              placeholder: 'Search talent by name, email, or phone',
              onChange: (value: any) => {
                handleSearch(value);
              },
              colSpan: 9,
            },
            // {
            //   type: "select",
            //   key: "status",
            //   label: "Status",
            //   options: [
            //     {
            //       value: "all",
            //       label: "All",
            //     },
            //     {
            //       value: "active",
            //       label: "Active",
            //     },
            //     {
            //       value: "inactive",
            //       label: "Inactive",
            //     },
            //   ],
            //   onChange: (value: any) => {
            //     filterDropdown({
            //       status: value === "all" ? "" : value,
            //     });
            //   },
            //   colSpan: 6,
            // },
          ],
        ]}
      />

      <AppTable
        isLoading={isLoading}
        keyId="userId"
        columns={columns}
        data={data}
        pagination={false}
        // onChangePage={changePage}
        // onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />
    </React.Fragment>
  );
};
export default ListTalentForReportData;
