export const MAPPING_EVALUATION_CHANGELOGS_ACTION: {[key: string]: {label: string; tagProps: {color: string}}} = {
  REQUESTED: {
    label: 'Requested',
    tagProps: {
      color: 'gray',
    },
  },
  CREATE: {
    label: 'Create',
    tagProps: {
      color: 'green',
    },
  },
  UPDATE: {
    label: 'Update',
    tagProps: {
      color: 'orange',
    },
  },
  REQUEST_FAILED: {
    label: 'Failed',
    tagProps: {
      color: 'red',
    },
  },
  SET_FINAL_NOTES: {
    label: 'Update Final Notes',
    tagProps: {
      color: 'purple',
    },
  },
  REQUESTED_SET_FINAL_NOTES: {
    label: 'Update Final Notes (Request)',
    tagProps: {
      color: 'gray',
    },
  },
};