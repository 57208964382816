import { Navigate } from 'react-router-dom';
import { useIsAuthenticated } from 'react-auth-kit';
import { ReactElement } from 'react';
import config from '../const/config';

export interface IProps {
  children: ReactElement;
}

const PublicRoute: React.FC<IProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated()) {
    return <Navigate to={config.defaultPath || '/dashboard'} />;
  }

  return children;
};

export default PublicRoute;
