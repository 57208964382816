import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Typography, message, Spin, Button } from 'antd';
import { httpRequest } from '../../../helpers/api';
import { EQuizType, ESubmitType, QuizAttemptProperties, QuizProperties } from '../../../types/quiz.type';
import { BaseResponsePaginationProps, DetailItem, getDateTimeString } from '@qlibs/react-components';

const {Title, Text} = Typography;

type Props = {
  quizId: string;

  label?: string;
  description?: string;

  currentEventId?: string;
}
export default function QuizDetailWithFunction({quizId, label, description, currentEventId}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [quiz, setQuiz] = useState<QuizProperties>({} as any);
  const [submissionSummary, setSubmissionSummary] = useState({totalSubmissions: 0, totalUserSubmit: 0, lastSubmit: ''});

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        setIsLoading(true);
        const [res, resAttempts] = await Promise.all([
          httpRequest.get<any>(`/quizzes/${quizId}`),
          httpRequest.get <
            BaseResponsePaginationProps<QuizAttemptProperties>>(
              `/quiz-attempt?quizIds=${quizId}`
            ),
        ]);
        let quizData;
        if (res && res?.data && res?.data?.payload) {
          setQuiz(res.data.payload);
          quizData = res.data.payload;
        } else {
          message.error('Failed to fetch data');
        }

        if (resAttempts?.data.payload.results) {
          let list = resAttempts.data.payload.results;
          if (
            quizData?.submitType === ESubmitType.PER_EVENT ||
            quizData?.submitType === ESubmitType.PER_EVENT_DAILY
          ) {
            list = list.filter(
              (item) => item.externalData?.eventId === currentEventId
            );
          }
          const groupedByUser = list.reduce((acc, curr) => {
            if (!acc[curr.userId]) {
              acc[curr.userId] = 0;
            }

            acc[curr.userId]++;

            return acc;
          },{} as {[userId: string]: number});

          list
            .filter((item) => item.finishedAt && !item.isFinishedBySystem)
            .sort((a, b) => new Date(b.finishedAt || '').getTime() - new Date(a.finishedAt || '').getTime());

          setSubmissionSummary({
            totalSubmissions: list.length,
            totalUserSubmit: Object.keys(groupedByUser).length,
            lastSubmit: getDateTimeString(list[0].finishedAt || '', 'short', 'short'),
          });
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchDetail();
  }, [quizId]);

  const {submissionUrl, detailUrl} = useMemo(() => {
    if (!quiz) {
      return {submissionUrl: '', detailUrl: ''};
    }

    let basePath = '/';
    if (quiz.quizType === EQuizType.SURVEY) {
      basePath += 'survey';
    } else if (quiz.quizType === EQuizType.POSTEST || quiz.quizType === EQuizType.PRETEST) {
      basePath += 'pretest--posttest';
    } else if (
      quiz.quizType === EQuizType.MICRO_LEARNING ||
      quiz.quizType === EQuizType.MICRO_LEARNING_PUBLIC
    ) {
      basePath += 'micro-learning';
    }
    return {
      detailUrl: basePath + '/' + quiz.quizId,
      submissionUrl: basePath + '/' + quiz.quizId + '/result',
    };
  }, [quiz])

  return (
    <Spin spinning={isLoading}>
      <Row style={{ marginTop: 10, marginBottom: 10 }}>
        <Col span={5}>
          <Title level={5}>{label || 'Data Quiz'}</Title>
          <Text style={{ color: '#768499' }}>
            {description || 'These are quiz details, you can’t change here'}
          </Text>
        </Col>
        <Col offset={1} span={16}>
          <Row>
            <Col span={24}>
              <DetailItem
                label="Title"
                type="link"
                value={quiz?.title}
                _aProps={{
                  target: "_blank",
                  href: detailUrl,
                  rel: "noreferrer",
                }}
              />
            </Col>
            <Col span={8}>
              <DetailItem
                label="Max Attempt"
                value={quiz?.maxAttempt || 'Unlimited'}
              />
            </Col>
            <Col span={8}>
              <DetailItem
                label="Max Shown Question"
                value={quiz?.shownQuestion || 'ALL'}
              />
            </Col>
            <Col span={8}>
              <DetailItem
                label="Random Question?"
                value={quiz?.randomQuestion ? 'YES' : 'NO'}
              />
            </Col>
            <Col span={24}>
              <Row
                style={{
                  backgroundColor: '#f2f2f2',
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <Col span={6}>
                  <DetailItem
                    label="Total Submissions"
                    value={submissionSummary.totalSubmissions}
                  />
                </Col>
                <Col span={6}>
                  <DetailItem
                    label="Total User Submit"
                    value={submissionSummary.totalUserSubmit}
                  />
                </Col>
                <Col span={6}>
                  <DetailItem
                    label="Last Submit"
                    value={submissionSummary.lastSubmit}
                  />
                </Col>
                <Col span={6}>
                  <Button
                    type="link"
                    size="small"
                    href={submissionUrl}
                    style={{ marginTop: 15, fontSize: 12 }}
                  >
                    See Submissions
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}
