import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, message, Spin, Upload, Button } from 'antd';
import { CourseProps, initialCourse } from '../../../types/course.type';
import { httpRequest } from '../../../helpers/api';
import { DetailItem } from '@qlibs/react-components';

const {Title, Text} = Typography;

type Props = {
  courseId: string;
}
export default function CourseDetailWithFunction({courseId}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState<CourseProps>(initialCourse);

  useEffect(() => {
    const fetchCourseDetail = async () => {
      try {
        setIsLoading(true);
        const res = await httpRequest.get<any>(`/course/${courseId}`);
        if (res && res?.data && res?.data?.payload) {
          setCourse(res.data.payload);
        } else {
          message.error('Failed to fetch data');
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchCourseDetail();
  }, [courseId]);

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col span={5}>
          <Title level={5}>Data Training</Title>
          <Text style={{ color: '#768499' }}>
            These are training details, you can’t change here
          </Text>
        </Col>
        <Col offset={1} span={16}>
          <Row>
            <Col span={24}>
              <Title
                level={5}
                style={{
                  color: '#768499',
                  marginBottom: 2,
                  fontSize: 14,
                }}
              >
                Training Title
              </Title>
              <Text>{course?.title}</Text>
            </Col>
          </Row>
          <Title
            level={5}
            style={{
              color: '#768499',
              marginTop: 20,
              marginBottom: 2,
              fontSize: 14,
            }}
          >
            Trainer
          </Title>
          <Text>{course?.trainer}</Text>

          <DetailItem
            label="Objective"
            type="html"
            value={course?.objective}
          />
        </Col>
      </Row>
    </Spin>
  );
}
