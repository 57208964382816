import { UploadOutlined } from '@ant-design/icons';
import { getStandardError } from '@qlibs/react-components';
import { Button, Form, message, Modal, Upload, UploadProps } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { useState } from 'react';
import { httpRequest } from '../../../helpers/api';

type Props = {
  userId: string;
  buttonType?: ButtonType;
  buttonTitle?: string;
};
export default function UploadInitialProfile({ buttonTitle, buttonType, userId }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const propsUpload: UploadProps = {
    name: 'file',
    accept: '.pdf',
    // method: 'PUT',
    multiple: false,
    showUploadList: false,
    // action: process.env.REACT_APP_BASE_URL + '/user-initial-profiles/' + userId,
    // headers: {
    //   authorization: 'Bearer ' + getToken(),
    // },
    onChange(info) {
      console.info('info.file', info.file);
      console.info('info.fileList', info.fileList);
      // return;

      setIsLoading(true);
      Modal.destroyAll();
      const formData = new FormData();
      formData.append('file', info.file.originFileObj as any);

      httpRequest
        .put('/user-initial-profiles/' + userId, formData)
        .then((res) => {
          message.success('File uploaded succesfully');
          window.location.reload();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          getStandardError(err, { showToast: true });
        });
      // if (info.file.status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      // if (info.file.status === 'uploading') {
      //   setIsLoading(true);
      //   Modal.destroyAll();
      //   message.destroy();
      //   message.loading('Uploading...');
      // } else if (info.file.status === 'done') {
      //   message.destroy();
      //   message.success(`File uploaded successfully`);
      //   setIsLoading(false);
      //   Modal.destroyAll();
      // } else if (info.file.status === 'error') {
      //   message.destroy();
      //   message.error(`File upload failed.`);
      //   setIsLoading(false);
      //   // getStandardError()
      // }
    },
  };

  return (
    <Button
      icon={<UploadOutlined />}
      loading={isLoading}
      disabled={isLoading}
      size="small"
      type={buttonType || 'link'}
      onClick={() => {
        Modal.confirm({
          title: 'Upload Talent Initial Profile (PDF)',
          content: (
            <Form style={{ marginTop: 10 }}>
              <Upload {...propsUpload}>
                <Button
                  type="primary"
                  loading={isLoading}
                  icon={<UploadOutlined />}
                >
                  Click to Upload
                </Button>
              </Upload>
            </Form>
          ),
          closable: true,
          footer: false,
        });
      }}
    >
      {buttonTitle || 'Upload'}
    </Button>
  );
}