import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Space, Spin, Tag, Typography } from "antd";
import { httpRequest } from "../../../helpers/api";
import {
  AppTable,
  formatDate,
  IAppTableColumn,
  NotSet,
  useFetchList,
} from "@qlibs/react-components";

import {
  EQuizType,
  ESubmitType,
  QuizAttemptProperties,
  QuizProperties,
} from "../../../types/quiz.type";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { UserProperties } from "../../../services/openapi";
import { getBasePath } from "../helpers/menuPathGenerator";
import useAdditionalDataForList from "../../../hooks/useAdditionalDataForList";
import { EventsProps } from "../../../types/event.type";
import ExcelJS from 'exceljs';
import moment from "moment";
import { stripHtml } from "string-strip-html";

interface ILocation {
  quizId: string;
}

export interface UserWithAttemptsProps extends UserProperties {
  quizAttempts: QuizAttemptProperties[];
}

const ByTalent = ({
  quiz,
  roles,
}: {
  quiz: QuizProperties | undefined;
  roles: string;
}) => {
  const { quizId } = useParams<keyof ILocation>() as ILocation;
  const filterRole = { roles };

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    query,
    setQuery,
    pageQueries,
  } = useFetchList<UserWithAttemptsProps>({
    httpRequest: httpRequest as any,
    endpoint: 'users',
    initialQuery: {
      quizIds: quizId,
      includeData: 'quizAttempts,quizAttemptsDetail',
      ...filterRole,
      sort: 'name:ASC',
    },
  });

  const list = useMemo(() => {
    return data.map((item: any) => {
      const lastData: any = (item.quizAttempts ?? []).sort(
        (a: any, b: any) => b.attempt - a.attempt
      )[0];

      item.lastDataStartAt = lastData.startAt;
      item.lastDataScore = lastData.score;
      item.lastDataEventId = lastData.externalData?.eventId;
      item.lastDataFinishedAt = lastData.finishedAt;
      item.lastDataIsFinishedBySystem = lastData.isFinishedBySystem;

      return item;
    });
  }, [data]);

  const { isLoadingAdditionalData, additionalData } = useAdditionalDataForList<{
    [userId: string]: {
      event: EventsProps;
    };
  }>({
    id: "userId",
    data: list,
    injects: [
      {
        injectedId: "lastDataEventId",
        endpoint: "/event?eventIds=",
        endpointId: "eventId",
        returnKey: "event",
      },
    ],
  });

  const columns: IAppTableColumn<UserWithAttemptsProps>[] = [
    {
      title: 'TALENT NAME',
      dataIndex: 'name',
      key: 'user',
      type: 'detail',
      keyId: 'id',
      showOriginalValue: true,
      render: (name: string, record: UserWithAttemptsProps) => (
        <Link
          className="table-link"
          to={`/${getBasePath()}/${quiz?.quizId}/result/user/${record.userId}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: 'TOTAL ATTEMPT',
      dataIndex: 'attempt',
      key: 'attempt',
      render: (_: any, record: UserWithAttemptsProps) => (
        <>{record.quizAttempts?.length || 0}</>
      ),
    },
    quiz?.quizType !== EQuizType.SURVEY
      ? {
        title: 'MIN & MAX SCORE',
        dataIndex: 'minMaxScore',
        key: 'minMaxScore',
        render: (_: any, record: UserWithAttemptsProps) => {
          const res = (record.quizAttempts ?? [])
            .filter((q) => q.finishedAt && q.score)
            .map((v) => v.score)
            .filter(Boolean)
            .sort((a: any, b: any) => a - b);

          return (
            <>
              <Space direction="vertical">
                <Typography.Text strong>
                  Min: {res.length ? res[0] : 0}
                </Typography.Text>
                <Typography.Text strong>
                  Max: {res.length ? res[res.length - 1] : 0}
                </Typography.Text>
              </Space>
            </>
          );
        },
      }
      : {},

    quiz?.quizType !== EQuizType.SURVEY
      ? {
        title: 'LATEST SCORE',
        dataIndex: 'lastDataScore',
        key: 'lastDataScore',
        // render: (_: any, record: UserWithAttemptsProps) => {
        //   const lastData = (record.quizAttempts ?? [])
        //     .filter((q) => q.finishedAt && q.score)
        //     .sort((a, b) => b.attempt - a.attempt)[0];

        //   return (
        //     <>{typeof lastData?.score === "number" ? lastData?.score : 0}</>
        //   );
        // },
      }
      : {},

    quiz?.quizType === EQuizType.SURVEY &&
      quiz?.submitType === ESubmitType.DAILY
      ? {
        title: 'LAST START AT',
        dataIndex: 'lastDataStartAt',
        key: 'lastDataStartAt',
        type: 'date',
        // render: (_: any, record: UserWithAttemptsProps) => {
        //   const lastData = (record.quizAttempts ?? []).sort(
        //     (a, b) => b.attempt - a.attempt
        //   )[0];

        //   return (
        //     <>
        //       {lastData ? (
        //         formatDate(lastData?.startAt, "DD MMM YYYY HH:mm") || (
        //           <NotSet />
        //         )
        //       ) : (
        //         <NotSet />
        //       )}
        //     </>
        //   );
        // },
      }
      : {},

    quiz?.quizType === EQuizType.SURVEY &&
      (quiz?.submitType === ESubmitType.PER_EVENT ||
        quiz?.submitType === ESubmitType.PER_EVENT_DAILY)
      ? {
        title: 'LAST EVENT',
        dataIndex: 'lastDataEventId',
        key: 'lastDataEventId',
        render: (value, record) =>
          isLoadingAdditionalData ? (
            <Spin />
          ) : additionalData[record.userId]?.event ? (
            additionalData[record.userId]?.event?.title
          ) : (
            <NotSet />
          ),
        // render: (_: any, record: UserWithAttemptsProps) => {
        //   const lastData = (record.quizAttempts ?? []).sort(
        //     (a, b) => b.attempt - a.attempt
        //   )[0];

        //   return (
        //     <>
        //       {lastData ? (
        //         <>{lastData?.externalData?.eventId || <NotSet />}</>
        //       ) : (
        //         <NotSet />
        //       )}
        //     </>
        //   );
        // },
      }
      : {},
    {
      title: 'LATEST SUBMIT',
      dataIndex: 'latestSubmit',
      key: 'latestSubmit',
      render: (_: any, record: UserWithAttemptsProps) => {
        const lastData = (record.quizAttempts ?? [])
          .filter((q) => q.finishedAt)
          .sort((a, b) => b.attempt - a.attempt)[0];

        return (
          <Space direction="vertical">
            <div>
              {lastData && lastData?.finishedAt ? (
                formatDate(lastData?.finishedAt, 'DD MMM YYYY HH:mm')
              ) : (
                <NotSet />
              )}
            </div>
            {lastData?.isFinishedBySystem && (
              <Tag color="red">Finished By System</Tag>
            )}
          </Space>
        );
      },
    },
  ];

  const handleDownloadExcelTemplate = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Summary');
    const worksheetRawAttempt = workbook.addWorksheet('Raw Attempt')
    const worksheetRawAttemptDetail = workbook.addWorksheet('Raw Attempt Detail')


    worksheetRawAttempt.columns = [
      { header: 'NAME', key: 'name', width: 25 },
      { header: 'EMAIL', key: 'email', width: 30 },
      { header: 'DATE', key: 'date', width: 25 },
      { header: 'TOTAL ATTEMPT', key: 'totalAttempt', width: 25 },
      { header: 'SCORE', key: 'score', width: 25 },
      { header: 'START AT', key: 'startAt', width: 25 },
      { header: 'FINISHED AT', key: 'finishedAt', width: 25 },
      { header: 'NOTE FROM MENTOR', key: 'notesFromMentor', width: 25 }
    ]

    worksheetRawAttemptDetail.columns = [
      { header: 'NAME', key: 'name', width: 25 },
      { header: 'EMAIL', key: 'email', width: 30 },
      { header: 'STATUS', key: 'status', width: 25 },
      { header: 'QUIZ TITLE', key: 'quizTitle', width: 25 },
      { header: 'DATE', key: 'date', width: 25 },
      { header: 'QUESTION', key: 'question', width: 25 },
      { header: 'ANSWER', key: 'answer', width: 25 },
    ]

    worksheet.columns = [
      { header: 'TALENT NAME', key: 'name', width: 25 },
      { header: 'TOTAL ATTEMPT', key: 'totalAttempt', width: 15 },
      { header: 'MIN SCORE', key: 'minScore', width: 10 },
      { header: 'MAX SCORE', key: 'maxScore', width: 10 },
      { header: 'LATEST SCORE', key: 'latestScore', width: 15 },
      { header: 'LATEST SUBMIT', key: 'latestSubmit', width: 20 },
    ];

    list.forEach((record) => {
      const scores = (record.quizAttempts ?? [])
        .filter((q: any) => q.finishedAt && q.score)
        .map((v: any) => v.score)
        .filter(Boolean)
        .sort((a: any, b: any) => a - b);

      const minScore = scores.length ? scores[0] : 0;
      const maxScore = scores.length ? scores[scores.length - 1] : 0;

      const lastData = (record.quizAttempts ?? []).sort(
        (a: any, b: any) => b.attempt - a.attempt
      )[0];

      const latestScore = lastData?.score ?? 0;
      const latestSubmit = lastData?.finishedAt
        ? formatDate(lastData.finishedAt, 'DD MMM YYYY HH:mm')
        : 'Not Set';

      worksheet.addRow({
        name: record.name,
        totalAttempt: record.quizAttempts?.length || 0,
        minScore: minScore,
        maxScore: maxScore,
        latestScore: latestScore,
        latestSubmit: latestSubmit,
      });

      record.quizAttempts.forEach((attempt: any) => {
        const rowRaw = {
          name: record.name,
          email: record.email,
          date: moment(attempt.startAt).format('DD MMM YYYY'),
          totalAttempt: attempt.attempt ?? 'Not Set',
          score: attempt.score ?? 'Not Set',
          startAt: attempt.startAt ? moment(attempt.startAt).format('DD MMM YYYY HH:mm') : 'Not Set',
          finishedAt: attempt.finishedAt ? moment(attempt.finishedAt).format('DD MMM YYYY HH:mm') : 'Not Set',
          notesFromMentor: attempt.notesFromMentor ?? 'Not Set'
        }
        const newRowRaw = worksheetRawAttempt.addRow(rowRaw);
        attempt.attemptDetails ? attempt.attemptDetails.map((x: any) => {
          const rowRawDetails = {
            name: record.name,
            email: record.email,
            status: 'SUBMIT',
            quizTitle: attempt.quiz ? attempt.quiz.title : 'Not Set',
            date: moment(x.createdAt).format('DD MMM YYYY'),
            question: x.metaQuestions ? stripHtml(x.metaQuestions.questionText).result : 'Not Set',
            answer: x.answerText ? stripHtml(x.answerText).result :
              (x.choosenAnswerIds && x.choosenAnswerIds.length > 0
                ? x.metaQuestionAnswers
                  .filter((y: any) => x.choosenAnswerIds.includes(y.answerId))
                  .map((y: any) => y.answer)
                  .join('\n')
                : 'Not Set')

          }

          const newRowRawDetail = worksheetRawAttemptDetail.addRow(rowRawDetails);

        }) : (() => {
          const rowRawDetails = {
            name: record.name,
            email: record.email,
            status: 'NOT SUBMIT',
            quizTitle: "Not Set",
            date: "Not Set",
            question: "Not Set",
            answer: "Not Set",
          };
          worksheetRawAttemptDetail.addRow(rowRawDetails);
        })();
      })
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = {
        bold: true,
        color: { argb: '000000' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    [worksheetRawAttempt.getRow(1)].forEach(row => {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: '000000' },
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
      });
    });

    [worksheetRawAttemptDetail.getRow(1)].forEach(row => {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: '000000' },
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
      });
    });

    const excelFile = await workbook.xlsx.writeBuffer();
    const getFormattedDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}`;

    }

    const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `Quiz Submission ${quiz?.title ?? ''} - downloaded at ${getFormattedDate()}.xlsx`;
    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  };


  return (
    <React.Fragment>
      <div
        style={{
          margin: "12px 0px",
        }}
      >
        <div style={{ display: 'flex', width: "100%" }}>
          <div style={{width: '100%'}}>

          <RowFilter
            filterValues={{
              search: pageQueries.search,
            }}
            filters={[
              [
                {
                  type: "search",
                  key: "search",
                  label: "Search",
                  placeholder: "Search by talent name",
                  onChange: (value: any) => {
                    setQuery({
                      ...query,
                      search: value,
                    });
                  },
                  colSpan: 12,
                },
              ],
            ]}
            
          />
          </div>
          <Button type="primary" style={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }} onClick={handleDownloadExcelTemplate}>
            Download Excel
          </Button>
        </div>

        <Card bordered={false} size="small" loading={isLoading}>
          <AppTable
            isLoading={isLoading}
            keyId="userId"
            columns={columns.filter((v) => v.key)}
            data={list}
            pagination={pagination}
            onChangeLimit={changeLimit}
            onChangePage={changePage}
          />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ByTalent;
