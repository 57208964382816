import {create} from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';
import { ProgramProps } from '../types/program.type';

type State = {
  selectedProgram: ProgramProps | undefined;
  programs: ProgramProps[];
  setPrograms: (data: ProgramProps[]) => void;
  setSelectedProgram: (data: ProgramProps) => void;
};

const useProgramStore = create(
  persist<State>(
    (set, get) => ({
      selectedProgram: undefined,
      programs: [],
      setPrograms: (data: ProgramProps[]) => {
        const list = sortPrograms(data);

        set((state) => ({
          programs: list,
          ...(state.selectedProgram ? {} : { selectedProgram: list[0] }),
        }));
      },
      setSelectedProgram: (data: ProgramProps) => {
        set({ selectedProgram: data });
      },
    }),
    {
      name: 'program__' + process.env.REACT_APP_STAGE, // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useProgramStore;


// Fungsi untuk mengecek apakah sebuah program aktif (today berada di antara startAt dan endAt)
const isActive = (program: ProgramProps, today: Date): boolean => {
  return today >= program.startAt && today <= program.endAt;
};

// Fungsi untuk mengurutkan array program
const sortPrograms = (programs: ProgramProps[]): ProgramProps[] => {
  const today = new Date(); // tanggal saat ini

  return programs.sort((a, b) => {
    const isAActive = isActive(a, today);
    const isBActive = isActive(b, today);

    // Jika salah satu program aktif dan yang lain tidak, letakkan yang aktif di atas
    if (isAActive && !isBActive) return -1;
    if (!isAActive && isBActive) return 1;

    // Jika keduanya sama-sama aktif atau sama-sama tidak aktif, urutkan berdasarkan startAt
    return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
  });
};
