import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Collapse, CollapseProps, Empty, Row, theme, Typography } from 'antd';
import React, { CSSProperties } from 'react'
import { ICompetence, ICompetenceItemLevel0 } from '../types/competence.type';
import EvalBadgeAverage from './EvalBadgeAverage';
import EvalProgressInput from './EvalProgressInput';
import ListEvaluationTableAndForm from './ListEvaluationTableAndForm';
import TooltipFormula from './TooltipFormula';

const { Text } = Typography;
type Props = {
  userId: string;
  competenceCode: string;
  data: ICompetence;

  hasChanges: { [key: string]: { eventIds: string[] } };
  onChangesAnything: (competenceCode: string, eventId?: string) => void;
};

export default function ListEvaluation(props: Props) {
  if (!props.data) {
    return <></>
  }
  if (props.data.items_level0) {
    return (
      <div style={{ paddingTop: 20 }}>
        <SectionEvaluation
          items={props.data.items_level0}
          hasChanges={props.hasChanges}
          competenceCode={props.competenceCode}
          userId={props.userId}
          onChangesAnything={props.onChangesAnything}
        />
      </div>
    );
  }
  return <>Something went wrong...</>
  //  else {
  //   return <ListEvaluationTableAndForm hasChanges={props.hasChanges} competenceCode={props.competenceCode} userId={props.userId} items={props.data.items} onChangesAnything={props.onChangesAnything} />
  // }
}

type SectionEvaluationProps = {
  userId: string;
  competenceCode: string;
  hasChanges: { [key: string]: { eventIds: string[] } };

  items: ICompetenceItemLevel0[];
  onChangesAnything: (competenceCode: string, eventId?: string) => void;
};

function SectionEvaluation(props: SectionEvaluationProps) {
  console.info('props.has', props.hasChanges);
  const { token } = theme.useToken();

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle
  ) =>
    props.items.map((item, index) => {

      return {
        key: item.code,
        label: (
          <Row justify="space-between">
            <Col span={12}>
              <Text style={{ fontWeight: 'bold' }}>{item.title}</Text>
            </Col>
            <Col>
              <TooltipFormula
                originalScore={item.originalScore}
                finalScore={item.finalScore}
                items={item.items_level1}
                operator={item.items_level1_operator}
                disableAutoCalculateSubItems={item.code === 'training'}
              />

              <EvalBadgeAverage
                label={(item.items_level1_operator === 'sum' ? '(sum) ' : '(average) ') + "Score"}
                average={item.originalScore || 0}
                // hasChanges={hasChanges}
                color="orange"
              />

              <span> x </span>

              <EvalBadgeAverage
                label="Weight"
                average={(item.weight || 0) * 100}
                suffix="%"
                color="gray"
              />

              <span>=</span>

              <EvalBadgeAverage
                label="Score"
                average={item.finalScore || 0}
                // hasChanges={hasChanges}
              />

              <EvalProgressInput
                items={item.items_level1}
                // hasChanges={hasChanges ? true : false}
              />
            </Col>
            {/* <Col>
              {hasChanges ? (
                <Text style={{ color: 'red' }}>* You have unsaved changes</Text>
              ) : item ? (
                <Row align="middle">
                  <Col>
                    <CheckCircleFilled
                      style={{ color: 'green', fontSize: 20 }}
                    />
                  </Col>
                  <Col>
                    <Text style={{ color: 'green', marginLeft: 10 }}>
                      All changes has been saved
                    </Text>
                  </Col>
                </Row>
              ) : (
                false
              )}
            </Col> */}
          </Row>
        ),
        children: (
          <ListEvaluationTableAndForm
            items={item.items_level1}
            hasChanges={props.hasChanges}
            competenceCode={props.competenceCode}
            code={item.code}
            userId={props.userId}
            // eventId={item.code}
            // onChangesAnything={props.onChangesAnything}
          />
        ),
        style: panelStyle,
      };
    });

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  if (props.items.length === 0) {
    return (
      <Empty style={{ color: 'red' }}>
        Empty
      </Empty>
    );
  }

  return (
    <Collapse
      bordered={false}
      // defaultActiveKey={['0']}
      activeKey={props.items.map(item => item.code)}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{ background: token.colorBgContainer }}
      items={getItems(panelStyle)}
    />
  );
}
