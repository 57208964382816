import { Form, GetRef, Input, InputRef } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  title: string;
  finalScore: number;
  notes: string;
}

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  autoCalculate?: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  required?: boolean;
}

export const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  autoCalculate,
  children,
  dataIndex,
  record,
  handleSave,
  required,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: required, message: `${title} is required.` }, {validator(rule, value, callback) {
            if (dataIndex === 'finalScore' && Number(value > 100)) {
              callback('Maximum 100');
            } else if (dataIndex === 'finalScore' && Number(value < 0)) {
              callback('Minimum 0');
            }
            callback(undefined)
        },}]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} {...(dataIndex === 'finalScore' ? {type: 'number', max: 100, min: 0} : {})} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingInlineEnd: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}{autoCalculate ? <span style={{marginLeft: 5, color: 'gray', fontSize: 11, fontStyle: 'italic'}}>(auto calculate)</span> : false}</td>;
};
