import React from "react";
import {
  HeaderSection,
} from "@qlibs/react-components";
import ListTalentForUserDevice from './ListTalentForUserDevice';

const UserDevice = () => {
  return (
    <React.Fragment>
      <HeaderSection title={"User Device"} />

      <ListTalentForUserDevice />
    </React.Fragment>
  );
};
export default UserDevice;
