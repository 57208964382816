import { getStandardError } from '@qlibs/react-components';
import { useEffect, useState } from 'react';
import { httpRequest } from '../helpers/api';
import useProgramStore from '../zustand/useProgramStore';

export default function useProgram() {
  const selectedProgram = useProgramStore((state) => state.selectedProgram);
  const setSelectedProgram = useProgramStore((state) => state.setSelectedProgram);
  const programs = useProgramStore((state) => state.programs);
  const setPrograms = useProgramStore((state) => state.setPrograms);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setIsLoading(true);
    httpRequest
      .get('/program')
      .then((res) => {
        setIsLoading(false);
        if (res.data.payload.results) {
          setPrograms(res.data.payload.results);
        } else {
          setPrograms([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setPrograms([]);
        getStandardError(err, { showToast: true });
      });
  }

  return { isLoading, selectedProgram, setSelectedProgram, programs };
}