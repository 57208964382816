import { Card, Spin } from 'antd';
import AuthLayout from '../layout/AuthLayout'
import AppVersion from '../../components/AppVersion';
import { useSignOut } from 'react-auth-kit';
import { useEffect } from 'react';

const Logout = () => {
  const signOut = useSignOut();

  useEffect(() => {
    signOut();
  }, [])
  
	return (
    <AuthLayout>
      <Card style={{ width: 500 }} bordered={false}>
        <Spin />
        <AppVersion />
      </Card>
    </AuthLayout>
  );
}

export default Logout