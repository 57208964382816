import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { getStandardError } from '@qlibs/react-components';
import { Alert, Button, message, Modal, Row } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { httpRequest } from '../../../helpers/api';
import PDFViewer from './PDFViewer';
import UploadInitialProfile from './UploadInitialProfile';

type Props = {
  mode?: 'download' | 'preview';

  userId: string;
  name: string;
}
export default function DownloadInitialProfile({userId, name, mode}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [manualDownloadUrl, setManualDownloadUrl] = useState<string>();
  const [previewError, setPreviewError] = useState<boolean>(false);

  const downloadMe = async () => {
    try {
      setIsLoading(true);
      const res = await httpRequest.get(
        `/user-initial-profiles/${userId}`,
      );

      if (res.data.payload) {
        const response = await axios.get(
          res.data.payload.fileLinkCache,
          {
            responseType: 'blob',
          }
        );

        setManualDownloadUrl(res.data.payload.fileLinkCache);

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const url = window.URL.createObjectURL(blob);
        // const url = res.data.payload.fileLinkCache;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Talent Initial Profile - ${name}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success('Downloaded successfully');
      }
      setIsLoading(false);
      setPreviewError(false);
    } catch (error) {
      setIsLoading(false);
      getStandardError(error, { showToast: true });
      setPreviewError(true);
    }
  };

  const downloadByOpenLink = async () => {
    try {
      setIsLoading(true);
      const res = await httpRequest.get(`/user-initial-profiles/${userId}`);

      if (res.data.payload) {
        const url = res.data.payload.fileLinkCache;
        // const url = res.data.payload.fileLinkCache;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Talent Initial Profile - ${name}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success('Downloaded successfully');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      getStandardError(error, { showToast: true });
    }
  };

  const previewMe = async () => {
    try {
      setIsLoading(true);
      const res = await httpRequest.get(`/user-initial-profiles/${userId}`);

      if (res.data.payload) {
        setPdfUrl(res.data.payload.fileLinkCache);
        setIsShowPreview(true);
        // const response = await axios.get(res.data.payload.fileLinkCache, {
        //   responseType: 'blob',
        // });

        // const blob = new Blob([response.data], {
        //   type: response.headers['content-type'],
        // });

        // const url = window.URL.createObjectURL(blob);
        // // const url = res.data.payload.fileLinkCache;
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `Talent Initial Profile - ${name}.pdf`);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

        // message.success('Downloaded successfully');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      getStandardError(error, { showToast: true });
    }
  };

  if (mode === 'preview') {
    // const arrayBuffer = await fetch(YOUR_PDF_URL);
    // const blob = await arrayBuffer.blob();
    // const url = await blobToURL(blob);

    // use the url here
    return (
      <React.Fragment>
        <Button
          icon={<EyeOutlined />}
          loading={isLoading}
          disabled={isLoading}
          size="small"
          type="primary"
          onClick={() => {
            previewMe();
          }}
        >
          Preview
        </Button>

        <Modal
          title={`Preview Initial Profile - ${name}`}
          width={900}
          open={isShowPreview}
          closable
          footer={false}
          onClose={() => setIsShowPreview(false)}
          onCancel={() => setIsShowPreview(false)}
        >
          <Alert
            type="info"
            style={{ marginBottom: 10 }}
            showIcon
            message="Want to reupload initial profile for this talent?"
            action={
              <UploadInitialProfile
                buttonType="primary"
                buttonTitle="Reupload"
                userId={userId}
              />
            }
          />

          {pdfUrl ? <PDFViewer pdfUrl={pdfUrl} /> : false}
          {/* <Document
            file={url}
            onLoadError={console.error}
          ></Document> */}

          {previewError && manualDownloadUrl ? (
            <Button
              icon={<DownloadOutlined />}
              loading={isLoading}
              disabled={isLoading}
              size="small"
              type="primary"
              onClick={() => {
                downloadByOpenLink();
              }}
            >
              Download Now
            </Button>
          ) : (
            false
          )}
        </Modal>
      </React.Fragment>
    );
  }

  return (
    <Button
      icon={<DownloadOutlined />}
      loading={isLoading}
      disabled={isLoading}
      size="small"
      type="primary"
      onClick={() => {
        downloadMe();
      }}
    >
      Download
    </Button>
  )
}

// utils
function blobToURL(blob: any) {
  return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
  });
}
