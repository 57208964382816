import React from "react";
import ReactJsonViewCompare from "react-json-view-compare";
import { diff, detailedDiff } from "deep-object-diff";

type Props = {
  mode?: "json" | "table";
  oldData: any;
  newData: any;
};
export default function CompareDataView(props: Props) {
  if (props.mode === "json") {
    return (
      <ReactJsonViewCompare oldData={props.oldData} newData={props.newData} />
    );
  } else {
    const differences = diff(props.oldData, props.newData);

    // Fungsi rekursif untuk mengumpulkan perbedaan
    const collectDifferences = (obj: any, parentKey = "") => {
      let changes: any[] = [];
      Object.keys(obj).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          changes = [...changes, ...collectDifferences(obj[key], fullKey)];
        } else {
          changes.push({
            key: fullKey,
            oldValue: getValueByPath(props.oldData, fullKey),
            newValue: !props.newData ? undefined : String(obj[key]),
          });
        }
      });
      return changes;
    };

    const changesArray = collectDifferences(
      !props.newData ? props.oldData : differences
    );

    return (
      <div>
        {changesArray.length > 0 ? (
          <table border={1} cellPadding="10" className='table-compare-data'>
            <thead>
              <tr>
                <th>Field</th>
                <th style={{ width: '100px !important;' }}>Old Value</th>
                <th style={{ width: '100px !important;' }}>New Value</th>
              </tr>
            </thead>
            <tbody>
              {changesArray.map((change, index) => (
                <tr key={index}>
                  <td>{change.key}</td>
                  <td style={{ width: '100px !important;' }}>
                    {change.oldValue !== undefined
                      ? JSON.stringify(change.oldValue)
                      : 'N/A'}
                  </td>
                  <td style={{ width: '100px !important;' }}>{change.newValue || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No differences found</p>
        )}
      </div>
    );
  }
}

// Fungsi untuk mengambil nilai dari objek menggunakan string keypath
const getValueByPath = (obj: any, path: string) => {
  return path.split(".").reduce((acc, key) => {
    if (acc && acc[key] !== undefined && acc[key] !== null) {
      return acc[key];
    }
    return undefined; // Jika null/undefined, kembalikan undefined
  }, obj);
};
