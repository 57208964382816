import { getStandardError } from '@qlibs/react-components';
import { Alert, Badge, message, Modal, Table, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { httpRequest } from '../../../helpers/api';
import { calculateAverage } from '../helpers/average';
import { ICompetenceItemLevel2, ICompetenceItemLevel3 } from '../types/competence.type';
import { IEvaluation } from '../types/evaluation.type';
import HasChangesSubmitButton from './HasChangesSubmitButton';
import { EditableCell, EditableRow } from './InputTableCell';

const {Text} = Typography;

type FormInputListProps = {
  level: 2 | 3, // 2 tidak perlu ada subCode, 3 wajib ada subCode
  userId: string;
  code: string;
  eventId: string;
  subCode?: string;

  items: (ICompetenceItemLevel2 | ICompetenceItemLevel3)[];

  onChangesAnything?: () => void;

  readonly?: boolean;
};
export default function FormInputList(props: FormInputListProps) {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [list, setList] = useState<(ICompetenceItemLevel2 | ICompetenceItemLevel3)[]>(props.items || []);
  const [hasChanges, setHasChanges] = useState(false);

  const hasMinScore = true;

  console.info('FormInputList - code', props.code);
  console.info('FormInputList - eventId', props.eventId);
  console.info('FormInputList - subCode', props.subCode);
  console.info('FormInputList - list', list);
  const realtimeAverage = useMemo(() => calculateAverage(list.map(curr => Number(curr.finalScore) || 0), true), [list]);
  if (props.level === 2 && props.subCode) {
    throw new Error("Level 2 didn't need subCode, please remove that");
  } else if (props.level === 3 && !props.subCode) {
    throw new Error("Level 3 - subCode is required, please add subCode");
  }
  const baseColumns: any[] = [
    {
      title: 'Criteria *',
      dataIndex: 'title',
      key: 'title',
      editable: false,
      required: true,
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      editable: false,
      width: 300,
      render: (value: any) => {
        if (value?.includes(' | ')) {
          return (
            <ul>
              {
                value.split(' | ').map((item: string, key: number) => (
                  <li key={key}>{item}</li>
                ))
              }
            </ul>
          )
        } else {
          return value;
        }
      }
    },
    hasMinScore
      ? {
          title: 'Min Score',
          dataIndex: 'minScore',
          key: 'minScore',
          editable: false,
          width: 150,
        }
      : undefined,
    {
      title: 'Actual Score',
      dataIndex: 'finalScore',
      key: 'finalScore',
      editable: !props.readonly,
      width: 150,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      editable: !props.readonly,
      width: 200,
    },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: 100,
    //   render: (value: any, record: IEvaluation) => {
    //     return (
    //       <React.Fragment>
    //         {(props.code === 'ojt' || props.code === 'dbi') && (
    //           <Button
    //             size="small"
    //             icon={<EditOutlined style={{ color: 'red', fontSize: 20 }} />}
    //             onClick={() => {
    //               // handleDelete(record);
    //               Modal.confirm({
    //                 title: record.title,
    //                 content: (
    //                   <Form>
    //                     {record.description ? (
    //                       <DetailItem label="" value={record.description} />
    //                     ) : (
    //                       false
    //                     )}
    //                     <Form.Item label="Score" name="score">
    //                       <Input />
    //                     </Form.Item>

    //                     <Button type="primary" htmlType="submit">
    //                       SUBMIT
    //                     </Button>
    //                   </Form>
    //                 ),
    //                 footer: false,
    //                 closable: true,
    //               });
    //             }}
    //           ></Button>
    //         )}

    //         {record.type === 'input_score' && (
    //           <Button
    //             size="small"
    //             icon={<EditOutlined style={{ color: 'red', fontSize: 20 }} />}
    //             onClick={() => {
    //               // handleDelete(record);
    //               Modal.confirm({
    //                 title: record.title,
    //                 content: (
    //                   <FormInputScore
    //                     userId={props.userId}
    //                     code={props.code}
    //                     subCode={record.subCode || ''}
    //                     initialValues={{ score: record.score }}
    //                     title={record.title}
    //                   />
    //                 ),
    //                 footer: false,
    //                 closable: true,
    //               });
    //             }}
    //           ></Button>
    //         )}
    //       </React.Fragment>
    //     );
    //   },
    // },
  ].filter((item) => item);

  const onChangeAnything = () => {
    setHasChanges(true);
    if (props.onChangesAnything) {
      props.onChangesAnything();
    }
  }

  const submitChanges = async () => {
    setIsLoadingSubmit(true);
    try {
      let listData: IEvaluation[] = [];
      if (props.subCode) {
        console.info('akan ada codesub');
        listData = list.map((item) => ({
          userId: props.userId,
          code: props.code,
          eventId: props.eventId,
          subCode: props.subCode || '',
          subCodeSub: (item as ICompetenceItemLevel3).subCodeSub || '',
          title: item.title,
          description: item.description,
          score: item.finalScore,
          minScore: Number(item.minScore),
          notes: item.notes,
        }));
      } else {
        console.info('oooo');
        listData = list.map((item) => ({
          userId: props.userId,
          code: props.code,
          eventId: props.eventId,
          subCode: item.subCode || '',
          title: item.title,
          description: item.description,
          score: item.finalScore,
          minScore: Number(item.minScore),
          notes: item.notes,
        }));
      }
      const data = {
        userId: props.userId,
        data: listData,
      };
      console.info('FormInputList - submitChanges - data', data);
      await httpRequest.post(`evaluation/${props.code}/${props.eventId}`, data);

      message.success('Updated successfully');
      Modal.destroyAll();
      setIsLoadingSubmit(false);
      window.location.reload();
    } catch(err) {
      getStandardError(err, {showToast: true});
      setIsLoadingSubmit(false);
    }
  }

  const handleSave = (row: ICompetenceItemLevel2 | ICompetenceItemLevel3) => {
    const newData = [...list];
    let index;
    if ((row as any).subCodeSub) {
      index = newData.findIndex((item) => (row as ICompetenceItemLevel3).subCodeSub === (item as ICompetenceItemLevel3).subCodeSub);
    } else {
      index = newData.findIndex((item) => row.subCode === item.subCode);  
    }
    row.finalScore = Number(row.finalScore);
    if (isNaN(row.finalScore)) {
      row.finalScore = list[index].finalScore || 0;
      if (isNaN(Number(row.finalScore))) {
        row.finalScore = 0;
      }
    }
    newData[index] = row;
    setList(newData);

    if (
      newData[index].finalScore !== list[index].finalScore ||
      newData[index].notes !== list[index].notes
    ) {
      onChangeAnything();
    }
    // props.onChangesAnything(props.competenceCode, props.eventId);
  };

  const columns = baseColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IEvaluation) => ({
        record,
        autoCalculate: false,
        editable: true,
        // editable: col.editable
        //   ? record.type === 'auto_calculate'
        //     ? col.title !== 'Score'
        //     : true
        //   : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        required: col.required,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div style={hasChanges ? { backgroundColor: '#FFEEA9', padding: 20 } : {}}>
      <div className="my-3">
        {hasChanges && (
          <HasChangesSubmitButton
            isLoading={isLoadingSubmit}
            onSubmit={() => submitChanges()}
            subtitle={
              props.subCode ? (
                <Text style={{ color: 'gray', fontSize: 11 }}>
                  New Average:{' '}
                  <Badge
                    overflowCount={100}
                    size="small"
                    style={{ fontSize: 11 }}
                    count={realtimeAverage.toFixed(2)}
                    color="orange"
                  />
                </Text>
              ) : (
                false
              )
            }
          />
        )}

        {/* <Text>{props.code} | {props.eventId}</Text> */}

        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={list}
          columns={columns}
          pagination={false}
          style={{ backgroundColor: '#FFEEA9' }}
        />

        {/* <Row justify="end">
          <Col span={4}>
            <Text style={{fontWeight: 'bold'}}>Saved Average: {savedAverage}</Text>
          </Col>
          <Col span={4}>
            <Text style={{fontWeight: 'bold'}}>Realtime Average: {realtimeAverage}</Text>
          </Col>
        </Row> */}

        {/* {!isCanAdd && ( */}
        {/* {false && (
          <Button
            style={{ marginTop: 20 }}
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => {
              props.onChangesAnything(props.competenceCode, props.eventId);
              setList((old) => [
                ...old,
                {
                  key: old.length,
                  userId: props.userId,
                  competenceCode: props.competenceCode,
                  code: props.code,
                  subCode: undefined,
                  eventId: props.eventId,
                  title: '',
                  description: undefined,
                  minScore: undefined,
                  score: undefined,
                  notes: undefined,
                },
              ]);
            }}
          >
            Add New Criteria
          </Button>
        )} */}
      </div>
    </div>
  );
}
