import Cookies from "js-cookie";
import {create} from "zustand";
import { ListLangType } from "../i18n/settings";

type Language = {
  lng: ListLangType;
  setLng: (newLng: ListLangType) => void;
};

const useLanguage = create<Language>((set: any) => ({
  lng: (Cookies.get("i18next") as ListLangType) || "en",
  setLng: (newLng: ListLangType) => {
    Cookies.set("i18next", newLng);
    set({ lng: newLng });
  },
}));

export default useLanguage;
