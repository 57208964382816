import { DetailItem, generateFormRules, getStandardError } from '@qlibs/react-components';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import React, { useState } from 'react'
import { httpRequest } from '../../../helpers/api';

type Props = {
  userId: string;
  code: string; // e.g training
  eventId: 'aws' | 'general_banking' | string;
  subCode: string; // e.g training__offline_exercise_score
  title: string;
  initialValues: { score?: number };

  label?: string;
};

export default function FormInputScore(props: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (values: {score: string}) => {
    setIsLoading(true);
    try {
      const data = {
        userId: props.userId,
        data: [
          {
            code: props.code,
            subCode: props.subCode,
            eventId: props.eventId,
            score: Number(values.score),
            title: props.title,
          },
        ],
      };
      console.info('FormInputScore data', data);
      await httpRequest.post(`evaluation/${props.code}/${props.eventId}`, data);

      message.success('Updated successfully');
      Modal.destroyAll();
      setIsLoading(false);
      window.location.reload();
    } catch(err) {
      getStandardError(err, {showToast: true});
      Modal.destroyAll();
      setIsLoading(false);
    }
  }
  return (
    <div style={{ paddingTop: 10 }}>
      {/* <Row>
        <Col span={8}>
          <DetailItem label="Code" value={props.code} />
        </Col>
        <Col span={16}>
          <DetailItem label="Sub Code" value={props.subCode} />
        </Col>
      </Row> */}
      <Form initialValues={props.initialValues} onFinish={handleSave}>
        <Form.Item
          label={props.label ?? 'Actual Score'}
          name="score"
          required
          rules={[
            ...generateFormRules(props.label ?? 'Actual Score', [
              'required',
              'numeric',
            ]),
            {
              validator(rule, value, callback) {
                if (Number(value > 100)) {
                  callback('Maximum 100');
                } else if (Number(value < 0)) {
                  callback('Minimum 0');
                }
                callback(undefined);
              },
            },
          ]}
        >
          <Input type="number" max={100} min={0} />
        </Form.Item>

        <Row justify="end">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            SUBMIT
          </Button>
        </Row>
      </Form>
    </div>
  );
}