import React from 'react'
import styled from 'styled-components'
import { thisYear } from '@qlibs/react-components'
import { APP_VERSION } from '../const/config';

const AppVersion = () => {
  return (
    <VersionApp>
      v{APP_VERSION} · © {thisYear}. {process.env.REACT_APP_WEBSITE_NAME}
    </VersionApp>
  );
}

const VersionApp = styled.div`
  padding: 10px;
  margin-top: 30px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.smallText};
  color: ${({ theme }) => theme.colors.charcoal400};
`
export default AppVersion