import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Card,
  Spin,
} from "antd";

import { httpRequest } from "../../../helpers/api";
import {
  AppTable,
  getDateTimeRangeString,
  IAppTableColumn,
  NotSet,
  useFetchList,
} from "@qlibs/react-components";

import {
  EQuizType,
  QuizProperties,
} from "../../../types/quiz.type";
import SimpleDetailItem from "../../../components/SimpleDetailItem";
import moment from "moment";
import { EventsProps } from "../../../types/event.type";
import useAdditionalDataForList from "../../../hooks/useAdditionalDataForList";
import { getBasePath } from '../helpers/menuPathGenerator';

interface ILocation {
  quizId: string;
}

const { RangePicker } = DatePicker;

const ByEvent = ({ quiz }: { quiz: QuizProperties | undefined }) => {
  const { quizId } = useParams<keyof ILocation>() as ILocation;

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    query,
    setQuery,
    fetchList,
    handleSearch,
  } = useFetchList<EventsProps>({
    httpRequest: httpRequest as any,
    endpoint: "event",
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
    initialQuery: {
      reportFormId: quizId ?? quiz?.quizId,
      // sort: 'startAt:DESC',
    },
  });

  const { isLoadingAdditionalData, additionalData } = useAdditionalDataForList<{
    [id: string]: {
      quizAttemptSummary: {
        eventId: string;
        totalUserSubmit: number;
      };
    };
  }>({
    id: 'eventId',
    data,
    injects: [
      {
        injectedId: 'eventId',
        endpoint: '/quiz-attempt-by-event?externalDataEventIds=',
        endpointId: 'eventId',
        returnKey: 'quizAttemptSummary',
      },
    ],
  });

  const columns: IAppTableColumn<EventsProps>[] = [
    {
      title: 'EVENT',
      dataIndex: 'title',
      key: 'title',
      type: 'detail',
      keyId: 'eventId',
      showOriginalValue: true,
      render: (title: string, record) => (
        <Link
          className="table-link"
          to={`/${getBasePath()}/${quiz?.quizId}/result/event/${
            record.eventId
          }`}
        >
          {title}
        </Link>
      ),
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'startAt',
      key: 'startAt',
      render: (startAt, record) =>
        getDateTimeRangeString(startAt, record.endAt),
    },
    {
      title: 'TOTAL USER SUBMIT',
      dataIndex: 'totalUserSubmit',
      key: 'totalUserSubmit',
      width: 200,
      render: (value, record) =>
        isLoadingAdditionalData ? (
          <Spin />
        ) : additionalData[record.eventId]?.quizAttemptSummary ? (
          additionalData[record.eventId]?.quizAttemptSummary?.totalUserSubmit
        ) : (
          <NotSet value="No submission" />
        ),
    },
    {
      title: 'ACTION',
      key: 'action',
      type: 'actions',
      actions: [
        {
          key: 'detail',
          label: 'Detail',
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Row
        style={{
          margin: '12px 0px',
        }}
        gutter={[12, 12]}
      >
        <Col span={8}>
          <SimpleDetailItem label="Search">
            <Input
              placeholder="Search by event title"
              onChange={(e) => handleSearch(e.target.value)}
              allowClear
            />
          </SimpleDetailItem>
        </Col>
        {/* {quiz?.quizType !== EQuizType.SURVEY && (
          <Col span={4}>
            <SimpleDetailItem label="Score">
              <Select
                options={[
                  {
                    label: "Highest to Lowest",
                    value: "highest",
                  },
                  {
                    label: "Lowest to Highest",
                    value: "lowest",
                  },
                ]}
                onChange={(e) => {
                  if (e === "highest") {
                    setQuery({
                      ...query,
                      sort: `highest:DESC`,
                    });
                  } else if (e === "lowest") {
                    setQuery({
                      ...query,
                      sort: `lowest:ASC`,
                    });
                  }
                }}
                placeholder="Sort by Score"
                allowClear
              />
            </SimpleDetailItem>
          </Col>
        )}

        <Col span={6}>
          <SimpleDetailItem label="Start At">
            <RangePicker
              onChange={(date, dateString) => {
                if (dateString[0] && dateString[1]) {
                  let filterStartAt = moment(dateString[0])
                    .startOf("day")
                    .toISOString();
                  let filterEndAt = moment(dateString[1])
                    .endOf("day")
                    .toISOString();

                  setQuery({
                    ...query,
                    startAt: filterStartAt,
                    startAtEndAt: filterEndAt,
                  });
                }
              }}
              allowClear
            />
          </SimpleDetailItem>
        </Col>
        <Col span={6}>
          <SimpleDetailItem label="Finished At">
            <RangePicker
              onChange={(date, dateString) => {
                if (dateString[0] && dateString[1]) {
                  let filterStartAt = moment(dateString[0])
                    .startOf("day")
                    .toISOString();
                  let filterEndAt = moment(dateString[1])
                    .endOf("day")
                    .toISOString();

                  setQuery({
                    ...query,
                    finishedStartAt: filterStartAt,
                    finishedEndAt: filterEndAt,
                  });
                }
              }}
              allowClear
            />
          </SimpleDetailItem>
        </Col> */}
      </Row>

      <Card bordered={false} size="small" loading={isLoading}>
        <AppTable
          isLoading={isLoading}
          keyId="eventId"
          columns={columns.filter((v) => v.key)}
          data={data}
          pagination={pagination}
          onChangeLimit={changeLimit}
          onChangePage={changePage}
        />
      </Card>
    </React.Fragment>
  );
};

export default ByEvent;
