import { getStandardError } from '@qlibs/react-components';
import { Empty, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { httpRequest } from '../../../helpers/api';
import Result from '../../quiz/Result'
import DetailResultByTalent from '../../quiz/Result/DetailResultByTalent';

export default function WSCCTestResultByEvent({eventId, talentId, title, onClose, onCancel}: {eventId: string; talentId: string; title: string; onClose: () => void; onCancel: () => void}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quizId, setQuizId] = useState<string>();

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    if (!eventId) {
      return;
    }
    setIsOpen(true);
    setIsLoading(true);
    try {
      const res = await httpRequest.get('event/' + eventId);

      if (res?.data?.payload) {
        setQuizId(res?.data?.payload.externalId);
      }
      setIsLoading(false);
    } catch (err) {
      getStandardError(err, { showToast: true });
      setIsLoading(false);
    }
  }

  return (
    <Modal
      open={isOpen}
      loading={isLoading}
      closable
      width={900}
      title={title}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onCancel={() => {
        setIsOpen(false);
        onCancel();
      }}
      footer={false}
    >
      {!isLoading && (!eventId || !quizId || !talentId) ? <Empty /> : false}
      {!isLoading && quizId && talentId ? (
        <DetailResultByTalent quizId={quizId} userId={talentId} detailButtonMode="modal" hideHeader />
      ) : (
        false
      )}
    </Modal>
  );
}