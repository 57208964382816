export const MAPPING_EVENTS_CHANGELOGS_ACTION: {[key: string]: {label: string; tagProps: {color: string}}} = {
  DELETE: {
    label: 'Delete',
    tagProps: {
      color: 'red',
    },
  },
  CREATE: {
    label: 'Create',
    tagProps: {
      color: 'green',
    },
  },
  UPDATE: {
    label: 'Update',
    tagProps: {
      color: 'orange',
    },
  },
  
};