import React from "react";
import { Typography } from "antd";

type Props = {
  label: string;
  children?: React.ReactNode;
};
const { Text } = Typography;
const SimpleDetailItem: React.FC<Props> = ({ label, children }) => {
  return (
    <div style={{ margin: "10px 0px" }}>
      <Text
        style={{
          display: "block",
          marginBottom: 2,
          fontSize: 14,
          fontWeight: 400,
          color: "#768499",
        }}
      >
        {label}
      </Text>

      {children}
    </div>
  );
};
export default SimpleDetailItem;
