import { getStandardError } from "@qlibs/react-components";
import { useEffect, useState } from "react";
import { httpRequest } from "../helpers/api";

type Props = {
  ignore?: boolean;
  id: string;
  data: any[];

  injects: {
    injectedId: string | string[]; // e.g userId or courseId
    endpoint: string; // e.g /users?filterStatus=active&roles=talent&userIds= or /event?externalIds=
    endpointId: string; // e.g userId or externalId
    returnKey: string; // e.g user or events
    multiple?: boolean;
  }[];
};
export default function useAdditionalDataForList<IAdditionalData>(
  props: Props
) {
  const [isLoadingAdditionalData, setIsLoadingAdditionalData] = useState(false);
  const [additionalData, setAdditionalData] = useState<IAdditionalData>(
    {} as any
  );

  useEffect(() => {
    if (!props.ignore) {
      fetchAdditionalData();
    }
  }, [props.data]);

  const fetchAdditionalData = async () => {
    if (props.data.length === 0 || props.injects.length === 0) {
      return;
    }

    setIsLoadingAdditionalData(true);
    try {
      const ids: { [key: string]: string[] } = {};
      for (const inject of props?.injects) {
        if (Array.isArray(inject?.injectedId)) {
          ids[inject?.injectedId?.join(".")] = props.data
            ?.filter(
              (item) => item[inject?.injectedId?.[0]]?.[inject?.injectedId?.[1]]
            )
            ?.map(
              (item) => item[inject?.injectedId?.[0]]?.[inject?.injectedId?.[1]]
            ); // courseId1,2,3
        } else {
          ids[inject?.injectedId] = props.data
            ?.filter((item) => item?.[inject?.injectedId as string])
            ?.map((item) => item?.[inject?.injectedId as string]); // courseId1,2,3
        }
      }

      const results = await Promise.all(
        props.injects.map((inject) =>
          httpRequest.get(
            `${inject.endpoint}${(Array.isArray(inject?.injectedId)
              ? ids[inject?.injectedId?.join(".")]
              : ids[inject?.injectedId]
            ).join(",")}`
          )
        )
      );

      const objData: any = {};
      for (const item of props.data) {
        for (const idx in results) {
          const result = results?.[idx];
          let res: any;
          if (props.injects?.[idx]?.multiple) {
            const list =
              result?.data?.payload?.results || result?.data?.payload || [];
            res = list?.filter?.((u: any) => {
              if (Array.isArray(props.injects?.[idx]?.injectedId)) {
                return (
                  u?.[props.injects?.[idx]?.endpointId] ===
                  item?.[props.injects?.[idx]?.injectedId[0]][
                    props.injects?.[idx]?.injectedId?.[1]
                  ]
                );
              } else {
                return (
                  u?.[props.injects?.[idx]?.endpointId] ===
                  item?.[props.injects?.[idx]?.injectedId as string]
                );
              }
            });
          } else {
            const list =
              result?.data?.payload?.results || result?.data?.payload || [];
            res = (list ?? [])?.find?.((u: any) => {
              if (Array.isArray(props?.injects?.[idx]?.injectedId)) {
                return (
                  u?.[props?.injects?.[idx]?.endpointId] ===
                  item?.[props?.injects?.[idx]?.injectedId?.[0]]?.[
                    props?.injects?.[idx]?.injectedId?.[1]
                  ]
                );
              } else {
                return (
                  u?.[props?.injects?.[idx]?.endpointId] ===
                  item?.[props?.injects?.[idx]?.injectedId as string]
                );
              }
            });
          }

          if (!objData?.[item[props?.id]]) {
            objData[item[props.id]] = {};
          }

          objData[item[props.id]][props.injects[idx].returnKey] = res;
        }
      }

      console.info("objData", objData);

      setAdditionalData(objData);
      setIsLoadingAdditionalData(false);
    } catch (error) {
      getStandardError(error, { showToast: true });
      console.error("Failed to fetch additional data", error);
      setIsLoadingAdditionalData(false);
    }
  };

  return {
    isLoadingAdditionalData,
    additionalData,
    fetchAdditionalData,
  };
}
