import { CalendarOutlined } from '@ant-design/icons';
import { getDateRangeString } from '@qlibs/react-components';
import { Button, Card, Col, DatePicker, Divider, Form, message, Modal, Radio, Row } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react'
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

type Props = {
  startAt?: string | Date;
  endAt?: string | Date;
  enableStartAt?: string | Date;
  enableEndAt?: string | Date;
  onChange: (value: { startAt: string; endAt: string }) => void;
};
export default function FilterDate(props: Props) {
  const [isShow, setIsShow] = useState(false);
  const filters = getDateRanges();

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    if (props.enableStartAt && props.enableEndAt) {
      return (
        new Date(props.enableStartAt).getTime() > new Date(current).getTime() &&
        new Date(props.enableEndAt).getTime() < new Date(current).getTime()
      );
    } else {
      return true;
    }
  };

  const handleChange = (values: {startAt: Dayjs, endAt: Dayjs}) => {
    const startAt = values.startAt.toISOString();
    const endAt = values.endAt.toISOString();
    if (props.enableStartAt && props.enableEndAt) {
      if (new Date(props.enableStartAt).getTime() > new Date(startAt).getTime()
      ) {
        message.error(`"From" must be in range (${getDateRangeString(props.enableStartAt, props.enableEndAt)}) `);
        return;
      } else if (
        new Date(props.enableEndAt).getTime() <
          new Date(endAt).getTime()
      ) {
        message.error(
          `"Until" must be in range (${getDateRangeString(
            props.enableStartAt,
            props.enableEndAt
          )}) `
        );
        return;
      }
    }

    props.onChange({startAt, endAt});
    setIsShow(false);
  }

  return (
    <React.Fragment>
      {props.startAt && props.endAt ? (
        <Button
          type="default"
          icon={<CalendarOutlined />}
          onClick={() => {
            setIsShow(true);
          }}
        >
          {getDateRangeString(props.startAt, props.endAt)}
        </Button>
      ) : (
        <Button
          type="default"
          icon={<CalendarOutlined />}
          onClick={() => {
            setIsShow(true);
          }}
        >
          Select Date
        </Button>
      )}

      <Modal
        title="Change Filter Date"
        open={isShow}
        onClose={() => setIsShow(false)}
        onCancel={() => setIsShow(false)}
        footer={false}
        closable
      >
        <div>
          <div style={{ marginTop: 10 }}>Choose one</div>
          <Card>
            <Row>
              {filters.map((filter) => (
                <Button
                  key={filter.label}
                  style={{ marginRight: 10 }}
                  type="default"
                  onClick={() => {
                    props.onChange({startAt: filter.startAt, endAt: filter.endAt});
                    setIsShow(false);
                  }}
                >
                  {filter.label}
                </Button>
              ))}
            </Row>
          </Card>

          <div style={{ marginTop: 10 }}>or select custom dates</div>

          <Card>
            <Form
              style={{ marginTop: 10 }}
              initialValues={{
                startAt: dayjs(props.startAt),
                endAt: dayjs(props.endAt),
              }}
              onFinish={(values) => {
                handleChange(values);
              }}
              layout="inline"
            >
              <Form.Item name="startAt" label="From">
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item name="endAt" label="Until">
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>

              <Row style={{ marginTop: 20 }}>
                <Button type="primary" htmlType="submit">
                  UPDATE FILTER
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
      </Modal>
    </React.Fragment>
  );
}

const getDateRanges = (): {
  label: string;
  startAt: string;
  endAt: string;
}[] => {
  // Today
  const todayStartAt = dayjs().startOf('day').toISOString();
  const todayEndAt = dayjs().endOf('day').toISOString();

  // This Week
  const weekStartAt = dayjs().startOf('week').toISOString();
  const weekEndAt = dayjs().endOf('week').toISOString();

  // This Month
  const monthStartAt = dayjs().startOf('month').toISOString();
  const monthEndAt = dayjs().endOf('month').toISOString();

  // Mengembalikan array dengan label dan range tanggal
  return [
    // {
    //   label: 'Today',
    //   startAt: todayStartAt,
    //   endAt: todayEndAt,
    // },
    {
      label: 'This Week',
      startAt: weekStartAt,
      endAt: weekEndAt,
    },
    {
      label: 'This Month',
      startAt: monthStartAt,
      endAt: monthEndAt,
    },
  ];
};