import { Button, Col, Modal, Row, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { getDateString, NotSet } from '@qlibs/react-components';
import { EventsProps } from '../../../types/event.type';
import { QuizAttemptProperties } from '../../../types/quiz.type';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { forwardRef, useImperativeHandle, useState } from 'react';
import React from 'react';
import DetailResultByTalent from '../Result/DetailResultByTalent';
import ExcelJS from 'exceljs';
import DetailResultQuiz from '../Result/DetailResultByHistory';
import { stripHtml } from "string-strip-html";


const MAPPING_COLOR: { [key: string]: string } = {
  'SUBMIT': 'green',
  'NOT SUBMIT': 'red'
}
// Generate tanggal dari 1 September 2024 hingga 6 Februari 2025
export const generateDates = (start: string, end: string) => {
  const startDate = moment(start);
  const endDate = moment(end);
  const dates = [];
  while (startDate.isSameOrBefore(endDate)) {
    dates.push(startDate.format('YYYY-MM-DD'));
    startDate.add(1, 'days');
  }
  return dates;
};

// Tanggal rentang yang akan digunakan

// Contoh data absensi
// const data = [
//   {
//     key: '1',
//     name: 'John Doe',
//     dailyAchievement: {
//       '2024-09-01': 'Hadir',
//       '2024-09-02': 'Sakit',
//       '2024-09-03': 'Tidak Hadir',
//       // Lanjutkan data hingga Februari 2025
//     },
//   },
//   {
//     key: '2',
//     name: 'Jane Doe',
//     dailyAchievement: {
//       '2024-09-01': 'Sakit',
//       '2024-09-02': 'Hadir',
//       '2024-09-03': 'Tidak Hadir',
//       // Lanjutkan data hingga Februari 2025
//     },
//   },
// ];

export type IDailyAchievementData = {
  key: string; // fill with userId
  userId: string;
  email: string;
  name: string; // fill with talent name
  dailyAchievement: {
    [date: string]: QuizAttemptProperties & { notSubmit?: boolean };
  };
  summary: {
    [status: string]: number;
  };
};

export type IEventData = {
  [date: string]: EventsProps[];
};

type Props = {
  isLoading: boolean;
  data: IDailyAchievementData[];
  event: IEventData;
  dateStartAt: string;
  dateEndAt: string;
}
const ColumnDateTable = forwardRef((props: Props, ref) => {
  const [showDetailAttempt, setShowDetailAttempt] = useState<{
    quizId: string;
    attemptId: string;
    talentId: string;
    talentName: string;
    startAt: string | Date | undefined;
  }>();
  const dates = generateDates(props.dateStartAt, props.dateEndAt);

  const handleDownloadExcelTemplate = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Summary');
    const worksheetRawAttempt = workbook.addWorksheet('Raw Attempt')
    const worksheetRawAttemptDetail = workbook.addWorksheet('Raw Attempt Detail')


    worksheetRawAttempt.columns = [
      { header: 'NAME', key: 'name', width: 25 },
      { header: 'EMAIL', key: 'email', width: 30 },
      { header: 'DATE', key: 'date', width: 25 },
      { header: 'TOTAL ATTEMPT', key: 'totalAttempt', width: 25 },
      { header: 'SCORE', key: 'score', width: 25 },
      { header: 'START AT', key: 'startAt', width: 25 },
      { header: 'FINISHED AT', key: 'finishedAt', width: 25 },
      { header: 'NOTE FROM MENTOR', key: 'notesFromMentor', width: 25 }
    ]

    worksheetRawAttemptDetail.columns = [
      { header: 'NAME', key: 'name', width: 25 },
      { header: 'EMAIL', key: 'email', width: 30 },
      { header: 'STATUS', key: 'status', width: 25 },
      { header: 'QUIZ TITLE', key: 'quizTitle', width: 25 },
      { header: 'DATE', key: 'date', width: 25 },
      { header: 'QUESTION', key: 'question', width: 25 },
      { header: 'ANSWER', key: 'answer', width: 25 },
    ]

    worksheet.columns = [
      { header: '', key: 'name', width: 25 },
      { header: 'SUBMIT', key: 'submit', width: 10, style: { alignment: { wrapText: true } } },
      { header: 'NOT SUBMIT', key: 'notSubmit', width: 10, style: { alignment: { wrapText: true } } },
      ...dates.map(date => ({
        header: date,
        key: date,
        width: 15,
        style: { alignment: { wrapText: true } }
      })),
    ];
    worksheet.mergeCells('A1:A2');
    worksheet.getCell('A1').value = ''

    worksheet.mergeCells('B1:C1');
    worksheet.getCell('B1').value = 'Summary';
    worksheet.getRow(2).values = [
      'Talent Name',
      'SUBMIT',
      'NOT SUBMIT',
      ...dates.map(date => props.event[date]?.length > 0
        ? `${props.event[date]?.length} event(s)`
        : '-'),
    ];

    [worksheet.getRow(1), worksheet.getRow(2)].forEach(row => {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: '000000' },
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
      });
    });

    [worksheetRawAttempt.getRow(1)].forEach(row => {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: '000000' },
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
      });
    });

    [worksheetRawAttemptDetail.getRow(1)].forEach(row => {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: '000000' },
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
      });
    });


    const getStatusColor = (status: any) => {
      switch (status) {
        case 'SUBMIT':
          return '52c41a';;
        case 'NOT SUBMIT':
          return 'ff4d4f'
        default:
          return '000000';
      }
    };

    const getStatusColorCheckList = (status: any) => {
      switch (status) {
        case '✔':
          return '52c41a';;
        case '✘':
          return 'ff4d4f'
        default:
          return '000000';
      }
    };

    ['SUBMIT', 'NOT SUBMIT'].forEach((status, index) => {
      const cell = worksheet.getCell(2, index + 2);
      const color = getStatusColor(status);
      cell.font = { color: { argb: color } };
    });

    props.data.forEach((record) => {

      const row = {
        name: record.name,
        submit: record.summary.SUBMIT || '0',
        notSubmit: record.summary['NOT SUBMIT'],
        ...dates.reduce((acc: any, date) => {
          acc[date] = record.dailyAchievement[date] ? record.dailyAchievement[date]?.notSubmit
            ? '✘' // Ganti dengan simbol silang
            : record.dailyAchievement[date]?.isFinishedBySystem ? '✔\nFinished By System' : '✔' 
            : 'NOT SET';
          return acc;
        }, {}),
      };



      Object.entries(record.dailyAchievement).map(([date, dailyAchievement]) => {
        const rowRaw = {
          name: record.name,
          email: record.email,
          date: date,
          totalAttempt: dailyAchievement.attempt ?? 'Not Set',
          score: dailyAchievement.score ?? 'Not Set',
          startAt: dailyAchievement.startAt ? moment(dailyAchievement.startAt).format('DD MMM YYYY HH:mm') : 'Not Set',
          finishedAt: dailyAchievement.finishedAt ? moment(dailyAchievement.finishedAt).format('DD MMM YYYY HH:mm') : 'Not Set',
          notesFromMentor: dailyAchievement.notesFromMentor ?? 'Not Set'
        }
        const newRowRaw = worksheetRawAttempt.addRow(rowRaw);

        dailyAchievement.attemptDetails ? dailyAchievement.attemptDetails.map((x) => {
          const rowRawDetails = {
            name: record.name,
            email: record.email,
            status: 'SUBMIT',
            quizTitle: dailyAchievement.quiz ? dailyAchievement.quiz.title : 'Not Set',
            date: date,
            question: x.metaQuestions ? stripHtml(x.metaQuestions.questionText).result : 'Not Set',
            answer: x.answerText ? stripHtml(x.answerText).result : 
            (x.choosenAnswerIds && x.choosenAnswerIds.length > 0
              ? x.metaQuestionAnswers
                  .filter((y: any) => x.choosenAnswerIds.includes(y.answerId))
                  .map((y: any) => y.answer)
                  .join('\n')
              : 'Not Set')
    
          }

          const newRowRawDetail = worksheetRawAttemptDetail.addRow(rowRawDetails);

        }) : (() => {
          const rowRawDetails = {
            name: record.name,
            email: record.email,
            status: 'NOT SUBMIT',
            quizTitle: "Not Set",
            date: date,
            question: "Not Set",
            answer: "Not Set",
          };
          worksheetRawAttemptDetail.addRow(rowRawDetails);
        })();
      })


      const newRow = worksheet.addRow(row);

      dates.forEach((date, index) => {
        const cell = newRow.getCell(index + 3);
        if (cell.value?.toString().includes('✔')) {
          cell.font = { color: { argb: '52c41a' } };
        } else if (cell.value === '✘') {
          cell.font = { color: { argb: 'ff4d4f' } };
        }
      });

      ['submit', 'not submit'].forEach((key, index) => {
        const status = key.toUpperCase();
        const color = getStatusColor(status);

        const cell = newRow.getCell(index + 2);
        cell.font = {
          color: { argb: color },
        };
      });
    });

    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      });
    });


    worksheetRawAttempt.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      });
    });

    const excelFile = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const getFormattedDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}`;

    }

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `Daily Achievement by Talent (${props.dateStartAt} ~ ${props.dateEndAt}) - downloaded at ${getFormattedDate()}.xlsx`;

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  };

  useImperativeHandle(ref, () => ({
    handleDownloadExcelTemplate,
  }));

  // Kolom dinamis berdasarkan tanggal yang digenerate
  const columns: TableColumnsType<IDailyAchievementData> = [
    {
      title: 'Talent Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left', // Kolom ini di-freeze agar selalu terlihat
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      fixed: 'left', // Kolom ini di-freeze agar selalu terlihat
      render: (summary) => (
        <div>
          {Object.keys(summary).map((status) => (
            <Row key={status} justify="space-between" gutter={6}>
              <Col
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  color: MAPPING_COLOR[status],
                }}
              >
                {status}
              </Col>
              <Col
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  color: MAPPING_COLOR[status],
                }}
              >
                {summary[status]}
              </Col>
            </Row>
          ))}
        </div>
      ),
    },
    ...dates.map((date) => ({
      title: (
        <div>
          {props.event[date]?.length > 0 ? (
            <div>
              <div style={{ fontSize: 11 }}>{moment(date).format('dddd')}</div>
              <div style={{ color: 'green' }}>
                {getDateString(date, 'short', 'short')}{' '}
              </div>
              <div style={{ fontSize: 11, color: 'darkgrey' }}>
                {props.event[date]?.length + ' event(s)'}
              </div>
            </div>
          ) : (
            <div style={{ color: 'red' }}>
              <div style={{ fontSize: 11 }}>{moment(date).format('dddd')}</div>
              <div>{getDateString(date, 'short', 'short')} </div>
              <div style={{ fontSize: 11 }}>-</div>
            </div>
          )}
        </div>
      ),
      dataIndex: ['dailyAchievement', date],
      key: date,
      render: (dailyAchievement: QuizAttemptProperties & { notSubmit?: boolean }, record: IDailyAchievementData) => {
        if (record.key === 'event') {
          return;
        } else {
          if (dailyAchievement?.notSubmit) {
            return (
              <Tag color="red">
                <CloseCircleOutlined />
              </Tag>
            );
          } else if (dailyAchievement) {
            return (
              <div>
                <Tooltip title="Click to show the submission">
                  <Tag
                    color="green-inverse"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowDetailAttempt({
                        quizId: dailyAchievement.quizId,
                        talentId: dailyAchievement.userId,
                        attemptId: dailyAchievement.id,
                        talentName: dailyAchievement.user?.name,
                        startAt: dailyAchievement.startAt,
                      });
                    }}
                  >
                    <CheckCircleOutlined />
                  </Tag>
                </Tooltip>

                <div style={{ fontSize: 11, color: 'darkgrey' }}>
                  {moment(dailyAchievement.startAt).format('HH:mm')} -{' '}
                  {moment(dailyAchievement.finishedAt).format('HH:mm')}
                </div>

                {dailyAchievement.isFinishedBySystem && (
                  <Tag style={{ fontSize: 10 }} color="orange">
                    Finished by system
                  </Tag>
                )}
              </div>
            );
          } else {
            return (
              <NotSet value="No event" />
            );
          }
        }
      },
    })),
  ];

  return (
    <React.Fragment>

      <div style={{ display: 'flex', marginBottom: 16 }}>
      </div>
      <Table
        loading={props.isLoading}
        columns={columns}
        dataSource={props.data}
        pagination={false}
        scroll={{ x: 'max-content' }} // Untuk memungkinkan horizontal scroll jika tabel terlalu lebar
      />

      {showDetailAttempt ? (
        <Modal
          title={`Daily Achievement - ${showDetailAttempt?.talentName
            } - ${getDateString(showDetailAttempt?.startAt, 'short', 'short')}`}
          open={showDetailAttempt ? true : false}
          width={900}
          onCancel={() => setShowDetailAttempt(undefined)}
          onClose={() => setShowDetailAttempt(undefined)}
          closable
        >
          <DetailResultQuiz
            quizId={showDetailAttempt?.quizId}
            quizAttemptId={showDetailAttempt?.attemptId}
            hideHeader
            onSuccessChangeNotes={() => {
              //
            }}
          />
        </Modal>
      ) : (
        false
      )}
    </React.Fragment>
  );
});

export default ColumnDateTable;
