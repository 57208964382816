import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AppTable,
  DetailItem,
  getStandardError,
  HeaderSection,
  IAppTableColumn,
  useFetchList,
} from "@qlibs/react-components";
import { httpRequest } from "../../../helpers/api";
import useDetailBreadcrumbs from "../../../hooks/useDetailBreadcrumbs";
import { Button, Card, Col, Empty, List, Modal, Row, Space, Spin } from "antd";
import {
  AnswerProperties,
  QuestionProperties,
  QuizProperties,
} from "../../../types/quiz.type";
import { EventsProps } from "../../../types/event.type";
import { CourseProps } from "../../../types/course.type";
import ExcelJS from 'exceljs';
import { stripHtml } from "string-strip-html";


type ByQuestion = {
  questionId: string;
  metaQuestions: any;
  question: QuestionProperties;

  answers: {
    userId: string;
    userName: string;
    answerText: string;
    choosenAnswerIds: string[];
    metaQuestionAnswers: AnswerProperties[];

    answerAsNumber?: number;
  }[];

  averageAnswerAsNumber?: number;
};

interface ILocation {
  quizId: string;
  eventId: string;
}

type Props = {
  quizId?: string;
  eventId?: string;
  hideHeader?: boolean;
  detailButtonMode?: "modal";
};
export default function DetailResultByEvent({
  quizId: quizIdFromProps,
  eventId: eventIdFromProps,
  ...props
}: Props) {
  const { quizId: quizIdFromParams, eventId: eventIdFromParams } = useParams<
    keyof ILocation
  >() as ILocation;

  const quizId = quizIdFromProps || quizIdFromParams;
  const eventId = eventIdFromProps || eventIdFromParams;

  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const { isLoading, data } = useFetchList<ByQuestion>({
    httpRequest: httpRequest as any,
    endpoint: `quiz-attempt-by-event/${quizId}/${eventId}`,
  });

  const [isLoadingQuiz, setIsLoadingQuiz] = useState(true);
  const [isLoadingEvent, setIsLoadingEvent] = useState(true);
  const [isLoadingCourse, setIsLoadingCourse] = useState(true);
  const [isLoadingAverage, setIsLoadingAverage] = useState(true);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const [quiz, setQuiz] = useState<QuizProperties>();
  const [event, setEvent] = useState<EventsProps>();
  const [course, setCourse] = useState<CourseProps>();
  const [average, setAverage] = useState<any>(0);

  useEffect(() => {
    fetchQuiz();
    fetchEvent();
    fetchAverage();
  }, []);

  useEffect(() => {
    const bcDetails = [];
    if (event?.eventId) {
      bcDetails.push({
        field: "eventId",
        value: event?.eventId,
        label: event?.title,
      });
    }
    if (quiz?.quizId) {
      bcDetails.push({
        field: "quizId",
        value: quiz?.quizId,
        label: quiz?.title,
      });
    }
    setBreadcrumbDetails(bcDetails);
  }, [quiz, event]);

  const fetchQuiz = async () => {
    httpRequest
      .get("/quizzes/" + quizId)
      .then((res) => {
        if (res.data.payload) {
          setQuiz(res.data.payload);

          const bcDetails = [
            {
              field: "quizId",
              value: res.data.payload.quizId,
              label: res.data.payload.title,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        }
        setIsLoadingQuiz(false);
      })
      .catch((err) => {
        setIsLoadingQuiz(false);
        getStandardError(err, { showToast: true });
      });
  };

  const fetchAverage = async () => {
    httpRequest
      .get(`quiz-attempt-by-event/${quizId}/${eventId}/average`)
      .then((res) => {
        if (res.data.payload) {
          setAverage(res.data.payload.average ?? 0);
        }
        setIsLoadingAverage(false);
      })
      .catch((err) => {
        setIsLoadingAverage(false);
        getStandardError(err, { showToast: true });
      });
  };

  const exportExcel = async () => {
    setIsLoadingAction(true);
    try {
      // const event = await this.eventService.findOne(eventId)
      // const quiz = await this.quizService.findById(quizId)
      // const course = await this.courseService.findOne(event.externalId)
      // const data = await this.quizAttemptByEventService.getSummaryByEvent(quizId, eventId);

      const workbook = new ExcelJS.Workbook();
      const summary = workbook.addWorksheet('Summary', { pageSetup: { paperSize: 9, orientation: 'landscape' } });
      const raw = workbook.addWorksheet('Raw', { pageSetup: { paperSize: 9, orientation: 'landscape' } });

      const value = data.filter(item => item.hasOwnProperty('averageAnswerAsNumber'));
      const total = value.reduce((sum, item) => sum + (item.averageAnswerAsNumber ?? 0), 0);
      const average = value.length > 0 ? total / value.length : 0;

      const colsvalue = [
        ['Form Survey', quiz?.title ?? ''],
        ['Event', event?.title ?? ''],
        ['Trainer', course?.trainer ?? ''],
        ['Average', average]
      ];
      const colsSummary = [
        { key: 'questionId', width: 40, name: 'Question Id' },
        { key: 'question.questionText', width: 40, name: 'Question Text (Html)' },
        { key: 'question.questionTextHtml', width: 80, name: 'Question Text (Plain)' },
        { key: 'question.questionType', width: 50, name: 'Question Type' },
        { key: 'averageAnswerAsNumber', width: 10, name: 'Average' },
        { key: 'answers', width: 10, name: 'Details' },
      ];

      const colsRaw = [
        { key: 'questionId', width: 40, name: 'Question Id' },
        { key: 'question.questionText', width: 40, name: 'Question Text (Html)' },
        { key: 'question.questionTextHtml', width: 80, name: 'Question Text (Plain)' },
        { key: 'question.questionType', width: 50, name: 'Question Type' },
        { key: 'user.userId', width: 60, name: 'Talent ID' },
        { key: 'user.name', width: 50, name: 'Talent Name' },
        { key: 'answers.answerText', width: 50, name: 'Answer' }
      ];

      const mapRowData = (col: any, dataItem: any) => {
        if (col.key === 'answers') {
          return dataItem.answers
            .filter((answer: any) => answer.answerText)
            .map((answer: any) => `• ${answer.answerText}`)
            .join('\n');
        }
        if (col.key.includes('Html')) {
          let keys = col.key.replace(/Html$/, "");
          return stripHtml(keys.split('.').reduce((obj: any, key: any) => obj?.[key], dataItem)).result
        }
        return col.key.split('.').reduce((obj: any, key: any) => obj?.[key], dataItem);
      };

      const fillRows = (cols: any, data: any) => data.map((item: any) => cols.map((col: any) => mapRowData(col, item)));

      const rowsSummary = fillRows(colsSummary, data);
      const rowsRaw = data.flatMap(item =>
        item.answers.map(x => [
          item.questionId,
          item.question.questionText,
          stripHtml(item.question.questionText).result,
          item.question.questionType,
          x.userId,
          x.userName,
          x.answerText || x.answerAsNumber
        ])
      );

      const applyStylesAndWidths = (worksheet: any, cols: any, rows: any) => {
        rows.forEach((_: any, rowIdx: any) => {
          cols.forEach((_: any, colIdx: any) => {
            const cell = worksheet.getCell(rowIdx + 2, colIdx + 1);
            cell.alignment = { wrapText: true, horizontal: 'left', innerHeight: 20, };
          });
        });

        cols.forEach((col: any, idx: any) => worksheet.getColumn(idx + 1).width = col.width);
      };


      const startRowSummaryTable = colsvalue.length + 3;

      applyStylesAndWidths(raw, colsRaw, rowsRaw);
      applyStylesAndWidths(summary, colsSummary, rowsSummary);

      summary.addTable({
        name: 'SummaryTable',
        ref: `A${startRowSummaryTable}`,
        headerRow: true,
        columns: colsSummary,
        rows: rowsSummary
      });

      colsvalue.forEach((value, idx) => {
        summary.getCell(`A${idx + 1}`).value = value[0];
        summary.getCell(`B${idx + 1}`).value = value[1];
      });
      raw.addTable({
        name: 'RawTable',
        ref: 'A1',
        headerRow: true,
        columns: colsRaw,
        rows: rowsRaw
      });

      const excelFile = await workbook.xlsx.writeBuffer();
      const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const getFormattedDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${year}${month}${day}${hours}${minutes}`;

      }

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${quiz?.title} - ${event?.title} - ${course?.trainer ?? 'Not set'}.xlsx`;

      document.body.appendChild(anchor);
      anchor.click();

      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);

      setIsLoadingAction(false);
    } catch (error) {
      setIsLoadingAction(false);
      getStandardError(error, { showToast: true });
    }
  };

  const fetchEvent = async () => {
    httpRequest
      .get("/event/" + eventId)
      .then((res) => {
        if (res.data.payload) {
          setEvent(res.data.payload);
          fetchCourse(res.data.payload.externalId);
        }
        setIsLoadingEvent(false);
      })
      .catch((err) => {
        setIsLoadingEvent(false);
        getStandardError(err, { showToast: true });
      });
  };

  const fetchCourse = async (courseId: string) => {
    httpRequest
      .get("/course/" + courseId)
      .then((res) => {
        if (res.data.payload) {
          setCourse(res.data.payload);
        }
        setIsLoadingCourse(false);
      })
      .catch((err) => {
        setIsLoadingCourse(false);
        getStandardError(err, { showToast: true });
      });
  };

  const columns: IAppTableColumn<ByQuestion>[] = [
    {
      title: "Question Id",
      dataIndex: "questionId",
      key: "questionId",
      width: 250,
    },
    {
      title: "Question Text",
      dataIndex: ["question", "questionText"],
      key: "questionText",
      width: 400,
      type: "html-modal",
    },
    {
      title: "Average",
      dataIndex: "averageAnswerAsNumber",
      key: "averageAnswerAsNumber",
      render: (averageAnswerAsNumber, record) =>
        averageAnswerAsNumber ? Number(averageAnswerAsNumber).toFixed(2) : "",
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      render: (_: any, record) =>
        !record.averageAnswerAsNumber &&
          record.answers.find((ans) => ans.answerText) ? (
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                title: "Detail",
                content: (
                  <ul>
                    {record.answers.map((ans) => (
                      <li>{ans.answerText}</li>
                    ))}
                  </ul>
                ),
                closable: true,
                footer: false,
              });
            }}
          >
            Detail
          </Button>
        ) : (
          false
        ),
    },
  ];

  return (
    <React.Fragment>
      {!props.hideHeader && (
        <HeaderSection
          icon="back"
          title="SUBMISSIONS By Event"
          rightAction={
            <Space>
              <Button
                style={{ padding: "0px 32px" }}
                loading={isLoadingAction}
                type="primary"
                onClick={() => exportExcel()}
              >
                Export Excel
              </Button>
            </Space>
          }
        />
      )}

      <Card style={{ marginBottom: 10 }}>
        <Row>
          <Col span={24}>
            {isLoadingQuiz ? (
              <div style={{ marginBottom: 40 }}>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Form" value={quiz?.title} />
            )}
          </Col>
          <Col span={12}>
            {isLoadingEvent ? (
              <div>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Event" value={event?.title} />
            )}
          </Col>
          <Col span={12}>
            {isLoadingCourse ? (
              <div>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Trainer" value={course?.trainer} />
            )}
          </Col>
          <Col span={12}>
            {isLoadingAverage ? (
              <div>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Average" value={average} />
            )}
          </Col>
        </Row>
      </Card>

      <Spin spinning={isLoading || isLoading}>
        {!isLoading && !eventId ? (
          <Empty description="Submissions is still empty for now" />
        ) : (
          <Card bordered={false}>
            {!props.hideHeader ? (
              <AppTable
                isLoading={isLoading}
                keyId="id"
                columns={columns.filter((v) => v.key)}
                data={data.sort((a, b) => a.question.order - b.question.order)}
                pagination={false}
              // onChangeLimit={changeLimit}
              // onChangePage={changePage}
              />
            ) : props.hideHeader ? (
              <AppTable
                isLoading={isLoading}
                keyId="id"
                columns={columns.filter((v) => v.key)}
                data={data.sort((a, b) => a.question.order - b.question.order)}
                pagination={false}
              // onChangeLimit={changeLimit}
              // onChangePage={changePage}
              />
            ) : (
              false
            )}
          </Card>
        )}
      </Spin>
    </React.Fragment>
  );
}
