import { EditOutlined, InfoCircleFilled } from '@ant-design/icons';
import { generateFormRules, getStandardError } from '@qlibs/react-components';
import { Alert, Button, Col, Form, Input, message, Modal, Row, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react'
import { httpRequest } from '../../../helpers/api';
import { ICompetenceItemLevel2, ICompetenceItemLevel3 } from '../types/competence.type';
import EvaluationChangeLogs from './EvaluationChangeLogs';

type Props = {
  userId: string;
  code: string; // e.g training
  eventId: string;
  items: (ICompetenceItemLevel2 | ICompetenceItemLevel3)[]; // e.g training__offline_exercise_score
  title: string;
  initialValues: { finalNotes?: string };

  label?: string;

  onClickInputScore?: () => void;
};

export default function FormInputFinalNotes(props: Props) {
  console.info('props.items', props.items);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const hasLevel3 = props.items.find(
    (item) => ((item as ICompetenceItemLevel2).items_level3 || []).length > 0
  );
  const hasScore = !props.items.find((item) => Number(item.originalScore || 0) === 0);

  const handleSave = async (values: {finalNotes: string}) => {
    setIsLoading(true);
    const list = [];
    if (hasLevel3) {
      for (const itemLv2 of props.items) {
        for (const itemLv3 of ((itemLv2 as ICompetenceItemLevel2).items_level3 || [])) {
          list.push({
            code: props.code,
            eventId: props.eventId,
            subCode: itemLv3.subCode,
            subCodeSub: itemLv3.subCodeSub,
            finalNotes: values.finalNotes,
          });
        }
      }
    } else {
      for (const itemLv2 of props.items) {
        list.push({
          code: props.code,
          eventId: props.eventId,
          subCode: itemLv2.subCode,
          subCodeSub: null,
          finalNotes: values.finalNotes,
        });
      }
    }

    try {
      const data = {
        userId: props.userId,
        data: list
      };
      console.info('data', data);
      console.info('props.code', props.code);
      console.info('props.eventId', props.eventId);
      await httpRequest.patch(`evaluation/${props.code}/${props.eventId}/set-final-notes`, data);

      message.success('Updated "notes from main mentor" successfully');
      Modal.destroyAll();
      setIsLoading(false);
      window.location.reload();
    } catch(err) {
      getStandardError(err, {showToast: true});
      Modal.destroyAll();
      setIsLoading(false);
    }
  }
  
  return (
    <React.Fragment>
      {!hasScore && (
        <Alert
          showIcon
          type="error"
          message="Score is not completed"
          description={
            <div>
              You can set the final notes after the score inputted (see list
              below). Makesure the score has been inputted by admin or mentor.
              {hasLevel3 ? (
                <div>
                  <ul>
                    {props.items.map((item) => (
                      <li key={item.subCode}>{item.title}</li>
                    ))}
                  </ul>
                  <div style={{ fontStyle: 'italic', color: 'darkred' }}>
                    * Notes: Average for each tab can't be zero.
                  </div>
                </div>
              ) : (
                <div style={{ fontStyle: 'italic', color: 'darkred' }}>
                  * Notes: Average can't be zero.
                </div>
              )}
            </div>
          }
          style={{
            marginBottom: 20,
          }}
          action={[
            <Button
              icon={<EditOutlined />}
              size="small"
              type="primary"
              onClick={() => {
                if (props.onClickInputScore) {
                  Modal.destroyAll();
                  props.onClickInputScore();
                }
              }}
            >
              Input Score
            </Button>,
          ]}
        />
      )}
      <div
        style={{
          marginBottom: 20,
          padding: 30,
          backgroundColor: hasScore ? '#EBD3F8' : '#f2f2f2',
          borderRadius: 10,
        }}
      >
        <Title level={5}>
          {props.title}

          <Tooltip title="Click to see the changes detail">
            <InfoCircleFilled
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                //
                setIsShowModal(true);
              }}
            />
          </Tooltip>
        </Title>
        {/* <Row>
          <Col span={8}>
            <DetailItem label="Code" value={props.code} />
          </Col>
          <Col span={16}>
            <DetailItem label="Sub Code" value={props.subCode} />
          </Col>
        </Row> */}
        <Form initialValues={props.initialValues} onFinish={handleSave}>
          <Form.Item
            label={props.label ?? 'Notes from Main Mentor'}
            name="finalNotes"
            required
            rules={generateFormRules(props.label ?? 'Notes from Main Mentor', [
              'required',
            ])}
          >
            <Input.TextArea disabled={!hasScore} />
          </Form.Item>

          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!hasScore}
            >
              Submit Notes from Main Mentor
            </Button>
          </Row>
        </Form>

        <Modal
          open={isShowModal}
          width={1000}
          closable
          footer={false}
          onClose={() => setIsShowModal(false)}
          onCancel={() => setIsShowModal(false)}
        >
          <EvaluationChangeLogs
            hideEvalCode
            hideEvent
            hideChanges
            talentId={props.userId}
            searchByExternalId={
              props.userId +
              '__' +
              props.code +
              '__' +
              props.eventId +
              '__' +
              props.items[0].subCode +
              (hasLevel3
                ? '__' +
                  (props.items as ICompetenceItemLevel2[])[0].items_level3?.[0]
                    ?.subCodeSub
                : '') +
              '__final_notes'
            }
          />
        </Modal>
      </div>
    </React.Fragment>
  );
}