import { Badge } from 'antd';
import React from 'react';

type Props = {
  label?: string;
  average: number;
  hasChanges?: boolean;
  color?: string;
  suffix?: string;

  info?: any;
};

export default function EvalBadgeAverage(props: Props) {
  let color = 'red';

  if (props.color) {
    color = props.color;
  } else if (props.average === 0) {
    color = 'red';
  } else {
    color = 'blue';
  }

  let scoreText = (props.average || 0).toFixed(2);
  if (scoreText.split('.')[1] === '00') {
    scoreText = scoreText.split('.')[0];
  }

  if (props.hasChanges) {
    return (
      <Badge
        count={`${scoreText} -> ??? `}
        color="orange"
        style={{ marginLeft: 5, fontSize: 10 }}
      />
    );
  }

  return (
    <Badge
      overflowCount={100}
      count={
        (props.label ? props.label + ' : ' : '') +
        scoreText +
        (props.suffix ? ' ' + props.suffix : '')
      }
      color={color}
      style={{ marginLeft: 5, fontSize: 10 }}
    />
  );
}
