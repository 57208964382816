export const COMPETENCES = [
  { competenceCode: '01A', title: '01A - Transformation' },
  { competenceCode: '01B', title: '01B - Data Analytics & Management' },
  { competenceCode: '02A', title: '02A - Project Management' },
  {
    competenceCode: '02B',
    title: '02B - Strategic Thinking & Problem-Solving',
  },
  { competenceCode: '03A', title: '03A - Business Acumen' },
  { competenceCode: '03B', title: '03B - Financial Literacy' },
  { competenceCode: '04A', title: '04A - Communication' },
  { competenceCode: '04B', title: '04B - Collaboration & Networking' },
  { competenceCode: '05A', title: '05A - Design Thinking & Innovation' },
  { competenceCode: '05B', title: '05B - Market Analysis & Customer Focus' },
];