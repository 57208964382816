import { getDateTimeRangeString, NotSet, useFetchList } from '@qlibs/react-components';
import { Collapse, CollapseProps, Divider, Modal, Spin, Typography } from 'antd'
import Title from 'antd/lib/typography/Title';
import { useEffect, useMemo, useState } from 'react'
import { httpRequest } from '../../../helpers/api';
import { EEventType, EventsProps } from '../../../types/event.type';
import useProgramStore from '../../../zustand/useProgramStore';
import DetailResultByTalent from '../../quiz/Result/DetailResultByTalent';

const {Text} = Typography;

export default function DBIQuizResultByEvent({eventId, talentId, title, onClose, onCancel}: {eventId: string; talentId: string; title: string; onClose: () => void; onCancel: () => void}) {
  const selectedProgram = useProgramStore(state => state.selectedProgram);
  const [isOpen, setIsOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [quizId, setQuizId] = useState<string>();
  
  const { isLoading, data: events } = useFetchList<EventsProps>({
    httpRequest: httpRequest as any,
    endpoint: '/event',
    limit: 99999,
    initialQuery: {
      programId: selectedProgram?.programId,
      eventType: EEventType.DBI_PROJECT,
      sort: 'startAt:ASC',
    },
  });

  useEffect(() => {
    setIsOpen(true);
  }, []);

  // const fetchEvent = async () => {
  //   if (!eventId) {
  //     return;
  //   }
  //   setIsOpen(true);
  //   setIsLoading(true);
  //   try {
  //     const res = await httpRequest.get('event/' + eventId);

  //     if (res?.data?.payload) {
  //       setQuizId(res?.data?.payload.reportFormId);
  //     }
  //     setIsLoading(false);
  //   } catch (err) {
  //     getStandardError(err, { showToast: true });
  //     setIsLoading(false);
  //   }
  // }

  const items: CollapseProps['items'] = useMemo(() => {
    return events.map((event) => ({
      key: event.eventId,
      label: (
        <Text>
          <Text style={{fontWeight: 'bold'}}>{event.title}</Text>{' '}
          <Text style={{ fontSize: 11 }}>
            ({getDateTimeRangeString(event.startAt, event.endAt)})
          </Text>
        </Text>
      ),
      children: (
        <div>
          {event.reportFormId ? (
            <DetailResultByTalent
              eventId={event.eventId}
              quizId={event.reportFormId}
              userId={talentId}
              detailButtonMode="modal"
              hideHeader
            />
          ) : (
            <NotSet value="Form is not set for this event" />
          )}
        </div>
      ),
    }));
  }, [events])

  return (
    <Modal
      open={isOpen}
      loading={isLoading}
      closable
      width={900}
      title={title}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onCancel={() => {
        setIsOpen(false);
        onCancel();
      }}
      footer={false}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <Collapse items={items} defaultActiveKey={events[0]?.eventId} />
      )}
    </Modal>
  );
}