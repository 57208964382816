import { StarFilled } from '@ant-design/icons';
import { getDateRangeString } from '@qlibs/react-components';
import { Tooltip, Tag, Modal, Table, Button, Divider } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react'
import { useLocation } from 'react-router-dom';
import useProgram from '../../hooks/useProgram';

export default function CurrentProgramBatch() {
  const location = useLocation();
  const { selectedProgram, programs, setSelectedProgram } = useProgram();

  if (process.env.REACT_APP_INCLUDE_PROGRAM_ID === 'true' &&
    !location.pathname.includes('/program')) {
      return (
        <Tooltip title="Click to change program">
          <Tag
            color="blue"
            style={{
              fontSize: 16,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
              cursor: 'pointer',
            }}
          >
            <div
              style={{ textAlign: 'right' }}
              onClick={() => {
                Modal.confirm({
                  title: 'Choose Program (Batch)',
                  content: (
                    <div>
                      <Paragraph>Which program will you choose?</Paragraph>

                      <Table
                        columns={[
                          {
                            key: 'title',
                            dataIndex: 'title',
                            title: 'Program (Batch)',
                          },
                          {
                            key: 'startAt',
                            dataIndex: 'startAt',
                            title: 'Period',
                            render: (startAt, record) =>
                              getDateRangeString(startAt, record.endAt),
                          },
                          {
                            key: 'actions',
                            dataIndex: 'actions',
                            title: 'Action',
                            render: (value, record) =>
                              record.programId ===
                              selectedProgram?.programId ? (
                                <Button type="default" disabled={true}>
                                  Selected
                                </Button>
                              ) : (
                                <Button
                                  type="default"
                                  onClick={() => {
                                    setSelectedProgram(record);
                                    Modal.destroyAll();

                                    setTimeout(() => {
                                      window.location.reload();
                                    }, 300);
                                  }}
                                >
                                  Select
                                </Button>
                              ),
                          },
                        ]}
                        dataSource={programs}
                        pagination={false}
                      />
                    </div>
                  ),
                  width: 800,
                  closable: true,
                  footer: false,
                });
              }}
            >
              <StarFilled />

              <span
                style={{
                  marginLeft: 10,
                  fontWeight: 'bold',
                }}
              >
                {(selectedProgram?.title || '').length > 10
                  ? selectedProgram?.title.substring(0, 8) + '...'
                  : selectedProgram?.title}
              </span>

              <span style={{ marginLeft: 10, fontSize: 10, color: 'darkblue' }}>
                {getDateRangeString(
                  selectedProgram?.startAt,
                  selectedProgram?.endAt,
                  'short',
                  'short'
                )}
              </span>
            </div>
          </Tag>
        </Tooltip>
      );
  }

  return <></>
}