export function generateUrlPath(data: string[]) {
  return data.map(item => item.toLowerCase()).join('--');
}

export function getBasePath(location?: any) {
  if (location) {
    return location.pathname.split('/')[1];
  } else {
    return window.location.pathname.split('/')[1];
  }
}