import moment from "moment";

export const dateRangeInMinutes = (startDate: Date, endDate: Date) => {
  const start = moment(startDate);
  const end = moment(endDate);

  return end.diff(start, "minutes");
};

export function isToday(dateString: string): boolean {
  // Parse the input date string to a moment object
  const inputDate = moment(dateString, 'YYYY-MM-DD');

  // Check if the parsed date is the same as today's date
  return inputDate.isSame(moment(), 'day');
}

export function isPast(dateString: string): boolean {
  // Parse the input date string to a moment object
  const inputDate = moment(dateString, 'YYYY-MM-DD');

  // Check if the input date is before today's date
  return inputDate.isBefore(moment(), 'day');
}