import React from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Card,
  Button,
  Space,
  Typography,
  Flex,
  Tag,
  Spin,
  message,
} from "antd";

import { httpRequest } from "../../../helpers/api";
import {
  AppTable,
  formatDate,
  getErrorMessage,
  getStandardError,
  IAppTableColumn,
  NotSet,
  useFetchList,
} from "@qlibs/react-components";

import {
  EQuizType,
  ESubmitType,
  QuizAttemptProperties,
  QuizProperties,
} from "../../../types/quiz.type";
import SimpleDetailItem from "../../../components/SimpleDetailItem";
import moment from "moment";
import ModalAddNotes from "../components/ModalAddNotes";
import { EventsProps } from "../../../types/event.type";
import useAdditionalDataForList from "../../../hooks/useAdditionalDataForList";
import { dateRangeInMinutes } from "../../../helpers/datetime";
import usePermission from "../../../hooks/usePermission";

interface ILocation {
  quizId: string;
}

const { RangePicker } = DatePicker;

const ByHistory = ({ quiz }: { quiz: QuizProperties | undefined }) => {
  const { isUserHasPermission } = usePermission();
  const { quizId } = useParams<keyof ILocation>() as ILocation;
  const [showModalAddNotes, setShowModalAddNotes] =
    React.useState<boolean>(false);
  const [selectedQuizAttempt, setSelectedQuizAttempt] = React.useState<
    QuizAttemptProperties | undefined
  >(undefined);

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    query,
    setQuery,
    fetchList,
  } = useFetchList<QuizAttemptProperties>({
    httpRequest: httpRequest as any,
    endpoint: "quiz-attempt",
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
    initialQuery: {
      quizIds: quizId,
      filterRole: "talent,customer",
    },
  });

  const { isLoadingAdditionalData, additionalData } = useAdditionalDataForList<{
    [id: string]: {
      event: EventsProps;
    };
  }>({
    id: "id",
    data,
    injects: [
      {
        injectedId: ["externalData", "eventId"],
        endpoint: "/event?eventIds=",
        endpointId: "eventId",
        returnKey: "event",
      },
    ],
  });

  const columns: IAppTableColumn<QuizAttemptProperties>[] = [
    {
      title: "USER",
      dataIndex: ["user", "name"],
      key: "user",
      type: "detail",
      keyId: "id",
      showOriginalValue: true,
    },
    {
      title: "ATTEMPT",
      dataIndex: "attempt",
      key: "attempt",
    },
    {
      title: "NUMBER OF QUESTIONS",
      dataIndex: "numberQuestions",
      key: "numberQuestions",
      width: 120,
      render: (v: number, record: QuizAttemptProperties) => (
        <>{record.attemptDetails?.length}</>
      ),
    },
    quiz?.quizType !== EQuizType.SURVEY
      ? {
          title: "SCORE",
          dataIndex: "score",
          key: "score",
          render: (v: number) => <>{typeof v === "number" ? v : <NotSet />}</>,
        }
      : {},

    quiz?.quizType === EQuizType.SURVEY &&
    quiz?.submitType === ESubmitType.PER_EVENT
      ? {
          title: "EVENT",
          dataIndex: "eventId",
          key: "eventId",
          width: 200,
          render: (value, record) =>
            isLoadingAdditionalData ? (
              <Spin />
            ) : additionalData[record.id]?.event ? (
              additionalData[record.id]?.event?.title
            ) : (
              <NotSet />
            ),
          // render: (_: any, record: QuizAttemptProperties) => {
          //   return (
          //     <>
          //       {record ? (
          //         <>{record?.externalData?.eventId || <NotSet />}</>
          //       ) : (
          //         <NotSet />
          //       )}
          //     </>
          //   );
          // },
        }
      : {},

    {
      title: "NOTES FROM MENTOR",
      dataIndex: "notesFromMentor",
      key: "notesFromMentor",
      width: 200,
      render: (notes: string, record: QuizAttemptProperties) => (
        <Space direction="vertical">
          {notes && <Typography.Text>{notes}</Typography.Text>}
          <Button
            type="link"
            onClick={() => {
              setShowModalAddNotes(true);
              setSelectedQuizAttempt(record);
            }}
            style={{
              fontSize: 12,
              width: "initial",
              padding: 0,
            }}
          >
            {notes ? "Edit" : "Add"} Notes
          </Button>
        </Space>
      ),
    },
    {
      title: "START AT",
      dataIndex: "startAt",
      key: "startAt",
      type: "datetime",
    },
    {
      title: "FINISHED AT",
      dataIndex: "finishedAt",
      key: "finishedAt",
      type: "datetime",
      render: (date: Date, record: QuizAttemptProperties) => (
        <Space direction="vertical">
          <div>{date ? formatDate(date, "DD MMM YYYY HH:mm") : <NotSet />}</div>
          {record?.isFinishedBySystem && (
            <Tag color="red">Finished By System</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "DURATION",
      dataIndex: "duration",
      key: "duration",
      render: (date: Date, record: QuizAttemptProperties) => {
        let duration;
        if (record.startAt && record.finishedAt) {
          duration = dateRangeInMinutes(record.startAt!, record.finishedAt);
        }
        return (
          <>
            {typeof duration === "number" ? `${duration} Minutes` : <NotSet />}
          </>
        );
      },
    },
    {
      title: "ROLE",
      dataIndex: ["user", "role", "roleName"],
      key: "role",
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      actions: [
        {
          key: "detail",
          label: "Detail",
        },
        data &&
        data.length > 0 &&
        data[0]?.quiz?.quizType === EQuizType.SURVEY &&
        data[0]?.quiz?.title.includes("OJT")
          ? {
              key: "send-copy-to-email",
              label: "Send Copy to Email",
              onPress: (id) => {
                httpRequest
                  .post(`quiz-attempt/${id}/send-copy-to-email`)
                  .then((res) => {
                    message.success("Sent the copy of response to email");
                  })
                  .catch((err) => {
                    getStandardError(err, { showToast: true });
                  });
              },
            }
          : undefined,
        isUserHasPermission(["QUIZ_ATTEMPT.DELETE"])
          ? {
              key: "delete",
              label: "Delete",
              confirmation: {
                title: "Confirmation",
                content: "Are you sure want to delete this attempt?",
              },
              onPress: (id) => {
                if (id) {
                  doDeleteAttempt(id);
                }
              },
            }
          : undefined,
      ],
    },
  ];

  const doDeleteAttempt = async (attemptId: string) => {
    try {
      await httpRequest.delete(`/quiz-attempt/${attemptId}`);
      fetchList();
      message.success("Success delete submission");
    } catch (error) {
      message.error(getErrorMessage(error));
    }
  };

  return (
    <React.Fragment>
      <ModalAddNotes
        open={showModalAddNotes}
        onClose={() => {
          setShowModalAddNotes(false);
          setSelectedQuizAttempt(undefined);
        }}
        quizAttempt={selectedQuizAttempt}
        onSuccess={() => {
          fetchList();
        }}
      />
      <Row
        style={{
          margin: "12px 0px",
        }}
        gutter={[12, 12]}
      >
        <Col span={8}>
          <SimpleDetailItem label="Search">
            <Input
              placeholder="Search by name"
              onChange={(e) =>
                setQuery({
                  ...query,
                  search: e.target.value,
                })
              }
              allowClear
            />
          </SimpleDetailItem>
        </Col>
        {quiz?.quizType !== EQuizType.SURVEY && (
          <Col span={4}>
            <SimpleDetailItem label="Score">
              <Select
                options={[
                  {
                    label: "Highest to Lowest",
                    value: "highest",
                  },
                  {
                    label: "Lowest to Highest",
                    value: "lowest",
                  },
                ]}
                onChange={(e) => {
                  if (e === "highest") {
                    setQuery({
                      ...query,
                      sort: `highest:DESC`,
                    });
                  } else if (e === "lowest") {
                    setQuery({
                      ...query,
                      sort: `lowest:ASC`,
                    });
                  }
                }}
                placeholder="Sort by Score"
                allowClear
              />
            </SimpleDetailItem>
          </Col>
        )}

        <Col span={6}>
          <SimpleDetailItem label="Start At">
            <RangePicker
              onChange={(date, dateString) => {
                if (dateString[0] && dateString[1]) {
                  let filterStartAt = moment(dateString[0])
                    .startOf("day")
                    .toISOString();
                  let filterEndAt = moment(dateString[1])
                    .endOf("day")
                    .toISOString();

                  setQuery({
                    ...query,
                    startAt: filterStartAt,
                    startAtEndAt: filterEndAt,
                  });
                }
              }}
              allowClear
            />
          </SimpleDetailItem>
        </Col>
        <Col span={6}>
          <SimpleDetailItem label="Finished At">
            <RangePicker
              onChange={(date, dateString) => {
                if (dateString[0] && dateString[1]) {
                  let filterStartAt = moment(dateString[0])
                    .startOf("day")
                    .toISOString();
                  let filterEndAt = moment(dateString[1])
                    .endOf("day")
                    .toISOString();

                  setQuery({
                    ...query,
                    finishedStartAt: filterStartAt,
                    finishedEndAt: filterEndAt,
                  });
                }
              }}
              allowClear
            />
          </SimpleDetailItem>
        </Col>
      </Row>

      <Card bordered={false} size="small" loading={isLoading}>
        <AppTable
          isLoading={isLoading}
          keyId="id"
          columns={columns.filter((v) => v.key)}
          data={data}
          pagination={pagination}
          onChangeLimit={changeLimit}
          onChangePage={changePage}
        />
      </Card>
    </React.Fragment>
  );
};

export default ByHistory;
