import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, IssuesCloseOutlined, PauseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { TagProps } from 'antd';
import { AttendanceStatus } from '../../../types/attendance.type';

export const MAPPING_ATTENDANCE_STATUS_PROPS: {
  [key: string]: TagProps;
} = {
  [AttendanceStatus.ALPHA]: {
    color: 'red',
    icon: <CloseCircleOutlined />,
  },
  [AttendanceStatus.LATE]: {
    color: 'gray',
    icon: <WarningOutlined />,
  },
  [AttendanceStatus.PERMISSION]: {
    color: 'purple',
    icon: <IssuesCloseOutlined />,
  },
  [AttendanceStatus.PRESENT]: {
    color: 'green',
    icon: <CheckCircleOutlined />,
  },
  [AttendanceStatus.SICK]: {
    color: 'orange',
    icon: <PauseCircleOutlined />,
  },

  [AttendanceStatus.UPCOMING]: {
    color: 'default',
    icon: <ClockCircleOutlined />,
  },
};