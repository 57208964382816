import React from "react";
import {
  HeaderSection,
  useFetchList,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import ListTalentForEvaluation from './ListTalentForEvaluation';
import useProgramStore from '../../zustand/useProgramStore';
import { ProgramTalentProps } from '../../types/programTalent.type';
import { Spin } from 'antd';
import ButtonDownloadFinalReport from '../program/components/ButtonDownloadFinalReport';

const Evaluation = () => {
  const selectedProgram = useProgramStore((state) => state.selectedProgram);

  const programId = process.env.REACT_APP_INCLUDE_PROGRAM_ID === 'true' ? selectedProgram?.programId : undefined;

  const { isLoading, data: programTalents } = useFetchList<ProgramTalentProps>({
    httpRequest: httpRequest as any,
    endpoint: 'program-talents',
    limit: 99999,
    initialQuery: {
      programId: selectedProgram?.programId,
    },
  });

  return (
    <React.Fragment>
      <HeaderSection
        title={'Evaluation ' + (programId ? '~' : '')}
        rightAction={<ButtonDownloadFinalReport programId={programId || ''} />}
      />

      {isLoading ? (
        <Spin />
      ) : (
        <ListTalentForEvaluation
          talentIds={programTalents.map((pt) => pt.talentId)}
        />
      )}
    </React.Fragment>
  );
};
export default Evaluation;
