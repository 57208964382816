import {
  AppTable,
  IAppTableColumn,
  PAGE_SIZE_OPTIONS,
  useFetchList,
} from "@qlibs/react-components";
import { Button, Modal, Space, Spin, Tabs, Typography } from "antd";
import { httpRequest } from "../../helpers/api";
import { TabsProps } from "antd/lib";
import CompareDataView from "../../components/CompareDataView";

const { Title } = Typography;

export default function QuizChangeLogs({ quizIds }: { quizIds?: string[] }) {
  const {
    isLoading: isLoadingLogs,
    data,
    pagination: paginationLogs,
    changePage: changePageLogs,
    changeLimit: changeLimitLogs,
    query,
    setQuery,
  } = useFetchList<any>({
    endpoint: `/quiz-change-logs`,
    httpRequest: httpRequest as any,
    limit: +PAGE_SIZE_OPTIONS[0],
    initialQuery: {
      actions: "CREATE,UPDATE,SOFT_DELETE,FORCE_DELETE,COPY,RESTORE",
      quizIds: quizIds?.join(","),
    },
  });

  const columns: IAppTableColumn<any>[] = [
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAt",
      type: "datetime",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      type: "status",
      mappingStatus: {
        COPY: {
          label: "COPY",
          tagProps: {
            color: "gray",
          },
        },
        CREATE: {
          label: "CREATE",
          tagProps: {
            color: "green",
          },
        },
        UPDATE: {
          label: "UPDATE",
          tagProps: {
            color: "orange",
          },
        },
        START_QUIZ: {
          label: "START QUIZ",
          tagProps: {
            color: "cyan",
          },
        },
        SUBMIT_QUIZ: {
          label: "SUBMIT QUIZ",
          tagProps: {
            color: "lime",
          },
        },
        SOFT_DELETE: {
          label: "SOFT DELETE",
          tagProps: {
            color: "volcano",
          },
        },
        FORCE_DELETE: {
          label: "FORCE DELETE",
          tagProps: {
            color: "red",
          },
        },
        RESTORE: {
          label: "RESTORE",
          tagProps: {
            color: "blue",
          },
        },
        DELETED_SUBMISSION: {
          label: "DELETED SUBMISSION",
          tagProps: {
            color: "red",
          },
        },
      },
    },
    {
      title: "Action By",
      key: "updatedByUserId",
      dataIndex: "updatedByUserId",
      render: (value, record) => {
        return record.actionByMeta?.name;
      },
    },

    {
      title: "Changes",
      key: "newData",
      dataIndex: "newData",
      render: (value, record) => {
        const { previousData, newData } = record;
        if (previousData) {
          delete previousData.createdByUserId;
          delete previousData.metaCreatedByUser;
          delete previousData.createdAt;
          delete previousData.updatedAt;
        }
        if (newData) {
          delete newData.createdByUserId;
          delete newData.metaCreatedByUser;
          delete newData.createdAt;
          delete newData.updatedAt;
        }
        return (
          <Button
            size="small"
            type="link"
            onClick={() => {
              Modal.info({
                title: "Data Changes",
                content: (
                  <CompareDataView oldData={previousData} newData={newData} />
                ),
                closable: true,
                width: 1000,
                footer: false,
              });
            }}
          >
            See Changes
          </Button>
        );
      },
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "data-quiz",
      label: "Master Quiz & Survey",
    },
    {
      key: "action-quiz",
      label: "Submissions",
    },
  ];

  return (
    <Spin spinning={isLoadingLogs}>
      <Title level={4}>Quiz & Survey Activities / Change Logs</Title>
      <Space direction="vertical" size="large">
        <Tabs
          defaultActiveKey="data-quiz"
          items={items}
          className="custom-tabs"
          onChange={(key) => {
            if (key === "data-quiz") {
              setQuery({
                ...query,
                offset: 0,
                actions: "CREATE,UPDATE,SOFT_DELETE,FORCE_DELETE,COPY,RESTORE",
              });
            } else if (key === "action-quiz") {
              setQuery({
                ...query,
                offset: 0,
                actions: "SUBMIT_QUIZ,START_QUIZ,DELETED_SUBMISSION",
              });
            }
          }}
        />
        <AppTable
          keyId="id"
          columns={columns}
          data={data}
          pagination={paginationLogs}
          isLoading={isLoadingLogs}
          onChangePage={changePageLogs}
          onChangeLimit={changeLimitLogs}
        />
      </Space>
    </Spin>
  );
}
