import { EQuizType, ESubmitType } from "../../../types/quiz.type";
import { Tag } from "antd";

export const renderTextQuizType = (type: EQuizType): string => {
  switch (type) {
    case EQuizType.POSTEST:
      return "Post Test";
    case EQuizType.MICRO_LEARNING:
      return "Micro Learning";
    case EQuizType.MICRO_LEARNING_PUBLIC:
      return "Micro Learning (Public)";
    case EQuizType.PRETEST:
      return "Pre Test";
    case EQuizType.SURVEY:
      return "Survey";
    default:
      return "";
      break;
  }
};

export default function RenderQuizType({ type }: { type: EQuizType }) {
  const renderColor = (type: EQuizType) => {
    switch (type) {
      case EQuizType.POSTEST:
        return "volcano";
      case EQuizType.MICRO_LEARNING:
        return "gold";
      case EQuizType.MICRO_LEARNING_PUBLIC:
        return "red";
      case EQuizType.PRETEST:
        return "green";
      case EQuizType.SURVEY:
        return "blue";
      default:
        break;
    }
  };
  return <Tag color={renderColor(type)}>{renderTextQuizType(type)}</Tag>;
}
