import { Col, Row, Table, TableColumnsType, Tag, Tooltip, Button } from 'antd';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { AttendanceProps, AttendanceStatus } from '../../types/attendance.type';
import { MAPPING_ATTENDANCE_STATUS_PROPS } from './data/mappingStatus';
import { getDateString, NotSet } from '@qlibs/react-components';
import { EventsProps } from '../../types/event.type';
import TagAttendanceStatus from './components/TagAttendanceStatus';
import { isToday } from '../../helpers/datetime';
import usePermission from '../../hooks/usePermission';
import { forwardRef, useImperativeHandle } from 'react';

export const generateDates = (start: string, end: string) => {
  const startDate = moment(start);
  const endDate = moment(end);
  const dates = [];
  while (startDate.isSameOrBefore(endDate)) {
    dates.push(startDate.format('YYYY-MM-DD'));
    startDate.add(1, 'days');
  }
  return dates;
};

export type IAbsensiData = {
  key: string;
  userId: string;
  name: string;
  email: string;
  attendance: {
    [date: string]: AttendanceProps;
  };
  summary: {
    [status: string]: number;
  };
};


export type IEventData = {
  [date: string]: EventsProps[];
};

type Props = {
  isLoading: boolean;
  data: IAbsensiData[];
  event: IEventData;
  dateStartAt: string;
  dateEndAt: string;
};

const AbsensiTable = forwardRef((props: Props, ref) => {
  const { isUserHasPermission } = usePermission();
  const dates = generateDates(props.dateStartAt, props.dateEndAt);

  const handleDownloadExcelTemplate = async () => {
    const startAt = moment(props.dateStartAt)
    const endAt = moment(props.dateEndAt)
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Summary');
    const worksheetRaw = workbook.addWorksheet('Raw')

    worksheetRaw.columns = [
      { header: 'CHECK IN AT', key: 'checkInAt', width: 25 },
      { header: 'NAME', key: 'name', width: 25 },
      { header: 'EMAIL', key: 'email', width: 30 },
      { header: 'STATUS', key: 'status', width: 25 },
      { header: 'LNG', key: 'lng', width: 25 },
      { header: 'LAT', key: 'lat', width: 25 },
      { header: 'REMARK', key: 'remark', width: 25 }

    ]

    worksheet.columns = [
      { header: '', key: 'name', width: 25 },
      { header: 'PRESENT', key: 'present', width: 10, style: { alignment: { wrapText: true } } },
      { header: 'LATE', key: 'late', width: 10, style: { alignment: { wrapText: true } } },
      { header: 'PERMISSION', key: 'permission', width: 10, style: { alignment: { wrapText: true } } },
      { header: 'SICK', key: 'sick', width: 10, style: { alignment: { wrapText: true } } },
      { header: 'ALPHA', key: 'alpha', width: 10, style: { alignment: { wrapText: true } } },
      { header: 'UPCOMING', key: 'upcoming', width: 10, style: { alignment: { wrapText: true } } },
      ...dates.map(date => ({
        header: date,
        key: date,
        width: 15,
        style: { alignment: { wrapText: true } }
      })),
    ];
    worksheet.mergeCells('A1:A2');
    worksheet.getCell('A1').value = ''

    worksheet.mergeCells('B1:G1');
    worksheet.getCell('B1').value = 'Summary';
    worksheet.getRow(2).values = [
      'Talent Name',
      'PRESENT',
      'LATE',
      'PERMISSION',
      'SICK',
      'ALPHA',
      'UPCOMING',
      ...dates.map(date => props.event[date]?.length > 0
        ? `${props.event[date]?.length} event(s)`
        : '-'),
    ];

    [worksheet.getRow(1), worksheet.getRow(2)].forEach(row => {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: '000000' },
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
      });
    });

    [worksheetRaw.getRow(1)].forEach(row => {
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: '000000' },
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
      });
    });


    const getStatusColor = (status: any) => {
      switch (status) {
        case 'PRESENT':
          return '52c41a';
        case 'LATE':
          return '8c8c8c';
        case 'ABSENT':
          return 'ff4d4f';
        case 'PERMISSION':
          return '722ed1';
        case 'SICK':
          return 'fa8c16';
        case 'ALPHA':
          return 'ff4d4f'
        default:
          return '000000';
      }
    };

    ['PRESENT', 'LATE', 'PERMISSION', 'SICK', 'ALPHA', 'UPCOMING'].forEach((status, index) => {
      const cell = worksheet.getCell(2, index + 2);
      const color = getStatusColor(status);
      cell.font = { color: { argb: color } };
    });

    props.data.forEach((record) => {
      const formattedSummary = Object.entries(record.summary)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');

      const row = {
        name: record.name,
        present: record.summary.PRESENT || '0',
        late: record.summary.LATE || '0',
        permission: record.summary.PERMISSION || '0',
        sick: record.summary.SICK || '0',
        alpha: record.summary.ALPHA || '0',
        upcoming: record.summary.UPCOMING || '0',
        ...dates.reduce((acc: any, date) => {
          acc[date] = record.attendance[date]?.status || 'Not Set';
          return acc;
        }, {}),
      };

      Object.entries(record.attendance).map(([date, attendanceProps]) => {
        const checkInDate = moment(attendanceProps.checkInAt) 
        const isInRange = checkInDate.isBetween(startAt, endAt, undefined, '[]');


        if(isInRange){
          const rowRaw = {
            name: record.name,
            email: record.email,
            status: attendanceProps.status,
            checkInAt: attendanceProps.checkInAt ? attendanceProps.status === AttendanceStatus.PRESENT ? moment(attendanceProps.checkInAt).format('DD MMM YYYY HH:mm') : moment(attendanceProps.checkInAt).format('DD MMM YYYY') : 'Not Set',
            lat: attendanceProps.locationIn ? attendanceProps.locationIn.lat : 'Not Set',
            lng: attendanceProps.locationIn ? attendanceProps.locationIn.lng : 'Not Set',
            remark: attendanceProps.remark ?? 'Not Set'
          }
  
          const newRowRaw = worksheetRaw.addRow(rowRaw);
        }

      });


      const newRow = worksheet.addRow(row);

      dates.forEach((date, index) => {
        const cell = newRow.getCell(index + 8)
        const status = record.attendance[date]?.status || 'Not Set';
        const color = getStatusColor(status);

        cell.font = {
          color: { argb: color },
        };
      });

      ['present', 'late', 'permission', 'sick', 'alpha', 'upcoming'].forEach((key, index) => {
        const status = key.toUpperCase();
        const color = getStatusColor(status);

        const cell = newRow.getCell(index + 2);
        cell.font = {
          color: { argb: color },
        };
      });
    });

    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      });
    });


    worksheetRaw.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      });
    });

    const excelFile = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const getFormattedDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}`;

    }

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `Attendance (${props.dateStartAt} ~ ${props.dateEndAt}) - downloaded at ${getFormattedDate()}.xlsx`;

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  };

  const columns: TableColumnsType<IAbsensiData> = [
    {
      title: 'Talent Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      fixed: 'left',
      render: (summary) => (
        <div>
          {Object.keys(summary).map((status) => (
            <Row key={status} justify="space-between" gutter={6}>
              <Col style={{ fontSize: 11, color: MAPPING_ATTENDANCE_STATUS_PROPS[status].color }}>
                {status}
              </Col>
              <Col style={{ fontSize: 11, color: MAPPING_ATTENDANCE_STATUS_PROPS[status].color }}>
                {summary[status]}
              </Col>
            </Row>
          ))}
        </div>
      ),
    },
    ...dates.map((date) => ({
      title: (
        <div style={isToday(date) ? { backgroundColor: '#B7E0FF', padding: '5px 10px', borderRadius: 5 } : {}}>
          {props.event[date]?.length > 0 ? (
            <div>
              <div style={{ fontSize: 11 }}>{moment(date).format('dddd')}</div>
              <div style={{ color: 'green' }}>
                {getDateString(date, 'short', 'short')}
              </div>
              <div style={{ fontSize: 11, color: 'darkgrey' }}>
                {props.event[date]?.length + ' event(s)'}
              </div>
            </div>
          ) : (
            <div style={{ color: 'red' }}>
              <div style={{ fontSize: 11 }}>{moment(date).format('dddd')}</div>
              <div>{getDateString(date, 'short', 'short')}</div>
              <div style={{ fontSize: 11 }}>-</div>
            </div>
          )}
        </div>
      ),
      dataIndex: ['attendance', date],
      key: date,
      render: (attendance: AttendanceProps, record: IAbsensiData) => {
        if (record.key === 'event') {
          return;
        } else {
          if (attendance) {
            return (
              <div>
                {isUserHasPermission(['ATTENDANCE.UPDATE']) ? (
                  <Tooltip title="Click to change the status">
                    <TagAttendanceStatus
                      readonly={false}
                      attendanceId={attendance.attendanceId}
                      userId={attendance.userId}
                      checkInAt={attendance.checkInAt}
                      currentStatus={attendance.status}
                      onSuccess={() => {
                        window.location.reload();
                      }}
                    />
                  </Tooltip>
                ) : (
                  <TagAttendanceStatus
                    readonly={true}
                    attendanceId={attendance.attendanceId}
                    userId={attendance.userId}
                    checkInAt={attendance.checkInAt}
                    currentStatus={attendance.status}
                    onSuccess={() => {
                      window.location.reload();
                    }}
                  />
                )}

                {attendance.status === AttendanceStatus.PRESENT || attendance.status === AttendanceStatus.LATE ? (
                  <div style={{ fontSize: 11, color: 'darkgrey' }}>
                    {moment(attendance.checkInAt).format('HH:mm')}
                  </div>
                ) : (
                  false
                )}
              </div>
            );
          } else {
            return <NotSet />;
          }
        }
      },
    })),
  ];

  useImperativeHandle(ref, () => ({
    handleDownloadExcelTemplate,
  }));


  return (
    <>
      <Table
        loading={props.isLoading}
        columns={columns}
        dataSource={props.data}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
});

export default AbsensiTable;
