import { AppTable, getDateTimeRangeString, IAppTableColumn, NotSet, useFetchList } from '@qlibs/react-components';
import { Alert, Button, Card, Modal, Spin, Typography } from 'antd';
import React, { useMemo, useState } from 'react'
import { httpRequest } from '../../../helpers/api';
import useAdditionalDataForList from '../../../hooks/useAdditionalDataForList';
import useAuthApp from '../../../hooks/useAuthApp';
import usePermission from '../../../hooks/usePermission';
import { EEventType, EventsProps } from '../../../types/event.type';
import { EQuizType, QuizAttemptProperties } from '../../../types/quiz.type';
import useProgramStore from '../../../zustand/useProgramStore';
import ModalAddNotes from '../../quiz/components/ModalAddNotes';
import DetailResultByTalent from '../../quiz/Result/DetailResultByTalent';

const {Text, Title} = Typography;

type Props = {
  talentId: string;
}
export default function ListMentoring(props: Props) {
  const {user} = useAuthApp();
  const {isUserHasPermission} = usePermission();
  const selectedProgram = useProgramStore((state) => state.selectedProgram);

  const [showModal, setShowModal] = useState<{type: 'mentoring'; quizId?: string; eventId: string}>();

  const [showModalAddNotes, setShowModalAddNotes] =
    React.useState<boolean>(false);
  const [selectedQuizAttempt, setSelectedQuizAttempt] = React.useState<
    QuizAttemptProperties | undefined
  >(undefined);

  const {data, isLoading} = useFetchList<EventsProps>({
    httpRequest: httpRequest as any,
    endpoint: 'event',
    // limit: 10,
    initialQuery: {
      eventType: EEventType.MENTORING,
      programId: selectedProgram?.programId,
      sort: 'startAt:DESC'
    }
  })

  const events = useMemo(() => {
    let list = data ? [...data] : [];
    list = list.filter(ev => ev.participants?.find(p => (p.role === 'TALENT' && p.userId === props.talentId)));

    if (!isUserHasPermission(['USER.SIDEBAR_MENU'])) {
      list = list.filter((ev) =>
        ev.participants?.find(
          (p) => p.role === 'MENTOR' && p.userId === user?.userId
        )
      );
    }

    return list.sort(
      (a, b) =>
        new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
    );
  }, [data]);

  const { isLoadingAdditionalData, additionalData } = useAdditionalDataForList<{
    [reportFormId: string]: {
      quiz: {
        quizId: string;
        userId: string;
        eventId: string;
        maxScore: number;
        minScore: number;
        avgScore: number;
        notesFromMentors: string;
      }[];
    }
  }>({
    id: 'eventId',
    data: events,
    injects: [
      {
        injectedId: 'reportFormId',
        endpoint:
          'evaluation-mentoring?talentId=' + props.talentId + '&quizIds=',
        endpointId: 'quizId',
        returnKey: 'quiz',
        multiple: true,
      },
    ],
  });
  console.info('additionalData', additionalData);

  const columns: (IAppTableColumn<EventsProps> | any)[] = [
    {
      title: 'Mentoring',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date & Time',
      dataIndex: 'date',
      key: 'date',
      render: (value: any, record: EventsProps) =>
        getDateTimeRangeString(record.startAt, record.endAt),
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
      width: 200,
      render: (value: EventsProps['participants'], record: EventsProps) => {
        if (!value) return <></>;

        const mentor = value.find((p) => p.role === 'MENTOR')?.user;
        const talents = value
          .filter((p) => p.role === 'TALENT')
          .map((p) => p.user)
          .filter((p) => p);
        return (
          <React.Fragment>
            <div>
              <Text style={{ color: 'gray' }}>
                Mentor: {mentor?.name}{' '}
                {mentor?.userId === user?.userId ? (
                  <Text style={{ fontWeight: 'bold' }}> (You)</Text>
                ) : (
                  false
                )}
              </Text>
            </div>
            <div>
              <Text style={{ color: 'gray' }}>
                Talent:{' '}
                {talents.map((t, index) => {
                  if (t?.userId === props.talentId) {
                    return (
                      <Text style={{ fontWeight: 'bold' }}>
                        {t.name}
                        {index === talents.length - 1 ? '' : ', '}
                      </Text>
                    );
                  }
                  return t?.name + (index === talents.length - 1 ? '' : ', ');
                })}{' '}
              </Text>
            </div>
          </React.Fragment>
        );
      },
    },
    isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS'])
      ? {
          title: 'Submissions',
          dataIndex: 'reportFormId',
          key: 'reportFormId',
          width: 200,
          render: (value: string, record: EventsProps) =>
            record.reportFormId ? (
              isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS']) ? (
                <Button
                  size="small"
                  type="link"
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    setShowModal({
                      type: 'mentoring',
                      quizId: record.reportFormId,
                      eventId: record.eventId,
                    });
                  }}
                >
                  Show Submissions
                </Button>
              ) : (
                <></>
              )
            ) : (
              <NotSet value="Form not exist" />
            ),
        }
      : {},
    {
      title: 'Notes From Mentor',
      dataIndex: 'notesFromMentor',
      key: 'notesFromMentor',
      width: 200,
      render: (value: string, record: EventsProps) => {
        const dt = additionalData[record.eventId]?.quiz.find(item => item.eventId === record.eventId);

        return record.reportFormId ? (
          isLoadingAdditionalData ? (
            <Spin />
          ) : (
            <div>
              {additionalData &&
              !!dt?.notesFromMentors ? (
                <Text style={{ fontSize: 12 }}>
                  {dt?.notesFromMentors}
                </Text>
              ) : (
                <NotSet />
              )}
              <Button
                type="link"
                href={
                  '/survey/' +
                  record?.reportFormId +
                  '/result/user/' +
                  props.talentId
                }
                target="_blank"
                style={{
                  fontSize: 12,
                  width: 'initial',
                  padding: 0,
                  marginLeft: 5,
                }}
              >
                {dt?.notesFromMentors
                  ? 'Edit'
                  : 'Add'}{' '}
                Notes
              </Button>
            </div>
          )
        ) : (
          <NotSet value="Form not exist" />
        );
      }
    },
  ];

  return (
    <React.Fragment>
      <Title level={4}>Mentoring</Title>

      <Alert
        type="warning"
        showIcon
        message={`Show only mentoring which containing current talent${
          isUserHasPermission(['USER.SIDEBAR_MENU']) ? '' : ' and you as mentor'
        }`}
        style={{ marginBottom: 10 }}
      />

      <AppTable
        keyId="eventId"
        data={events}
        columns={columns}
        isLoading={isLoading}
        pagination={false}
        // pagination={pagination}
        // onChangePage={changePage}
        // onChangeLimit={changeLimit}
      />

      {showModal && showModal?.type === 'mentoring' && showModal.quizId ? (
        <Modal
          open={true}
          onClose={() => {
            setShowModal(undefined);
          }}
          onCancel={() => {
            setShowModal(undefined);
          }}
          width={900}
          closable
          footer={false}
        >
          <DetailResultByTalent
            userId={props.talentId}
            quizId={showModal.quizId}
            eventId={showModal.eventId}
            hideHeader
            detailButtonMode="modal"
          />
        </Modal>
      ) : (
        false
      )}

      <ModalAddNotes
        open={showModalAddNotes}
        onClose={() => {
          setShowModalAddNotes(false);
          setSelectedQuizAttempt(undefined);
        }}
        quizAttempt={selectedQuizAttempt}
        onSuccess={() => {
          // setIsFetch(true);
        }}
      />
    </React.Fragment>
  );
}