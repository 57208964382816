import React from "react";
import ListQuiz from ".";
import { EQuizType } from "../../types/quiz.type";

export default function QuizMicroLearning() {
  return (
    <ListQuiz
      quizType={[EQuizType.MICRO_LEARNING, EQuizType.MICRO_LEARNING_PUBLIC]}
    />
  );
}
