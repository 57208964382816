export const SCORING_REFERENCES = {
  columns: [
    {
      key: 'score',
      dataIndex: 'score',
      title: 'Score',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
    },
    {
      key: 'parameter',
      dataIndex: 'parameter',
      title: 'Parameter',
    },
  ],
  data: [
    {
      score: '90-100',
      description: 'Exceeds expectations in all competencies.',
      parameter: 'Outstanding',
    },
    {
      score: '75-89',
      description: 'Consistently meets and occasionally exceeds expectations.',
      parameter: 'Strong Performer',
    },
    {
      score: '60-74',
      description: 'Meets expectations in most areas, with room for growth.',
      parameter: 'Competent',
    },
    {
      score: '45-59',
      description:
        'Below expectations in some areas, requiring significant development.',
      parameter: 'Developing',
    },
    {
      score: 'below-45',
      description: 'Significantly below expectations in most areas.',
      parameter: 'Needs Improvement',
    },
  ],
};
