import React from "react";
import {
  HeaderSection,
  useFetchList,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import ListTalentForReportData from './ListTalentForReportData';
import useProgramStore from '../../zustand/useProgramStore';
import { ProgramTalentProps } from '../../types/programTalent.type';
import { Spin } from 'antd';

const ReportData = () => {
  const selectedProgram = useProgramStore((state) => state.selectedProgram);

  const programId = process.env.REACT_APP_INCLUDE_PROGRAM_ID === 'true' ? selectedProgram?.programId : undefined;

  const { isLoading, data: programTalents } = useFetchList<ProgramTalentProps>({
    httpRequest: httpRequest as any,
    endpoint: 'program-talents',
    initialQuery: {
      programId: selectedProgram?.programId,
    },
  });

  return (
    <React.Fragment>
      <HeaderSection title={"Report Data " + (programId ? '~' : '')} />

      {isLoading ? (
        <Spin />
      ) :
        <ListTalentForReportData programId={selectedProgram?.programId || ''} talentIds={programTalents.map(pt => pt.talentId)} />
      }
    </React.Fragment>
  );
};
export default ReportData;
