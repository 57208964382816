import { getErrorMessage, getStandardError } from '@qlibs/react-components';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, PDFDownloadLink } from '@react-pdf/renderer';
import { Button, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { httpRequest } from '../../helpers/api';
import QRCode from 'qrcode';
import React from 'react';
import moment from 'moment';

type IQRPDFData = { qrURL: string; value: string; label: string };

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginVertical: 2,
    flexWrap: 'wrap',
  },
  qrcodeContainer: {
    padding: 5,
    width: '23%',
    height: 'auto',
    margin: 0,
  },
  qrText: {
    fontSize: 11,
    textAlign: 'center',
  },
});

export const QRCodeDownloadPDF = (props: { data: IQRPDFData[][] }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {props.data.map((rowItem, rowIndex) => (
        <View key={rowIndex} style={styles.row}>
          {rowItem.map((data) => (
            <View key={data.value} style={styles.qrcodeContainer}>
              <Image src={data.qrURL} />
              <Text style={styles.qrText}>{data.label}</Text>
            </View>
          ))}
        </View>
      ))}
    </Page>
  </Document>
);

type Props = {
  qrType: 'DAILY';
  rangeStart: Date;
}
export const QRCodeDownloadPDFViewer = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IQRPDFData[][]>([]);
  
  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await httpRequest.get('qrcode', {
        params: {
          qrType: props.qrType,
          order: props.qrType === 'DAILY' ? 'data,ASC' : undefined,
          rangeStart: props.rangeStart.toISOString(),
        },
      })

      let list = [];
      for (const item of res.data.payload.results) {
        const qrURL = await QRCode.toDataURL(item.id);
        list.push({qrURL: qrURL, value: item.id, label: item.data});
      }

      setData(chunkArray(list, 4));
      setIsLoading(false);
    } catch(err) {
      setIsLoading(false);
      getStandardError(err, {showToast: true})
    };
  }

  if (isLoading) {
    return <Spin spinning={true} />
  }

  return (
    <React.Fragment>
      <Row justify="center" style={{marginBottom: 10}}>
        <PDFDownloadLink
          document={<QRCodeDownloadPDF data={data} />}
          fileName={`qrcode.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <Button type="primary" disabled={true} loading={true}>
                Preparing your PDF...
              </Button>
            ) : url ? (
              <Button type="primary" href={url}>
                Download Now
              </Button>
            ) : error ? (
              getErrorMessage(error)
            ) : (
              'Something went wrong'
            )
          }
        </PDFDownloadLink>
      </Row>
      <Row justify="center">
        <PDFViewer height={500} width={700}>
          <QRCodeDownloadPDF data={data} />
        </PDFViewer>
      </Row>
    </React.Fragment>
  );
}

function chunkArray(array: IQRPDFData[], itemsPerRow: number): IQRPDFData[][] {
  // Initialize an array to hold the chunked arrays
  const chunkedArray: IQRPDFData[][] = [];

  // Loop through the original array and split it into chunks
  for (let i = 0; i < array.length; i += itemsPerRow) {
    const chunk = array.slice(i, i + itemsPerRow);
    chunkedArray.push(chunk);
  }

  return chunkedArray;
}
