import { hasPermission } from '@qlibs/react-components';
import React from 'react'
import { useAuthUser } from 'react-auth-kit';

export default function usePermission() {
  const auth = useAuthUser();
  const user = auth()

  const isUserHasPermission = (requiredPermissions: string[], operator?: "OR" | 'AND') => {
    return hasPermission(user?.role.permissions, requiredPermissions, operator);
  }

  return {
    isUserHasPermission,
  };
}