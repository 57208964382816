import { useState } from 'react';
import { Form, DatePicker, Button, notification } from 'antd';
import { httpRequest } from '../../../helpers/api';
import { getStandardError } from '@qlibs/react-components';
import moment from 'moment';

const {RangePicker} = DatePicker;

type Props = {
  onSuccess: () => void;
}
export default function FormGenerateDailyQRCode(props: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  
  const onSubmit = async (values: any) => {
    console.info('values', values);
    const range = values.range;
    const startDate = moment(range[0]['$d']).format('YYYY-MM-DD');
    const endDate = moment(range[1]['$d']).format('YYYY-MM-DD');

    console.info('startDate', startDate);
    console.info('endDate', endDate);
    
    setIsLoading(true);
    try {
      await httpRequest.post('qrcode/generator', {
        startDate,
        endDate,
        ifExist: 'THROW_ERROR',
      });
      setIsLoading(false);

      notification.success({ message: 'Generated successfully' });

      props.onSuccess();
    } catch (err) {
      setIsLoading(false);
      getStandardError(err, { showToast: true });
    }
  }

  return (
    <Form form={form} onFinish={onSubmit} style={{textAlign: 'center', paddingTop: 25}}>
      <Form.Item name="range">
        <RangePicker />
      </Form.Item>

      {/* <Checkbox /> */}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
          Generate Now
        </Button>
      </Form.Item>
    </Form>
  );
}
