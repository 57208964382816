// Core viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Create new plugin instance

type Props = {
  pdfUrl: string;
}
const PDFViewer = ({pdfUrl}: Props) => {
const defaultLayoutPluginInstance = defaultLayoutPlugin();
return (
  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
    <Viewer
      fileUrl={pdfUrl}
      plugins={[
        // Register plugins
        defaultLayoutPluginInstance,
      ]}
    />
  </Worker>
);
}

export default PDFViewer;