import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';

const {Text} = Typography;

type Props = {
  isLoading: boolean;
  onSubmit: () => void;
  subtitle?: any;
}
export default function HasChangesSubmitButton(props: Props) {
  return (
    <Row justify="end">
      <Col style={{ marginRight: 10 }}>
        <Text style={{ color: 'red' }}>* You have unsaved changes</Text>
        {props.subtitle ? (
          <Text
            style={{
              color: 'gray',
              fontSize: 11,
              marginLeft: 5,
            }}
          >
            ({props.subtitle})
          </Text>
        ) : (
          false
        )}
      </Col>

      <Col>
        <Button
          loading={props.isLoading}
          style={{ marginBottom: 10 }}
          type="primary"
          onClick={() => {
            props.onSubmit();
          }}
          icon={<SaveOutlined />}
        >
          SUBMIT EVALUATION
        </Button>
      </Col>
    </Row>
  );
}