import React from 'react';
import styled from 'styled-components';
import AuthContentLeft from './AuthContentLeft';
import AuthContentLeftSecondary from './AuthContentLeftSecondary';
import { MainCheckHealth } from '@qlibs/react-components';
import AuthContentRight from './AuthContentRight';
import { httpRequest } from '../../helpers/api';

interface Props {
  variant?: 'primary' | 'secondary';
  formPosition?: 'left' | 'right';
  children?: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({
  children,
  variant = 'primary',
  formPosition = 'right',
}) => {
  return (
    <div>
      {!!process.env.REACT_APP_CHECK_HEALTH_URL && (
        <MainCheckHealth url={process.env.REACT_APP_CHECK_HEALTH_URL || '/'} axiosInstance={httpRequest as any} />
      )}
      <Container>
        {formPosition === 'right' ? (
          <>
            {variant === 'primary' ? (
              <AuthContentLeft />
            ) : (
              <AuthContentLeftSecondary />
            )}
            <ContentRight>{children}</ContentRight>
          </>
        ) : (
          <>
            <ContentRight>{children}</ContentRight>
            <AuthContentRight />
          </>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
`;

const ContentRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default AuthLayout;
