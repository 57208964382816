import { EQuestionType } from "../../../types/quiz.type";

export const renderTextQuestionType = (type: EQuestionType) => {
  switch (type) {
    case EQuestionType.ESSAY:
      return "Essay";
    case EQuestionType.MULTIPLE_CHOICE_CORRECT_WRONG_SINGLE:
      return "Multiple Choice (Choose one)";
    case EQuestionType.MULTIPLE_CHOICE_CORRECT_WRONG_MULTIPLE:
      return "Multiple Choice (Choose more than one)";
    case EQuestionType.MULTIPLE_CHOICE_FREE_CHOICE_SINGLE:
      return "Multiple Choice Free Choice (Choose one)";
    case EQuestionType.MULTIPLE_CHOICE_FREE_CHOICE_MULTIPLE:
      return "Multiple Choice Free Choice (Choose more than one)";
    case EQuestionType.FILL_THE_BLANK_CORRECT_WRONG:
      return "Fill in The Blank";
    case EQuestionType.LOGBOOK:
      return "Logbook";
    case EQuestionType.INSERT_URL:
      return "Insert URL";
    case EQuestionType.UPLOAD_FILE:
      return "Upload File";
    default:
      break;
  }
};

export default function RenderQuestionType() {
  return <div>RenderQuestionText</div>;
}
