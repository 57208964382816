import React, { useState } from 'react';
import {
  AppTable,
  IAppTableColumn,
  useFetchList,
  NotSet,
  getDateTimeString,
  getStandardError,
} from '@qlibs/react-components';
import { httpRequest } from '../../helpers/api';
import RowFilter from '@qlibs/react-components/dist/Table/RowFilter';
import { UserProperties } from '../user/types/user.type';
import useAdditionalDataForList from '../../hooks/useAdditionalDataForList';
import { Button, Checkbox, message, Modal } from 'antd';
import { getTextStartEndOnly } from '../../helpers/textStartEndOnly';
import moment from 'moment';

type Props = {
  talentIds?: string[];
}
const ListTalentForUserDevice = (props: Props) => {
  const filterRole = { roles: 'talent' };

  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const { isLoading, data, handleSearch, pageQueries, fetchList } =
    useFetchList<UserProperties>({
      httpRequest: httpRequest as any,
      endpoint: 'users',
      limit: 999999,
      initialQuery: {
        ...filterRole,
        ...(props.talentIds ? {userIds: props.talentIds.join(',')}: {}),
        status: 'active',
        sort: 'name:ASC',
      },
      pageQuery: {
        defaultValue: {
          page: 1,
        },
      },
    });

  const { additionalData } = useAdditionalDataForList<{
    [key: string]: { userDevices?: any };
  }>({
    id: 'userId',
    data,
    injects: [
      {
        injectedId: 'userId',
        endpoint: '/user-devices?externalIds=',
        endpointId: 'externalId',
        returnKey: 'userDevices',
        multiple: true,
      },
    ],
  });
  console.info('additionalData', additionalData);

  const columns: IAppTableColumn<UserProperties>[] = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      // type: 'detail',
      showOriginalValue: true,
      keyId: 'userId',
      width: 150,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: 150,
    },
    // {
    //   title: "PHONE NUMBER",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    {
      title: 'LAST LOGIN',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      width: 150,
      render: (_, record) => {
        if (additionalData[record.userId]?.userDevices) {
          const devices = additionalData[record.userId]?.userDevices;

          if (devices.length === 0) {
            return <NotSet />;
          }

          devices.sort(
            (a: any, b: any) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );

          return (
            <span>
              <span style={{ marginRight: 5 }}>
                {getDateTimeString(devices[0].updatedAt, 'short', 'short')}
              </span>
              <span style={{color: 'darkgrey', fontSize: 11}}>{moment(devices[0].updatedAt).fromNow()}</span>
            </span>
          );
        } else {
          return <NotSet />;
        }
      },
    },
    {
      title: 'DEVICE',
      dataIndex: 'device',
      key: 'device',
      render: (_, record) => {
        if (additionalData[record.userId]?.userDevices) {
          const devices = additionalData[record.userId]?.userDevices;

          if (devices.length === 0) {
            return <NotSet />;
          }

          devices.sort(
            (a: any, b: any) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );

          let shownDevices = [];
          if (devices.length > 2) {
            shownDevices = devices.filter((_: any, index: number) => index < 2);
          } else {
            shownDevices = [...devices];
          }

          return (
            <div>
              <ListDevice
                isLoadingRemove={isLoadingRemove}
                devices={shownDevices}
                handleDeleteById={handleDeleteById}
              />

              {devices.length - shownDevices.length > 0 && <Button
                size="small"
                type="link"
                style={{ fontSize: 11 }}
                onClick={() => {
                  Modal.info({
                    title: 'User Device - ' + record.name,
                    content: (
                      <ListDevice
                        isLoadingRemove={isLoadingRemove}
                        devices={devices}
                        handleDeleteById={handleDeleteById}
                      />
                    ),
                    footer: false,
                    closable: true,
                    width: 800,
                  });
                }}
              >
                {devices.length - shownDevices.length > 0
                  ? '+ ' + (devices.length - shownDevices.length) + ' more'
                  : 'Show detail'}
              </Button>}
            </div>
          );
        } else {
          return <NotSet />;
        }
      },
    },
  ];

  const handleDeleteById = (id: number) => {
    setIsLoadingRemove(true);
    httpRequest
      .delete('/user-devices/' + id)
      .then((res) => {
        setIsLoadingRemove(false);
        message.success('Removed successfully');
        Modal.destroyAll();
        fetchList();
      })
      .catch((err) => {
        setIsLoadingRemove(false);
        getStandardError(err, { showToast: true });
      });
  }

  return (
    <React.Fragment>
      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || 'all',
        }}
        filters={[
          [
            {
              type: 'search',
              key: 'search',
              label: 'Search Talent',
              placeholder: 'Search talent by name, email, or phone',
              onChange: (value: any) => {
                handleSearch(value);
              },
              colSpan: 9,
            },
            // {
            //   type: "select",
            //   key: "status",
            //   label: "Status",
            //   options: [
            //     {
            //       value: "all",
            //       label: "All",
            //     },
            //     {
            //       value: "active",
            //       label: "Active",
            //     },
            //     {
            //       value: "inactive",
            //       label: "Inactive",
            //     },
            //   ],
            //   onChange: (value: any) => {
            //     filterDropdown({
            //       status: value === "all" ? "" : value,
            //     });
            //   },
            //   colSpan: 6,
            // },
          ],
        ]}
      />

      <AppTable
        isLoading={isLoading}
        keyId="userId"
        columns={columns}
        data={data}
        pagination={false}
        // onChangePage={changePage}
        // onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />
    </React.Fragment>
  );
};
export default ListTalentForUserDevice;

const ListDevice = ({isLoadingRemove, devices, handleDeleteById}: {isLoadingRemove: boolean, devices: any[], handleDeleteById: (id: number) => void}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);

  const handleDeletes = async () => {
    try {
      setLoading(true);
      await Promise.all(selected.map((id) => handleDeleteById(id)));
      Modal.destroyAll();
      setSelected([]);
      setLoading(false);
    } catch(err) {
      getStandardError(err, {showToast: true});
      setSelected([]);
      setLoading(false);
    }
  }

  return (
    <div>
      {selected.length > 0 && selected.length !== devices.length && (
        <Button
          size="small"
          style={{ fontSize: 12, marginBottom: 10, marginRight: 5 }}
          onClick={() => {
            setSelected(devices.map((d) => d.id));
          }}
        >
          Select All
        </Button>
      )}
      {selected.length > 0 && selected.length === devices.length && (
        <Button
          size="small"
          style={{ fontSize: 12, marginBottom: 10, marginRight: 5 }}
          onClick={() => {
            setSelected([]);
          }}
        >
          Unselect All
        </Button>
      )}
      {selected.length > 0 && (
        <Button
          loading={loading || isLoadingRemove}
          size="small"
          type="primary"
          style={{ fontSize: 12, marginBottom: 10 }}
          onClick={() => {
            Modal.confirm({
              title: 'Confirmation',
              content: 'Are you sure want to remove selected data?',
              onOk: () => {
                handleDeletes();
              },
            });
          }}
        >
          Remove Selected ({selected.length})
        </Button>
      )}

      <Checkbox.Group
        options={devices.map((ud) => ({
          label:
            ud.meta.os +
            ' - v' +
            ud.meta.appVersion +
            ' - ' +
            getDateTimeString(ud.updatedAt, 'short', 'short') +
            ' - ' +
            getTextStartEndOnly(ud.fcmToken),
          value: ud.id,
        }))}
        value={selected}
        onChange={setSelected}
      />
    </div>
  );
  return (
    <ul style={{ marginLeft: -20 }}>
      {devices.map((ud: any) => (
        <li style={{ fontSize: 11 }}>
          {ud.meta.os} - v{ud.meta.appVersion} -{' '}
          {getDateTimeString(ud.updatedAt, 'short', 'short')} -{' '}
          <span style={{ fontSize: 11 }}>
            {getTextStartEndOnly(ud.fcmToken)} -{' '}
          </span>
          {ud.deviceId} -
          <Button
            size="small"
            loading={isLoadingRemove}
            type="link"
            style={{ fontSize: 11 }}
            onClick={() => {
              Modal.confirm({
                title: 'Confirmation',
                content: 'Are you sure want to remove this data?',
                onOk: () => {
                  handleDeleteById(ud.id);
                },
              });
            }}
          >
            remove
          </Button>
        </li>
      ))}
    </ul>
  );
}