import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, message, Spin } from 'antd';
import { httpRequest } from '../../../helpers/api';
import { DetailItem } from '@qlibs/react-components';
import { IEventParticipant } from '../types/eventParticipant.type';

const {Title, Text} = Typography;

type Props = {
  eventId: string;
  participants?: IEventParticipant[];
}
export default function EventParticipantsWithFunction({eventId, ...props}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [participants, setParticipants] = useState<IEventParticipant[]>(props.participants || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await httpRequest.get<any>(`/event-participants?eventId=${eventId}`);
        if (res && res?.data && res?.data?.payload?.results) {
          setParticipants(res.data.payload.results);
        } else {
          message.error('Failed to fetch data');
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (!props.participants) {
      fetchData();
    } else {
      setParticipants(props.participants);
    }
  }, [eventId]);

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col span={5}>
          <Title level={5}>Participants</Title>
          <Text style={{ color: '#768499' }}>
            These are list of participant, you can’t change here
          </Text>
        </Col>
        <Col offset={1} span={16}>
          <Row>
            {participants.map(part => (
              <Col span={12} key={part.id}>
                <DetailItem label={part.role} value={part.user?.name} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}
