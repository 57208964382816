import { WarningFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import { ICompetenceItemLevel1 } from '../types/competence.type';

type Props = {
  items: ICompetenceItemLevel1[];
  hasChanges?: boolean;
}

export default function EvalProgressInput(props: Props) {
  const { currentFilled, maxFilled } = {
    currentFilled: props.items.reduce(
      (acc, curr) => (Number(curr.finalScore) > 0 ? acc + 1 : acc),
      0
    ),
    maxFilled: props.items.length,
  };
  let color = 'red';
  if (currentFilled === maxFilled) {
    color = 'green';  
  } else if (currentFilled > 0 && currentFilled < maxFilled) {
    color = 'orange';
  }

  if (props.hasChanges) {
    return (
      <Tag color="orange" style={{ marginLeft: 5 }}>
        <WarningFilled style={{ marginRight: 5 }} />
        ???
      </Tag>
    );
  }

  return (
    <Tag color={color} style={{marginLeft: 5}}>
      {color === 'red' ? <WarningFilled style={{marginRight: 5}} /> : false}

      {currentFilled}
      {' / '}
      {maxFilled}
    </Tag>
  );
}