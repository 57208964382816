import { Empty, Modal } from 'antd'
import { useState } from 'react'
import Attendance from '../../attendance/indexByTalent';

export default function AttendanceByTalent({talentId, title, onClose, onCancel}: {talentId: string; title: string; onClose: () => void; onCancel: () => void}) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal
      open={isOpen}
      closable
      width={1000}
      title={title}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onCancel={() => {
        setIsOpen(false);
        onCancel();
      }}
      footer={false}
    >
      {!talentId ? <Empty /> : false}
      {talentId ? (
        // <Attendance type="DAILY" talentId={talentId} hideHeader hideActions />
        <Attendance talentId={talentId} hideHeader />
      ) : (
        false
      )}
    </Modal>
  );
}