import { getStandardError } from '@qlibs/react-components';
import { useState } from 'react';
import { httpRequest } from '../helpers/api';

type Props = {
  endpoints: {
    endpoint: string;
    params?: any;
    returnKey: string;
    returnResultsFromPagination?: boolean;
  }[];
};
export default function usePromiseAllData<IPromiseAllData>(props: Props) {
  const [isLoadingPromiseAllData, setIsLoadingPromiseAllData] = useState(false);
  const [promiseAllData, setPromiseAllData] = useState<IPromiseAllData>({} as any);
  
  const fetchPromiseAllData = async () => {
    setIsLoadingPromiseAllData(true);
    try {
      const results = await Promise.all(
        props.endpoints.map(endpoint => (
          httpRequest.get(
            `${endpoint.endpoint}`,
            { params: endpoint.params }
          )
        ))
      );

      const objData: any = {};
      for (const idx in props.endpoints) {
        const endpoint = props.endpoints[idx];
        if (endpoint.returnResultsFromPagination) {
          objData[endpoint.returnKey] = results[idx]?.data?.payload?.results || [];
        } else {
          objData[endpoint.returnKey] = results[idx]?.data?.payload;
        }
      }
      console.info('props.endpoints', props.endpoints);
      console.info('objData', objData);

      setPromiseAllData(objData);
      setIsLoadingPromiseAllData(false);
    } catch (error) {
      getStandardError(error, { showToast: true });
      console.error('Failed to fetch promiseall data', error);
      setIsLoadingPromiseAllData(false);
    }
  };

  return {
    isLoadingPromiseAllData,
    promiseAllData,
    fetchPromiseAllData,
  };
}