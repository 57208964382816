import React, { useState } from 'react';
import { TagOutlined } from '@ant-design/icons';
import { Space, Button, QRCode, Modal, Form, DatePicker, Checkbox, Alert, Row, Tooltip, Col } from 'antd';
import {
  HeaderSection,
  IAppTableColumn,
  NotSet,
  PAGE_SIZE_OPTIONS,
} from '@qlibs/react-components';
import { httpRequest } from '../../helpers/api';
import { AppTable, useFetchList } from '@qlibs/react-components';
import FormGenerateDailyQRCode from './components/FormGenerateDailyQRCode';
import { QRCodeDownloadPDFViewer } from '../qrcodeGenerator/QRCodeDownloadPDF';
import moment from 'moment';

type Props = {
  type: "DAILY"
}

const QRcode = (props: Props) => {
  const [
    isShowModalGenerateDailyQRCode,
    setIsShowModalGenerateDailyQRCode,
  ] = useState(false);
  const [isShowModalDownloadDailyQRCode, setIsShowModalDownloadDailyQRCode] =
    useState(false);

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    fetchList,
  } = useFetchList<any>({
    endpoint: 'qrcode',
    limit: +PAGE_SIZE_OPTIONS[0],
    httpRequest: httpRequest as any,
    initialQuery: {
      rangeStart: moment().startOf('day').toISOString(),
      order: props.type === 'DAILY' ? 'data,ASC' : undefined
    },
    pageQuery: {
      defaultValue: { page: 1, search: '', isPublished: '', order: props.type ? 'data,ASC' : undefined },
    },
  });

  const columns: IAppTableColumn<any>[] = [
    {
      title: 'QRCode',
      dataIndex: 'id',
      key: 'id',
      keyId: 'id',
      width: 120,
      render: (value, record) => {
        return (
          <React.Fragment>
            <Tooltip title="Click to preview">
              <QRCode
                value={value}
                size={120}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  Modal.info({
                    title: 'QRCode - ' + record.data,
                    width: 600,
                    content: (
                      <Row
                        justify="center"
                        align="middle"
                        style={{ padding: 20 }}
                      >
                        <QRCode value={value} size={400} />
                      </Row>
                    ),
                    closable: true,
                    footer: false,
                  });
                }}
              />
            </Tooltip>
            {/* <span style={{fontSize: 11}}>{record.id}</span> */}
          </React.Fragment>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'data',
      key: 'data',
      keyId: 'data',
      width: 250,
    },
    // {
    //   title: 'Usage',
    //   dataIndex: 'usage',
    //   key: 'usage',
    //   keyId: 'usage',
    //   width: 200,
    //   render: () => <NotSet value="Coming soon" />
    // },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      type: 'datetime',
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      type: 'datetime',
    },
    {
      title: 'GENERATED BY',
      dataIndex: 'metaCreatedByUser',
      key: 'metaCreatedByUser',
      render: (value) => value.name,
    },
    // {
    //   title: 'ACTION',
    //   key: 'action',
    //   actions: [
    //     'edit',
    //   ],
    // },
  ];

  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);

  return (
    <React.Fragment>
      <HeaderSection
        icon={<TagOutlined />}
        title="QRCode"
        subtitle="Manage your QRCode"
        rightAction={
          <Space>
            <Button
              style={{ background: '#264284', color: 'white', marginRight: 20 }}
              type="primary"
              onClick={() => {
                setIsShowModalDownloadDailyQRCode(true);
              }}
            >
              Download PDF
            </Button>
            <Button
              style={{ background: '#264284', color: 'white', marginRight: 20 }}
              type="primary"
              onClick={() => setIsShowModalGenerateDailyQRCode(true)}
            >
              Generate Daily QRCode
            </Button>
          </Space>
        }
      />

      <Alert
        message="This page only shows the QR Code for today and the future (which have been generated)"
        showIcon
      />

      <AppTable
        isLoading={isLoading}
        keyId="categoryId"
        columns={columns}
        data={data}
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
      />

      <Modal
        title="Generate Daily QRCode"
        open={isShowModalGenerateDailyQRCode}
        footer={false}
        closable
        onCancel={() => setIsShowModalGenerateDailyQRCode(false)}
      >
        <Alert
          type="warning"
          showIcon
          message="You can only generate 1 QRcode for 1 day. Make sure you choose a time range that doesn't have a QRcode yet."
        />
        <FormGenerateDailyQRCode
          onSuccess={() => {
            fetchList();
            setIsShowModalDownloadDailyQRCode(false);
          }}
        />
      </Modal>

      <Modal
        title="Download Daily QRCode (PDF)"
        open={isShowModalDownloadDailyQRCode}
        footer={false}
        closable
        width={800}
        onCancel={() => setIsShowModalDownloadDailyQRCode(false)}
      >
        <QRCodeDownloadPDFViewer qrType={'DAILY'} rangeStart={now} />
      </Modal>
    </React.Fragment>
  );
};

export default QRcode;
