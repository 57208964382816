import { generateFormRules, NotSet } from '@qlibs/react-components';
import { data } from '@remix-run/router/dist/utils';
import { Form, Tag, Modal, Select, Button, Typography, message, TimePicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react'
import { httpRequest } from '../../../helpers/api';
import { AttendanceStatus } from '../../../types/attendance.type';
import { MAPPING_ATTENDANCE_STATUS_PROPS } from '../data/mappingStatus';

const {Text} = Typography;

export default function TagAttendanceStatus({
  readonly,
  attendanceId,
  userId,
  checkInAt,
  currentStatus,
  onSuccess,
}: {
  readonly: boolean;
  attendanceId: string;
  userId: string;
  checkInAt?: Date;
  currentStatus: AttendanceStatus;
  onSuccess: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = async (
    id: string,
    value: AttendanceStatus,
    remark: string
  ) => {
    try {
      setIsLoading(true);

      const newData = {
        status: value,
        remark: remark,
      };
      await httpRequest.patch('attendance/' + id + '/status', newData);

      onSuccess();

      message.success(`Update status successfully`);
      setIsLoading(false);
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  const handleAddAttendance = async (
    status: AttendanceStatus,
    time: any, 
    remark: string,
  ) => {
    try {
      setIsLoading(true);
      if (!checkInAt) {
        message.error('Unknown date');
        setIsLoading(false);
        return;
      }

      if (!time) {
        message.error('Unknown date');
        setIsLoading(false);
        return;
      }

      const dataCheckInAt = new Date(checkInAt || '');
      dataCheckInAt.setHours(time['$H']);
      dataCheckInAt.setMinutes(time['$m']);
      dataCheckInAt.setSeconds(time['$s']);

      const newData = {
        status,
        remark,
        userId,
        checkInAt: dataCheckInAt,
      };
      console.info('newData', newData);
      await httpRequest.post('attendance', newData);
      message.success(`Attendance (${status}) added successfully ~`);

      setTimeout(() => {
        onSuccess();
      }, 500);

      setIsLoading(false);
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  if (currentStatus === AttendanceStatus.UPCOMING) {
    return <NotSet value="upcoming" />;
  }

  return (
    <Tag
      style={readonly ? {} : { cursor: 'pointer' }}
      onClick={() => {
        if (readonly) {
          return;
        }

        Modal.confirm({
          title: 'Change Status',
          width: 500,
          content: (
            <React.Fragment>
              <Text>
                Current Status: <Tag>{currentStatus}</Tag>{' '}
                {attendanceId ? <></> : <Text>~</Text>}
              </Text>
              <Form
                onFinish={(values) => {
                  if (attendanceId) {
                    handleStatusChange(
                      attendanceId,
                      values.newStatus,
                      values.remark
                    );
                  } else {
                    handleAddAttendance(
                      values.newStatus,
                      values.time,
                      values.remark
                    );
                  }
                  Modal.destroyAll();
                }}
              >
                <Form.Item
                  label="New Status"
                  name="newStatus"
                  style={{ marginTop: 10 }}
                  rules={generateFormRules('New status', ['required'])}
                >
                  <Select
                    style={{ width: '100%' }}
                    options={[
                      { value: 'PRESENT', label: 'PRESENT' },
                      { value: 'LATE', label: 'LATE' },
                      { value: 'SICK', label: 'SICK' },
                      { value: 'PERMISSION', label: 'PERMISSION' },
                      { value: 'ALPHA', label: 'ALPHA' },
                    ].filter((item) => item.value !== currentStatus)}
                  />
                </Form.Item>

                {!attendanceId && (
                  <Form.Item
                    name="time"
                    label="Check In Time"
                    required
                    rules={generateFormRules('Check In Time', ['required'])}
                  >
                    <TimePicker style={{ width: '100%' }} />
                  </Form.Item>
                )}

                <Form.Item
                  name="remark"
                  label="Remark / Reason"
                  required
                  rules={generateFormRules('Remark', ['required'])}
                >
                  <TextArea
                    placeholder="write the reason here..."
                    style={{ width: '100%' }}
                  />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                  SUBMIT
                </Button>
              </Form>
            </React.Fragment>
          ),
          onCancel: () => {
            // const list = [...data].map((item) => {
            //   if (item.attendanceId === record.attendanceId) {
            //     item.status = currentStatus;
            //   }
            //   return item;
            // });
            // setData(list);
          },
          footer: false,
          closable: true,
        });
      }}
      {...MAPPING_ATTENDANCE_STATUS_PROPS[currentStatus]}
    >
      {currentStatus}
    </Tag>
  );
}