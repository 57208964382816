import { UserProperties } from "../services/openapi";

export enum ESubmitType {
  DAILY = "DAILY",
  PER_EVENT = "PER_EVENT",
  PER_EVENT_DAILY = "PER_EVENT_DAILY",
}

export type QuizProperties = {
  quizId: string;
  title: string;
  remidialScore?: number;
  maxAttempt?: number;
  quizType: EQuizType;
  createdByUserId: string;
  randomQuestion?: boolean;
  shownQuestion: number;
  requirement?: any;
  submitType: ESubmitType;
  createdAt?: Date;
  updatedAt?: Date;

  questions: QuestionProperties[];
  quizFeedbacks: QuizProperties[];
  user: UserProperties;
  quizAttemptCount: string;
  latestAttempt: QuizAttemptProperties;
};

export type QuestionProperties = {
  questionId: string;
  quizId: string;
  questionText: string;
  editableWhenContinue?: boolean;
  questionType: EQuestionType;
  note?: string;
  order: number;
  createdByUserId: string;
  allowedExtension: string[];
  metaHeaderQuestions: any;
  groupName?: string;
  createdAt?: Date;
  updatedAt?: Date;

  tempId?: string;

  answers: AnswerProperties[];
};

export enum EQuestionType {
  MULTIPLE_CHOICE_CORRECT_WRONG_SINGLE = "MULTIPLE_CHOICE_CORRECT_WRONG_SINGLE",
  MULTIPLE_CHOICE_CORRECT_WRONG_MULTIPLE = "MULTIPLE_CHOICE_CORRECT_WRONG_MULTIPLE",
  FILL_THE_BLANK_CORRECT_WRONG = "FILL_THE_BLANK_CORRECT_WRONG",
  ESSAY = "ESSAY",
  MULTIPLE_CHOICE_FREE_CHOICE_SINGLE = "MULTIPLE_CHOICE_FREE_CHOICE_SINGLE",
  MULTIPLE_CHOICE_FREE_CHOICE_MULTIPLE = "MULTIPLE_CHOICE_FREE_CHOICE_MULTIPLE",
  LOGBOOK = "LOGBOOK",
  INSERT_URL = "INSERT_URL",
  UPLOAD_FILE = "UPLOAD_FILE",
}

export enum EQuizType {
  PRETEST = "PRETEST",
  POSTEST = "POSTEST",
  MICRO_LEARNING = "MICRO_LEARNING",
  MICRO_LEARNING_PUBLIC = "MICRO_LEARNING_PUBLIC",
  SURVEY = "SURVEY",
}

export type AnswerProperties = {
  answerId: string;
  questionId: string;
  answer: string;
  isCorrect: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type QuizAttemptProperties = {
  id: string;
  quizId: string;
  userId: string;
  attempt: number;
  score?: number;
  startAt?: Date;
  finishedAt?: Date;
  externalData: any;
  isFinishedBySystem: boolean;
  notesFromMentor?: string;
  createdAt?: Date;
  updatedAt?: Date;
  user: UserProperties;
  quiz: QuizProperties;

  attemptDetails: QuizAttemptDetailProperties[];
};

export type QuizAttemptDetailProperties = {
  id: string;
  attemptId: string;
  userId: string;
  questionId: string;
  order: number;
  metaQuestionAnswers: AnswerProperties[];
  metaQuestions: QuestionProperties;
  choosenAnswerIds: string[];
  answerText: string;
  score: number;
  isCorrect: boolean;
  choosenAnswerData: AnswerProperties[];
  createdAt?: Date;
  updatedAt?: Date;

  user: UserProperties;
  attempt: QuizAttemptProperties;
  question: QuestionProperties;
  answer: AnswerProperties;
};
