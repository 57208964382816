import { message } from "antd";
import { useMemo, useState } from "react";
import { httpRequest } from "../helpers/api";
import {
  getErrorMessage,
  saveToken,
  BaseResponseProps,
} from "@qlibs/react-components";
import { useAuthUser, useSignIn } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../const/config";
import { RoleList } from "../screens/user/types/user.type";

type Props = {
  apiLoginUrl?: string;
  apiGetMyProfileUrl?: string;
};

export default function useAuthApp(props?: Props) {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const auth = useAuthUser();

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const role = useMemo(() => {
    const user = auth();

    if (!user) {
      return false;
    }

    return user?.role?.roleName;
  }, [auth]);

  const isAdmin = useMemo(() => {
    return role === RoleList.admin;
  }, [role]);

  const isDealerOwner = useMemo(() => {
    return role === RoleList.dealer_owner;
  }, [role]);

  const isPersonnel = useMemo(() => {
    return role === RoleList.dealer_personnel;
  }, [role]);

  const isCustomer = useMemo(() => {
    return role === RoleList.customer;
  }, [role]);

  const doLogin = async (
    data: { email: string; password: string },
    callback?: () => void
  ) => {
    setIsAuthLoading(true);
    try {
      const resultAuthLogin = await axios.post<
        BaseResponseProps<{
          access_token: string;
          refresh_token: string;
        }>
      >(
        props?.apiLoginUrl || process.env.REACT_APP_BASE_URL + "/auth/signin",
        data
      );

      if (!resultAuthLogin) {
        setIsAuthLoading(false);
        message.error("Login failed. Empty response.");
        return;
      }

      console.log(resultAuthLogin);
      const resProfile: any = await axios.get<
        BaseResponseProps<{
          token: string;
        }>
      >(
        props?.apiGetMyProfileUrl ||
          process.env.REACT_APP_BASE_URL + "/users/me",
        {
          headers: {
            Authorization:
              "Bearer " + resultAuthLogin.data.payload.access_token,
          },
        }
      );
      console.log("resProfile", resProfile);

      if (!resProfile) {
        message.error("Login failed. No profile.");
        setIsAuthLoading(false);
        return;
      }

      if (
        !resProfile?.data?.payload?.role?.permissions?.AUTH?.includes(
          "LOGIN_CMS_ADMIN"
        )
      ) {
        message.error("Your account does not have access rights to login");
        setIsAuthLoading(false);
        return;
      }

      if (resultAuthLogin) {
        saveToken(resultAuthLogin.data.payload.access_token);
      }

      if (
        signIn({
          token: resultAuthLogin.data.payload.access_token,
          expiresIn: 10000,
          tokenType: "Bearer",
          authState: resProfile.data.payload,
          // authState: { token: resultAuthLogin.data.payload.token },
          // refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
          // refreshTokenExpireIn: res.data.refreshTokenExpireIn, // Only if you are using refreshToken feature
        })
      ) {
        // Redirect or do-something
        // console.log(resProfile)
        if (callback) {
          callback();
        } else {
          navigate(config.defaultPath || "/dashboard", { replace: true });
        }
        message.success("Welcome to " + process.env.REACT_APP_WEBSITE_NAME);
      } else {
        message.error("Login failed.");
        //Throw error
      }
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  return {
    isAuthLoading,
    doLogin,
    role,
    isDealerOwner,
    isPersonnel,
    isCustomer,
    isAdmin,
    user: auth(),
  };
}
