import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default function TooltipFormula({
  originalScore,
  finalScore,
  operator,
  items,
  weight,
  disableAutoCalculateSubItems,
}: {
  originalScore: number | null;
  finalScore: number;
  operator: 'average' | 'sum' | string;
  items: any[];
  weight?: number;
  disableAutoCalculateSubItems?: boolean;
}) {
  let list = [];
  if (finalScore > 0 && items.length === 1 && !disableAutoCalculateSubItems) {
    list = items[0].items_level3 || items[0].items_level2 || [];
  } else if (finalScore > 0) {
    list = items;
  } else {
    return <></>;
  }

  const suffixWeight = `${
    weight ? ' x ' + weight * 100 + '% = ' + (originalScore || 0) * weight : ''
  }`;

  if (operator === 'average') {
    const avgFormula = `(${list
      .map((itemSub: any) => itemSub.finalScore)
      .join(' + ')}) / ${list.length} = ${originalScore}`;

    return (
      <Tooltip title={`${avgFormula} ${suffixWeight}`} style={{ fontSize: 11 }}>
        <InfoCircleFilled
          style={{ marginLeft: 5, fontSize: 15, color: 'gray' }}
        />
      </Tooltip>
    );
  } else {
    const sumFormula = `${list
      .map((itemSub: any) => itemSub.finalScore)
      .join(' + ')} = ${originalScore}`;
    return (
      <Tooltip title={`${sumFormula} ${suffixWeight}`} style={{ fontSize: 11 }}>
        <InfoCircleFilled
          style={{ marginLeft: 5, fontSize: 15, color: 'gray' }}
        />
      </Tooltip>
    );
  }
}
