import React, { useMemo, useState } from 'react';
import { Row, Col, Typography, Spin, Divider, Alert, Button } from 'antd';
import { EEventType, EventsProps, initialEvents } from '../../../types/event.type';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps, DetailItem, getDateRangeString, getDateTimeRangeString, NotSet, useFetchList } from '@qlibs/react-components';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import CourseDetailWithFunction from '../../course/components/CourseDetailWithFunction';
import QuizDetailWithFunction from '../../quiz/components/QuizDetailWithFunction';
import EventParticipantsWithFunction from './EventParticipantsWithFunction';
import { COMPETENCES } from '../../basicConfig/competences';
import { ProgramProps, initialProgram } from '../../../types/program.type';

const { Title } = Typography;

type Props = {
  eventId: string;
}

export default function EventDetailWithFunction(props: Props) {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState<EventsProps>(initialEvents);

  const { isLoading: isLoadingAppConfigsCompetence, data: appConfigsCompetence } = useFetchList<{ key: string; value?: string }>({
    httpRequest: httpRequest as any,
    endpoint: 'app-configs',
    initialQuery: {
      keys: COMPETENCES.map(comp => comp.competenceCode + '_WSCC_TRAINING').join(',')
    }
  })
  React.useEffect(() => {
    const fetchEventDetail = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<BaseResponseProps<EventsProps>>(
          '/event/' + props.eventId
        );

        setEvent({
          ...res.data.payload,
        });

        // const bcDetails = [
        //   {
        //     field: 'eventId',
        //     value: props.eventId,
        //     label: 'Detail Event',
        //   },
        // ];
        // setBreadcrumbDetails(bcDetails);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchEventDetail();
  }, [props.eventId]);

  const renderEventStatus = (status?: EEventType) => {
    let eventType = '';
    switch (status) {
      case EEventType.BASIC_TRAINING:
        eventType = 'Basic Training';
        break;
      case EEventType.DBI_PROJECT:
        eventType = 'DBI Project';
        break;
      case EEventType.MENTORING:
        eventType = 'Mentoring';
        break;
      case EEventType.OJT:
        eventType = 'OJT';
        break;
      case EEventType.OJT_PERIOD:
        eventType = 'OJT Period';
        break;
      case EEventType.POST_TEST_WSCC_TRAINING:
        eventType = 'Post Test WSCC Training';
        break;
      case EEventType.PRE_TEST_WSCC_TRAINING:
        eventType = 'Pre Test WSCC Training';
        break;
      case EEventType.WAR_MENTORING:
        eventType = 'War Mentoring';
        break;
      case EEventType.WSCC_TRAINING:
        eventType = 'WSCC Training';
        break;
      default:
        eventType = 'Basic Training';
    }

    return eventType;
  };

  const competenceEvaluation = useMemo(() => {
    const find = appConfigsCompetence.find((item: any) => item?.value?.includes(props.eventId));
    if (find) {
      const findLabel = COMPETENCES.find(item => item.competenceCode === find.key.replace('_WSCC_TRAINING', ''));
      return findLabel;
    } else {
      return undefined;
    }
  }, [appConfigsCompetence]);

  return (
    <Spin spinning={isLoading}>
      {isLoadingAppConfigsCompetence ? (
        <Spin spinning={isLoadingAppConfigsCompetence} />
      ) : competenceEvaluation ? (
        <Alert
          type="warning"
          showIcon
          message={`This event is used for competency evaluation (${competenceEvaluation?.title})`}
          style={{ marginBottom: 20, alignItems: 'center' }}
          action={
            <Button type="link" href="/basic-configurations">
              See Detail
            </Button>
          }
        />
      ) : (
        false
      )}
      <Row>
        <Col span={5}>
          <Title level={5}>Event</Title>
        </Col>
        <Col offset={1} span={16}>
          <Row>
            <Col span={16}>
              <DetailItem label="Event Title" value={event?.title} />
            </Col>
            {/* <Col span={8}>
              <DetailItem label="Program" value={program.title} />
            </Col> */}
          </Row>

          <Row>
            <Col span={16}>
              <DetailItem
                label="Event Date"
                value={
                  event.isAllDay
                    ? getDateRangeString(event.startAt, event.endAt)
                    : getDateTimeRangeString(event.startAt, event.endAt)
                }
              />
            </Col>
            <Col span={8}>
              <DetailItem
                label="Event Type"
                value={renderEventStatus(event?.eventType)}
              />
            </Col>
          </Row>
          {event.dates &&
            (event.dates || []).filter((item) => item).length > 0 ? (
            <Row style={{ display: event.dates ? 'block' : 'none' }}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{
                    marginBottom: 2,
                    fontSize: 14,
                  }}
                >
                  Detail Dates
                </Title>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <ul style={{ color: 'black' }}>
                    {event.dates.map((x) => {
                      const eventDate: any = x ? x.split(',') : undefined;
                      const startAt = eventDate ? eventDate[0] : undefined;
                      const endAt = eventDate ? eventDate[1] : undefined;
                      if (eventDate) {
                        return (
                          <li>
                            {event.isAllDay
                              ? getDateRangeString(startAt, endAt)
                              : getDateTimeRangeString(startAt, endAt)}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Col>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col span={24}>
              <DetailItem
                label="Description"
                type="html"
                value={event?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DetailItem label="Notes" type="html" value={event?.note} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      {event?.externalId &&
        (event?.eventType === EEventType.BASIC_TRAINING ||
          event?.eventType === EEventType.WSCC_TRAINING) ? (
        <CourseDetailWithFunction courseId={event?.externalId} />
      ) : (
        false
      )}

      {event?.externalId &&
        (event?.eventType === EEventType.POST_TEST_WSCC_TRAINING ||
          event?.eventType === EEventType.PRE_TEST_WSCC_TRAINING) ? (
        <>
          <QuizDetailWithFunction quizId={event?.externalId} />
          <Divider />
        </>
      ) : (
        false
      )}

      {event?.eventType === EEventType.MENTORING ||
        event?.eventType === EEventType.WAR_MENTORING ? (
        <>
          <EventParticipantsWithFunction
            eventId={event?.eventId}
            participants={event.participants}
          />
          <Divider />
        </>
      ) : (
        false
      )}

      {event?.planningFormId ? (
        <QuizDetailWithFunction
          quizId={event?.planningFormId}
          label={
            event?.eventType === EEventType.OJT
              ? 'OJT Planning Form'
              : event?.eventType === EEventType.OJT_PERIOD
                ? 'OJT Project Form'
                : 'Feedback Review Form'
          }
          description={
            event?.eventType === EEventType.OJT
              ? 'These are OJT Planning Form details'
              : event?.eventType === EEventType.OJT_PERIOD
                ? 'These are OJT Project Form details'
                : 'These are Feedback Review Form details, you can’t change here'
          }
        />
      ) : (
        false
      )}

      {event?.microLearningId ? (
        <QuizDetailWithFunction
          quizId={event?.microLearningId}
          label='Micro Learning Form'
          description='These are micro learning details'
        />
      ) : (
        false
      )}

      {event?.reportFormId ? (
        <QuizDetailWithFunction
          quizId={event?.reportFormId}
          label={
            event?.eventType === EEventType.OJT
              ? 'OJT Report Form'
              : event?.eventType === EEventType.OJT_PERIOD
                ? 'OJT Project Form'
                : 'Feedback Review Form'
          }
          description={
            event?.eventType === EEventType.OJT
              ? 'These are OJT Report Form details'
              : event?.eventType === EEventType.OJT_PERIOD
                ? 'These are OJT Project Form details'
                : 'These are Feedback Review Form details, you can’t change here'
          }
          currentEventId={event?.eventId}
        />
      ) : (
        false
      )}


    </Spin>
  );
}
