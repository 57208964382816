import dayjs from "dayjs";
import { IEventParticipant } from '../screens/event/types/eventParticipant.type';

export enum EEventType {
  BASIC_TRAINING = 'BASIC_TRAINING',
  WSCC_TRAINING = 'WSCC_TRAINING',
  PRE_TEST_WSCC_TRAINING = 'PRE_TEST_WSCC_TRAINING',
  POST_TEST_WSCC_TRAINING = 'POST_TEST_WSCC_TRAINING',
  OJT = 'OJT',
  DBI_PROJECT = 'DBI_PROJECT',
  MENTORING = 'MENTORING',
  WAR_MENTORING = 'WAR_MENTORING',
  OJT_PERIOD = 'OJT_PERIOD'
}

export interface EventsProps {
  eventId: string;
  programId?: string;
  eventType: EEventType;
  externalId?: string;
  title: string;
  methods: string[];
  description?: string;
  note?: string;
  isAllDay: boolean;
  startAt: Date;
  endAt: Date;
  dates?: string[];
  reportFormId?: string;
  planningFormId?: string;
  microLearningId?: string;
  maxTalent?: number;
  createdByUserId: string;
  metaCreatedByUser: any;
  createdAt?: Date;
  updatedAt?: Date;
  participants?: IEventParticipant[];
}

export const initialEvents: EventsProps = {
  eventId: '',
  programId: '',
  eventType: EEventType.BASIC_TRAINING,
  externalId: '',
  title: '',
  methods: [],
  description: '',
  note: '',
  isAllDay: true,
  startAt: new Date(),
  endAt: new Date(),
  dates: undefined,
  createdByUserId: '',
  metaCreatedByUser: '',
  reportFormId: '',
  planningFormId: '',
  microLearningId: '',
  maxTalent: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
};
