import { AppTable, IAppTableColumn, PAGE_SIZE_OPTIONS, useFetchList } from '@qlibs/react-components';
import { Button, Modal, Spin, Typography } from 'antd';
import { httpRequest } from '../../../helpers/api';
import ReactJson from 'react-json-view';
import { useEffect, useMemo } from 'react';
import useAdditionalDataForList from '../../../hooks/useAdditionalDataForList';
import { EventsProps } from '../../../types/event.type';
import { UserProperties } from '../../user/types/user.type';
import ReactJsonViewCompare from 'react-json-view-compare';
import CompareDataView from '../../../components/CompareDataView';
import { MAPPING_EVENTS_CHANGELOGS_ACTION } from '../data/mappingEvents';

const {Title} = Typography;

type Props = {
  eventId?: string;
  searchByExternalId?: string;

  hideEvent?: boolean;
  hideChanges?: boolean;
}
export default function EventChangeLogs(props: Props) {
  console.log(props.eventId)
  const {
    isLoading: isLoadingLogs,
    data,
    pagination: paginationLogs,
    changePage: changePageLogs,
    changeLimit: changeLimitLogs,
    fetchList,
    setQuery,
    setPageQueries,
    setIsLoading
  } = useFetchList<any>({
    endpoint: `/event-change-logs`,
    httpRequest: httpRequest as any,
    limit: +PAGE_SIZE_OPTIONS[0],
    initialQuery: {
      ...(props.eventId
        ? { externalId: props.eventId }
        : {}),
    },
  });

  useEffect(()=>{
    if(props.eventId){
      setIsLoading(true)
      setQuery({
        limit: 10,
        offset: 0
      })
      setIsLoading(false)

    }
  },[props.eventId])


  const columns: IAppTableColumn<any>[] = [
    {
      title: 'Updated At',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      type: 'datetime',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      type: 'status',
      mappingStatus: MAPPING_EVENTS_CHANGELOGS_ACTION,
    },
    {
      title: 'Action By',
      key: 'updatedByUserId',
      dataIndex: 'updatedByUserId',
      render: (value, record) => {
        return record.actionByMeta?.name;
      },
    },
    {
      title: 'Event',
      key: 'eventName',
      dataIndex: 'eventName',
      render: (value, record) => {
        return record.newData.title;
      },
    },

    // {
    //   title: 'Description',
    //   key: 'description',
    //   dataIndex: 'description',
    //   type: 'description',
    // },
    props.hideChanges
      ? {}
      : {
          title: 'Changes',
          key: 'newData',
          dataIndex: 'newData',
          render: (value, record) => {
            const { previousData, newData } = record;
            if (previousData) {
              delete previousData.createdByUserId;
              delete previousData.metaCreatedByUser;
              delete previousData.createdAt;
              delete previousData.updatedAt;
            }
            if (newData) {
              delete newData.createdByUserId;
              delete newData.metaCreatedByUser;
              delete newData.createdAt;
              delete newData.updatedAt;
            }
            if (record.action === 'REQUESTED') {
              return (
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    Modal.info({
                      title: 'Request Data',
                      content: (
                        <ReactJson
                          src={newData}
                          theme="google"
                          displayDataTypes={false}
                        />
                      ),
                      closable: true,
                      width: 1000,
                      footer: false,
                    });
                  }}
                >
                  See Requested Data
                </Button>
              );
            } else {
              return (
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    Modal.info({
                      title: `Data Changes (${MAPPING_EVENTS_CHANGELOGS_ACTION[record.action].label})`,
                      content: (
                        <CompareDataView
                          oldData={previousData}
                          newData={newData}
                        />
                      ),
                      closable: true,
                      width: 1000,
                      footer: false,
                    });
                  }}
                >
                  See Changes
                </Button>
              );
            }
          },
        },
  ];

  return (
    <Spin spinning={isLoadingLogs}>
      <Title level={4}>Events Activities / Change Logs</Title>
      <AppTable
        keyId="historyId"
        columns={columns}
        data={data}
        pagination={paginationLogs}
        isLoading={isLoadingLogs}
        onChangePage={changePageLogs}
        onChangeLimit={changeLimitLogs}
      />
    </Spin>
  );
}