import { Badge, Modal, Tabs, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ICompetence } from '../types/competence.type';
import EvalBadgeAverage from './EvalBadgeAverage';
import ListEvaluation from './ListEvaluation';

type Props = {
  userId: string;
  competencies: ICompetence[];

  mode?: 'input' | 'report';
}
export default function AllEvaluationPerUser(props: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [hasChanges, setHasChanges] = useState<{
    [key: string]: { eventIds: string[] };
  }>({});
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || props.competencies[0]?.competenceCode || '');

  if (props.mode === 'input') {
    const comp = props.competencies[0];
    for (const idx in props.competencies) {
      if (Number(idx) === 0) continue;
      const itemComp = props.competencies[idx];

      for (const idx1 in comp.items_level0) {
        const itemComp1 = props.competencies[idx].items_level0[idx1];

        if (itemComp1.code === 'training') {
          comp.items_level0[idx1].items_level1 = comp.items_level0[idx1].items_level1.concat();
        }
      }
    }

    return (
      <div style={{ padding: 10 }}>
        {/* <Title level={4} style={{ paddingLeft: 5 }}>
          {comp.title}
        </Title> */}

        <ListEvaluation
          userId={props.userId || ''}
          competenceCode={comp.competenceCode}
          data={comp}
          hasChanges={hasChanges}
          onChangesAnything={(competenceCode: string, eventId?: string) => {
            setHasChanges((old) => {
              const newData = { ...old };
              if (newData[competenceCode]) {
                if (eventId) {
                  newData[competenceCode].eventIds.push(eventId);
                }
              } else {
                if (eventId) {
                  newData[competenceCode] = {
                    eventIds: [eventId],
                  };
                } else {
                  newData[competenceCode] = {
                    eventIds: [],
                  };
                }
              }
              return newData;
            });
          }}
        />
      </div>
    );
  } else {
    const items = props.competencies.map((comp) => {
      return {
        label: (
          <Tooltip title={comp.title}>
            <div
              className="flex items-center gap-auto"
              style={{ fontSize: 11, padding: 0 }}
            >
              {hasChanges[comp.competenceCode] ? (
                <Badge dot>{comp.competenceCode}</Badge>
              ) : (
                comp.competenceCode
              )}
              <br />

              <EvalBadgeAverage
                average={comp.finalScore}
                hasChanges={hasChanges[comp.competenceCode] ? true : false}
              />
            </div>
          </Tooltip>
        ),
        key: comp.competenceCode,
        children: (
          <div style={{ padding: 10, paddingTop: 30 }}>
            <Title level={4} style={{ paddingLeft: 5 }}>
              {comp.title}
            </Title>

            <ListEvaluation
              userId={props.userId || ''}
              competenceCode={comp.competenceCode}
              data={comp}
              hasChanges={hasChanges}
              onChangesAnything={(competenceCode: string, eventId?: string) => {
                setHasChanges((old) => {
                  const newData = { ...old };
                  if (newData[competenceCode]) {
                    if (eventId) {
                      newData[competenceCode].eventIds.push(eventId);
                    }
                  } else {
                    if (eventId) {
                      newData[competenceCode] = {
                        eventIds: [eventId],
                      };
                    } else {
                      newData[competenceCode] = {
                        eventIds: [],
                      };
                    }
                  }
                  return newData;
                });
              }}
            />
          </div>
        ),
        closable: false,
      };
    });
    
    const onChangeTab = (key: string) => {
      if (hasChanges[activeTab]) {
        Modal.confirm({
          title: 'Are you sure want to change tab?',
          content:
            'Please makesure your changes in current tab has been saved, or your changes will be losed.',
          onOk: () => {
            setHasChanges({});
            setActiveTab(key);
            navigate('/evaluation/' + props.userId + '?tab=' + key);
            window.location.reload();
          },
          onCancel: () => {
            //
          },
          okText: 'Yes, sure',
          okType: 'danger',
        });
      } else {
        navigate('/evaluation/' + props.userId + '?tab=' + key);
        setActiveTab(key);
      }
    };

    return (
      <CustomTabs
        type="editable-card"
        items={items}
        onChange={onChangeTab}
        defaultActiveKey={props.competencies[0]?.competenceCode}
        activeKey={activeTab}
        hideAdd
      />
    );
  }
}

const CustomTabs = styled(Tabs)`
  .ant-tabs-tab {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
  }import Evaluation from './index';

`;

